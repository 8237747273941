/* eslint-disable object-shorthand */
/* eslint-disable object-curly-newline */
/* eslint-disable indent */
/* eslint-disable arrow-body-style */
import { GridColumn } from '@progress/kendo-react-grid';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import LoadingSpinner from 'shared/loading/LoadingSpinner';
import { getWarehouseDetails } from 'src/fetch/warehouseDetails';
import PageGrid from './PageGrid';
import './style.scss';
import WarehouseRecievingDetailsModal from './WarehouseRecievingDetailsModal.jsx';

const InventoryUnitGrid = ({
    proNumber,
    moveType,
    ...others
}) => {
    const IMPORT_ORDER = 'IMPORT';
    const [loading, setLoading] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [type, setType] = useState(null);
    const [selectedDataItem, setSelectedDataItem] = useState({});

    const getData = useMemo(() => getWarehouseDetails(proNumber, moveType)
            .then((response) => {
                setLoading(false);
                return { data: response, total: response.length };
            }),
        [proNumber, moveType]);

    useEffect(() => {
        Promise.all([
        ]).then(() => setLoading(false));
    }, []);

    const onHover = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.style.backgroundColor = '#dbdf52';
            element.style.color = 'black';
        }
    };

    const onMouseOut = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.style.backgroundColor = '#121726';
            element.style.color = 'white';
        }
    };

    const HeaderCell = ({ elements, rowspan = 1, minWidth = null }) => {
        return (
          <div rowSpan={rowspan}>
            {elements.map((item) => {
                    return (
                      <div
                        key={item.id}
                        id={item.id}
                        className={elements.indexOf(item) > 0 ? 'border' : null}
                        style={{ minWidth: minWidth }}
                      >
                        {item.value}
                      </div>
                    );
                })}
          </div>
        );
    };

    const RowCell = ({ elements, rowspan = 1 }) => {
        return (
          <td rowSpan={rowspan}>
            {elements.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className={elements.indexOf(item) > 0 ? 'multi-row border' : 'multi-row'}
                        onMouseEnter={() => onHover(item.id)}
                        onMouseOut={() => onMouseOut(item.id)}
                      >
                        {item.value || ''}
                      </div>
                    );
                })}
          </td>
        );
    };

    const openModal = (dataItem) => {
        setSelectedDataItem(dataItem);
        setModalIsOpen(true);
    };

    const handleCloseModal = (shouldRefresh) => {
        setType(null);
        if (shouldRefresh) {
            refreshData();
        }
        setSelectedDataItem({});
        setModalIsOpen(false);
    };

    return loading ? <LoadingSpinner /> : (
      <div className='detail-section order-details inventory-units'>
        <div className='title-container'>
          <div className='title'>Inventory</div>
          <div className='title-bar' />
        </div>
        {moveType === IMPORT_ORDER ? (
            // IMPORT ORDER GRID
          <PageGrid
            className='inventory-grid multi-row-grid'
            searchParams={{}}
            getData={() => getData}
            pageable={false}
            editable={false}
            resizable={false}
            onRowSelect={(dataItem) => openModal(dataItem)}
            {...others}
          >

            <GridColumn
              width="80px"
              headerCell={() => {
                            return (
                              <HeaderCell elements={[{ id: 'mr-number', value: 'MR #' }]} rowSpan={3} minWidth="4rem" />
                            );
                        }}
              cell={(data) => {
                            return (
                              <RowCell elements={[
                                    { id: 'mr-number', value: data.dataItem.lineNumber },
                                ]}
                              />
                            );
                        }}
            />

            <GridColumn
              headerCell={() => {
                            return (
                              <HeaderCell elements={[
                                    { id: 'warehouse-code', value: 'Warehouse' },
                                    { id: 'check-in-date', value: 'Check In Date' }]}
                              />
                            );
                        }}
              cell={(data) => {
                            return (
                              <RowCell elements={[
                                    { id: 'warehouse-code', value: data.dataItem.warehouseCode },
                                    {
                                        id: 'check-in-date',
                                        value: data.dataItem.checkInDate
                                            ? new Date(data.dataItem.checkInDate).toLocaleDateString('en-US')
                                            : null,
                                    },
                                ]}
                              />
                            );
                        }}
            />

            <GridColumn
              headerCell={() => {
                            return (
                              <HeaderCell elements={[
                                    { id: 'mr-po', value: 'MR PO' },
                                    { id: 'split-from', value: 'Split From' },
                                    { id: 'storage-lfd', value: 'Storage LFD' },
                                ]}
                              />
                            );
                        }}
              cell={(data) => {
                            return (
                              <RowCell elements={[
                                    { id: 'mr-po', value: data.dataItem.purchaseOrderNumber },
                                    { id: 'split-from', value: data.dataItem.splitFrom },
                                    {
                                        id: 'storage-lfd',
                                        value: data.dataItem.storageLastFreeDay
                                            ? new Date(data.dataItem.storageLastFreeDay).toLocaleDateString('en-US')
                                            : null,
                                    },
                                ]}
                              />
                            );
                        }}
            />

            <GridColumn
              headerCell={() => {
                            return (
                              <HeaderCell elements={[
                                    { id: 'product-code', value: 'Product Code' },
                                    { id: 'product-description', value: 'Description' },
                                    { id: 'customer', value: 'Customer' },
                                ]}
                              />
                            );
                        }}
              cell={(data) => {
                            return (
                              <RowCell elements={[
                                    { id: 'product-code', value: data.dataItem.productCode },
                                    { id: 'product-description', value: data.dataItem.productDescription },
                                    { id: 'customer', value: data.dataItem.customerName },
                                ]}
                              />
                            );
                        }}
            />

            <GridColumn
              headerCell={() => {
                            return (
                              <HeaderCell elements={[
                                    { id: 'pl-qty', value: 'P/L Qty' },
                                    { id: 'pl-uom', value: 'P/L UoM' },
                                    { id: 'os', value: 'O/S' },
                                ]}
                              />
                            );
                        }}
              cell={(data) => {
                            return (
                              <RowCell elements={[
                                    { id: 'pl-qty', value: data.dataItem.packingListQuantity },
                                    { id: 'pl-uom', value: data.dataItem.packingListUnitOfMeasurement },
                                    { id: 'os', value: data.dataItem.overageShortage },
                                ]}
                              />
                            );
                        }}
            />

            <GridColumn
              headerCell={() => {
                            return (
                              <HeaderCell elements={[
                                    { id: 'first-qty', value: '1st Qty' },
                                    { id: 'first-uom', value: '1st UoM' },
                                ]}
                              />
                            );
                        }}
              cell={(data) => {
                            return (
                              <RowCell elements={[
                                    {
                                      id: 'first-qty',
                                      value: (
                                        <div
                                          style={data.dataItem.masterQuantity > 0 ? { cursor: 'pointer', color: '#016496', textDecoration: 'underline' } : {}}
                                          onClick={() => {
                                                  if (data.dataItem.masterQuantity > 0) {
                                                      openModal(data.dataItem);
                                                  }
                                              }}
                                        >
                                          {data.dataItem.masterQuantity || ''}

                                        </div>),
                                    },
                                    { id: 'first-uom', value: data.dataItem.masterUnitOfMeasurement },
                                ]}
                              />
                            );
                        }}
            />

            <GridColumn
              headerCell={() => {
                            return (
                              <HeaderCell elements={[
                                    { id: 'second-qty', value: '2nd Qty' },
                                    { id: 'second-uom', value: '2nd UoM' },
                                ]}
                              />
                            );
                        }}
              cell={(data) => {
                    return (
                      <RowCell elements={[
                            {
                                id: 'second-qty',
                                value: (
                                    <div
                                        style={data.dataItem.secondaryQuantity > 0 ? { cursor: 'pointer', color: '#016496', textDecoration: 'underline' } : {}}
                                        onClick={() => {
                                            if (data.dataItem.secondaryQuantity > 0) {
                                                openModal(data.dataItem);
                                            }
                                        }}
                                  >
                                    { data.dataItem.secondaryQuantity  || '' }

                                  </div>),
                                    },
                                    { id: 'second-uom', value: data.dataItem.secondaryUnitOfMeasurement },
                                ]}
                      />
                            );
                        }}
            />

            <GridColumn
              headerCell={() => {
                            return (
                              <HeaderCell elements={[
                                    { id: 'lbs', value: 'Lbs' },
                                    { id: 'kgs', value: 'Kgs' },
                                ]}
                              />
                            );
                        }}
              cell={(data) => {
                            return (
                              <RowCell elements={[
                                    { id: 'lbs', value: data.dataItem.lbs },
                                    { id: 'kgs', value: data.dataItem.kgs },
                                ]}
                              />
                            );
                        }}
            />

            <GridColumn
              headerCell={() => {
                            return (
                              <HeaderCell elements={[
                                    { id: 'consignee', value: 'Consignee' },
                                    { id: 'address', value: 'Address/Contact' },
                                    { id: 'bill-to', value: 'Bill To' },
                                ]}
                              />
                            );
                        }}
              cell={(data) => {
                            return (
                              <RowCell elements={[
                                    { id: 'consignee', value: data.dataItem.consigneeName },
                                    { id: 'address', value: data.dataItem.consigneeAddress },
                                    { id: 'bill-to', value: data.dataItem.billTo },
                                ]}
                              />
                            );
                        }}
            />

            <GridColumn
              headerCell={() => {
                            return (
                              <HeaderCell elements={[
                                    { id: 'arrive-date', value: 'Arrive Date' },
                                    { id: 'bl', value: 'BL' },
                                    { id: 'ship-date', value: 'Ship Date' },
                                ]}
                              />
                            );
                        }}
              cell={(data) => {
                            return (
                              <RowCell elements={[
                                    {
                                        id: 'arrive-date',
                                        value: data.dataItem.arrivalDate
                                            ? new Date(data.dataItem.arrivalDate).toLocaleDateString('en-US')
                                            : null,
                                    },
                                    {
                                        id: 'bl',
                                        value: (
                                            data.dataItem.billOfLadingNumber > 0 ? (
                                              <Link
                                                className='link'
                                                style={{ cursor: 'pointer', color: '#016496', textDecoration: 'underline' }}
                                                to={`/search?type=0&query=${data.dataItem.billOfLadingNumber}`}
                                                target='_blank'
                                              >
                                                {data.dataItem.billOfLadingNumber}
                                              </Link>
                                            ) : <span>{data.dataItem.billOfLadingNumber || ''}</span>),
                                    },
                                    {
                                        id: 'ship-date',
                                        value: data.dataItem.shipDate
                                            ? new Date(data.dataItem.shipDate).toLocaleDateString('en-US')
                                            : null,
                                    },
                                ]}
                              />
                            );
                        }}
            />
          </PageGrid>
            ) : (
                // EXPORT ORDER GRID
              <PageGrid
                className='multi-row-grid'
                searchParams={{}}
                getData={() => getData}
                pageable={false}
                editable={false}
                resizable={false}
                {...others}
              >

                <GridColumn
                  width="80px"
                  headerCell={() => {
                            return (
                              <HeaderCell elements={[{ id: 'mr', value: 'MR #' }]} rowSpan={3} minWidth="4rem" />
                            );
                        }}
                  cell={(data) => {
                            return (
                              <RowCell elements={[
                                    {
                                        id: 'mrNumber',
                                        value: (
                                          <Link
                                            className='link'
                                            style={{ cursor: 'pointer', color: '#016496', textDecoration: 'underline' }}
                                            to={(data.dataItem.masterReceivingNumber
                                              ? `/search?type=0&query=${data.dataItem.masterReceivingNumber.split('-')[0]}`
                                              : '/search?type=0'
                                            )}
                                            target='_blank'
                                          >
                                            { data.dataItem.masterReceivingNumber || '' }
                                          </Link>),
                                    },
                                ]}
                              />
                            );
                        }}
                />
                <GridColumn
                  width="60px"
                  headerCell={() => {
                            return (
                              <HeaderCell
                                elements={[
                                    { id: 'bl', value: 'BL #' }]}
                                minWidth="4rem"
                              />
                            );
                        }}
                  cell={(data) => {
                            return (
                              <RowCell elements={[
                                    { id: 'bl', value: data.dataItem.lineNumber }]}
                              />
                            );
                        }}
                />
                <GridColumn
                  headerCell={() => {
                            return (
                              <HeaderCell elements={[
                                    { id: 'warehouse', value: 'Warehouse' },
                                    { id: 'check-in-date', value: 'Check In Date' }]}
                              />
                            );
                        }}
                  cell={(data) => {
                            return (
                              <RowCell elements={[
                                    { id: 'warehouse', value: data.dataItem.warehouseCode },
                                    { id: 'check-in-date', value: data.dataItem.checkInDate ? new Date(data.dataItem.checkInDate).toLocaleDateString('en-US') : null },
                                ]}
                              />
                            );
                        }}
                />

                <GridColumn
                  headerCell={() => {
                            return (
                              <HeaderCell elements={[
                                    { id: 'product-code', value: 'Product Code' },
                                    { id: 'bl-customer', value: 'BL Customer' },
                                ]}
                              />
                            );
                        }}
                  cell={(data) => {
                            return (
                              <RowCell elements={[
                                    { id: 'product-code', value: `${data.dataItem.productCode } - ${ data.dataItem.productCodeDescription}` },
                                    { id: 'bl-customer', value: data.dataItem.customerName },
                                ]}
                              />
                            );
                        }}
                />

                <GridColumn
                  headerCell={() => {
                            return (
                              <HeaderCell elements={[
                                    { id: 'added-by', value: 'Added By' },
                                    { id: 'pick-policy', value: 'Pick Policy' },
                                ]}
                              />
                            );
                        }}
                  cell={(data) => {
                            return (
                              <RowCell elements={[
                                    { id: 'added-by', value: data.dataItem.addedBy },
                                    { id: 'pick-policy', value: data.dataItem.pickPolicy },
                                ]}
                              />
                            );
                        }}
                />

                <GridColumn
                  headerCell={() => {
                            return (
                              <HeaderCell elements={[
                                    { id: 'added-from', value: 'Added From' },
                                    { id: 'call-off', value: 'Call Off #' },
                                ]}
                              />
                            );
                        }}
                  cell={(data) => {
                            return (
                              <RowCell elements={[
                                    { id: 'added-from', value: data.dataItem.addedFrom },
                                    { id: 'call-off', value: data.dataItem.callOffNumber },
                                ]}
                              />
                            );
                        }}
                />

                <GridColumn
                  headerCell={() => {
                            return (
                              <HeaderCell elements={[
                                    { id: 'avail-qty', value: 'Avail Qty' },
                                    { id: 'avail-uom', value: 'Avail UoM' },
                                ]}
                              />
                            );
                        }}
                  cell={(data) => {
                            return (
                              <RowCell elements={[
                                    {
                                        id: 'avail-qty',
                                        value: (
                                          <div>
                                            {data.dataItem.availableQuantity || ''}
                                          </div>),
                                    },
                                    { id: 'avail-uom', value: data.dataItem.availableUnitOfMeasurement },
                                ]}
                              />
                            );
                        }}
                />

                <GridColumn
                  headerCell={() => {
                            return (
                              <HeaderCell elements={[
                                    { id: 'export-qty', value: 'Qty' },
                                    { id: 'uom', value: 'UoM' },
                                ]}
                              />
                            );
                        }}
                  cell={(data) => {
                            return (
                              <RowCell elements={[
                                    {
                                        id: 'export-qty',
                                        value: (
                                          <div
                                                style={data.dataItem.quantity > 0 && data.dataItem.masterReceivingNumber !== null  ? { cursor: 'pointer', color: '#016496', textDecoration: 'underline' } : {}}
                                                onClick={() => {
                                                if (data.dataItem.quantity > 0 && data.dataItem.masterReceivingNumber !== null) {
                                                        openModal(data.dataItem);
                                                    }
                                                }}
                                          >
                                            {data.dataItem.quantity}
                                          </div>),
                                    },
                                    { id: 'uom', value: data.dataItem.unitOfMeasurement },
                                ]}
                              />
                            );
                        }}
                />

                <GridColumn
                  headerCell={() => {
                            return (
                              <HeaderCell elements={[
                                    { id: 'lbs', value: 'Lbs' },
                                    { id: 'kgs', value: 'Kgs' },
                                ]}
                              />
                            );
                        }}
                  cell={(data) => {
                            return (
                              <RowCell elements={[
                                    { id: 'lbs', value: data.dataItem.lbs },
                                    { id: 'kgs', value: data.dataItem.kgs },
                                ]}
                              />
                            );
                        }}
                />

                <GridColumn
                  headerCell={() => {
                            return (
                              <HeaderCell elements={[
                                    { id: 'ship-date', value: 'Ship Date' },
                                ]}
                              />
                            );
                        }}
                  cell={(data) => {
                            return (
                              <RowCell elements={[
                                    { id: 'ship-date', value: data.dataItem.shipDate ? new Date(data.dataItem.shipDate).toLocaleDateString('en-US') : null, editable: false },
                                ]}
                              />
                            );
                        }}
                />

              </PageGrid>
            )}
        {modalIsOpen && (
        <WarehouseRecievingDetailsModal
          isModalShowing={modalIsOpen}
          handleCloseModal={(shouldRefresh) => handleCloseModal(shouldRefresh)}
          proNumber={proNumber}
          moveType={moveType}
          dataItem={selectedDataItem}
        />
            )}
      </div>
    );
};

export default InventoryUnitGrid;