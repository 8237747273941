import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const AccessRoute = ({ path, component: Component, requiredAccess, ...routeProps }) => {
  const customerAccess = useSelector(state => state.user && state.user.customerAccess);
  const hasAccess = requiredAccess?.some((access) => customerAccess[access]);
  const render = (props) => hasAccess ? <Component {...props} /> : <Redirect to='/access-denied' />;

  return <Route exact {...routeProps} render={render} />;
};

export default AccessRoute;
