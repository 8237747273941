import { handleActions } from 'redux-actions';

const initialState = {
  username: '',
  name: '',
  email: '',
  heapProperties: {}, 
};


export default handleActions(
  {
    SET_USER: (state, { payload }) => {
      return { ...state, ...payload };
    },
    SET_HEAP_PROPERTIES: (state, { payload }) => {
      return { ...state, heapProperties: payload };
    }
  },
  initialState,
);
