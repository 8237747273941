import React from 'react';

import Modal from 'shared/modals/Modal';
import LoadingSpinner from './LoadingSpinner';

const LoadingModal = ({ isOpen, isNested, text }) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={() => {
        /* do nothing */
      }}
      backDropStyle={`margin--none light ${isNested ? 'nested' : ''}`}
      containerStyle='loading'
      className='loading-modal'
    >
      <LoadingSpinner text={text} />
    </Modal>
  );
};

export default LoadingModal;
