import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import LoadingSpinner from 'shared/loading/LoadingSpinner';

const LineChart = ({
  loading,
  theme,
  strokeColors,
  data = [],
  tickRotationBottom = -45,
  legendBottom = '',
  legendBottomOffset = 0,
  legendBottomPosition = 'middle',
  leftFormat = d => `${d}`,
  legendLeft = '',
  legendLeftOffset = 0,
  legendLeftPosition = 'middle',
  tickValuesLeft = undefined,
  yMin = 'auto',
  yMax = 'auto',
  margin = { top: 20, right: 10, bottom: 100, left: 50 },
  axisTop = null,
  axisRight = null,
  // minimumFractionDigits = 2,
  error,
}) => {
  return (
    loading ? <LoadingSpinner /> : (
      error ? (
        <div>There was an error loading data from the api.</div>
      ) : (
        <ResponsiveLine
          data={data}
          margin={margin}
          xScale={{ type: 'point' }}
          yScale={{ type: 'linear', min: yMin, max: yMax, stacked: false, reverse: false }}
          theme={theme}
          tooltip={node => <div style={theme.tooltip.container}>{`${node.point.data.x} - ${node.point.data.y} Orders`}</div>}
          axisTop={axisTop}
          axisRight={axisRight}
          axisBottom={{
            orient: 'bottom',
            tickSize: 10,
            tickPadding: 5,
            tickRotation: tickRotationBottom,
            legend: legendBottom,
            legendOffset: legendBottomOffset,
            legendPosition: legendBottomPosition
          }}
          axisLeft={{
            tickValues: tickValuesLeft,
            orient: 'left',
            tickSize: 10,
            tickPadding: 1.2,
            tickRotation: 0,
            format: leftFormat,
            legend: legendLeft,
            legendOffset: legendLeftOffset,
            legendPosition: legendLeftPosition
          }}
          style={{ cursor: 'pointer' }}
          colors={strokeColors}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={1}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabel='y'
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 100,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
        />
      )
    )
  );
};

export default LineChart;
