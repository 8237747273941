import React from 'react';

const AccessDenied = () => (
  <div className='access-denied'>
    <span>401</span>
    <div>
      <h1>UNAUTHORIZED</h1>
      <p>You are not authorized to access gwiTrack</p>
      <p>For access please contact your Logistics Coordinator</p>
    </div>
  </div>
);

export default AccessDenied;
