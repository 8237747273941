import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import floor from 'lodash/floor';
import ceil from 'lodash/ceil';
import Media from 'react-media';
import { Helmet } from 'react-helmet';
import format from 'date-fns/format';
// Components
import DashboardChart from '../charts/DashboardChart';
import DashboardHeader from 'shared/toolbars/DashboardHeader';
import OldDashboardHeader from 'shared/toolbars/OldDashboardHeader';
import NavMenu from 'shared/layout/NavMenu';
import LoadingSpinner from 'shared/loading/LoadingSpinner';
import { Button } from '@progress/kendo-react-buttons';
import Icon from 'shared/icons/IconComponent';
// Impersonation
import { useImpersonation } from 'shared/../impersonation';
// Fetch
import {
  getTodaysDeliveriesImport,
  getCurrentOrdersImportSummary,
  getImportCategoryTypes,
  getTodaysDeliveriesDomestic,
  getDomesticCategoryTypes,
  getCurrentOrdersDomesticSummary,
  getTodaysDeliveriesTypes,
  getDeliverySchedule,
  getMilestoneTracker,
  getDroppedContainers,
  getGroundedContainers,
  getOrderVolume,
  getTodaysCutoffTypes,
  getTodaysEmptiesTypes,
  getTodaysEarlyReturnsTypes,
  getTodaysCutoffs,
  getTodaysEmpties,
  getTodaysEarlyReturns,
  getTodaysRollingsSummary,
  logSession
} from 'src/fetch';
// Customer Access
import { SELECT_USER, VIEW_MULTI_SEARCH } from 'shared/../constants/CustomerAccess';

// Chart helpers
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const Home = ({ customerAccess, history }) => {
  // Impersonation
  const { isImpersonated, impersonate, startImpersonate } = useImpersonation();

  const [hasLoadedAccounts, setHasLoadedAccounts] = useState(false);
  const [emptyAccounts, setEmptyAccounts] = useState(false);

  // State for static lists
  const [typesLoading, setTypesLoading] = useState(false);
  const [typesError, setTypesError] = useState(false);

  const [legacyAccount, setLegacyAccount] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [showLegacyProperties, setShowLegacyProperties] = useState(true);

  // current customer
  const [currentCustomer, setCurrentCustomer] = useState({ value: isImpersonated ? impersonate : null });

  const handleSetCurrentCustomer = (selected) => {
    startImpersonate(selected);
    setCurrentCustomer(selected);
  };

  // Imports
  const [importLoading, setImportLoading] = useState(true);
  const [importError, setImportError] = useState(false);
  const [importCategoryTypes, setImportCategoryTypes] = useState([]);
  const [importData, setImportData] = useState({});

  // Exports
  const [exportLoading, setExportLoading] = useState(true);
  const [exportError, setExportError] = useState(false);
  const [exportData, setExportData] = useState({});
  const [hasExportData, setHasExportData] = useState(false);
  const [cutoffTypes, setCutoffTypes] = useState([]);
  const [emptiesTypes, setEmptiesTypes] = useState([]);
  const [earlyReturnsTypes, setEarlyReturnsTypes] = useState([]);

  // Domestic
  const [domesticLoading, setDomesticLoading] = useState(true);
  const [domesticCategoryTypes, setDomesticCategoryTypes] = useState([]);
  const [domesticError, setDomesticError] = useState(false);
  const [domesticData, setDomesticData] = useState({});

  // Delivery Schedule
  const [deliveryScheduleLoading, setDeliveryScheduleLoading] = useState();
  const [deliveryScheduleDate, setDeliveryScheduleDate] = useState(new Date());
  const [todaysDeliveriesTypes, setTodaysDeliveriesTypes] = useState([]);
  const [deliveryScheduleError, setDeliveryScheduleError] = useState(false);
  const [deliveryScheduleData, setDeliveryScheduleData] = useState([]);

  useEffect(() => {
    getDeliveryScheduleData({ startDate: format(deliveryScheduleDate, 'yyyy-MM-dd') });
  }, [deliveryScheduleDate]);

  useEffect(() => {
    logSession();
  }, [])

  const addWeeksToDeliveryScheduleDate = (weeks) => () => {
    const date = new Date(deliveryScheduleDate);
    date.setDate(deliveryScheduleDate.getDate() + (weeks * 7));
    setDeliveryScheduleDate(date);
  };

  // Milestones
  const [milestonesLoading, setMilestonesLoading] = useState();
  const [milestonesError, setMilestonesError] = useState(false);
  const [milestonesData, setMilestonesData] = useState({});

  // `Drops`
  const [dropsLoading, setDropsLoading] = useState();
  const [dropsError, setDropsError] = useState(false);
  const [dropsData, setDropsData] = useState({});

  // Grounded Containers
  const [groundedContainersLoading, setGroundedContainersLoading] = useState();
  const [groundedContainersError, setGroundedContainersError] = useState(false);
  const [groundedContainersData, setGroundedContainersData] = useState({});

  // Order Volume
  const [orderVolumeLoading, setOrderVolumeLoading] = useState();
  const [orderVolumeError, setOrderVolumeError] = useState(false);
  const [orderVolumeData, setOrderVolumeData] = useState([]);
  const [orderVolumeMax, setOrderVolumeMax] = useState(0);
  const [orderVolumeMin, setOrderVolumeMin] = useState(0);
  const [orderVolumeTotals, setOrderVolumeTotals] = useState({});
  // Nav Menu

  const [isCollapsed, setIsCollapsed] = useState(true);

  const getTypes = async () => {
    setTypesLoading(true);
    try {
      const [
        importCategoryTypes,
        deliveriesTypes,
        todaysCutoffTypes,
        todaysEmptiesTypes,
        todaysEarlyReturnsTypes,
        domesticCategoryTypes,
      ] = await Promise.all([
        getImportCategoryTypes(),
        getTodaysDeliveriesTypes(),
        getTodaysCutoffTypes(),
        getTodaysEmptiesTypes(),
        getTodaysEarlyReturnsTypes(),
        getDomesticCategoryTypes(),
      ]);

      setImportCategoryTypes(importCategoryTypes);
      setTodaysDeliveriesTypes(deliveriesTypes);
      setCutoffTypes(todaysCutoffTypes);
      setEmptiesTypes(todaysEmptiesTypes);
      setEarlyReturnsTypes(todaysEarlyReturnsTypes);
      setDomesticCategoryTypes(domesticCategoryTypes);

      setTypesLoading(false);
    } catch (error) {
      setTypesLoading(false);
      setTypesError(true);
    }
  };

  useEffect(() => {
    getTypes();
  }, []);

  const getImportData = async () => {
    setImportLoading(true);
    try {
      const [importDeliveries, importOrders] = await Promise.all([
        getTodaysDeliveriesImport({ LegacyAccounts: legacyAccount, Organizations: organization, EmailAddress: currentCustomer.value }),
        getCurrentOrdersImportSummary({ LegacyAccounts: legacyAccount, Organizations: organization, EmailAddress: currentCustomer.value }),
      ]);

      const combinedImportData = {
        deliveries: importDeliveries,
        orders: importOrders,
      };

      setImportData(combinedImportData);
      setImportLoading(false);
    } catch (error) {
      setImportLoading(false);
      setImportError(true);
    }
  };

  const getExportData = async () => {
    setExportLoading(true);

    try {
      const [todaysCutoffs, todaysEmpties, todaysEarlyReturns, todaysRollingsSummary] = await Promise.all([
        getTodaysCutoffs({ LegacyAccounts: legacyAccount, Organizations: organization, EmailAddress: currentCustomer.value }),
        getTodaysEmpties({ LegacyAccounts: legacyAccount, Organizations: organization, EmailAddress: currentCustomer.value }),
        getTodaysEarlyReturns({ LegacyAccounts: legacyAccount, Organizations: organization, EmailAddress: currentCustomer.value }),
        getTodaysRollingsSummary({ LegacyAccounts: legacyAccount, Organizations: organization, EmailAddress: currentCustomer.value }),
      ]);

      const combinedExportData = {
        cutoffs: todaysCutoffs,
        empties: todaysEmpties,
        earlyReturns: todaysEarlyReturns,
        rollings: todaysRollingsSummary,
      };

      const hasCutoffs = Object.values(combinedExportData.cutoffs).some((x) => x > 0);
      const hasEmpties = Object.values(combinedExportData.empties).some((x) => x > 0);
      const hasEarlyReturns = Object.values(combinedExportData.earlyReturns).some((x) => x > 0);
      const hasRollings = Object.values(combinedExportData.rollings).some((x) => x > 0);

      setHasExportData(hasCutoffs || hasEmpties || hasEarlyReturns || hasRollings);

      setExportData(combinedExportData);
      setExportLoading(false);
    } catch (error) {
      setExportLoading(false);
      setExportError(true);
    }
  };

  const getDomesticData = async () => {
    setDomesticLoading(true);

    try {
      const [domesticDeliveries, domesticOrders] = await Promise.all([
        getTodaysDeliveriesDomestic({ LegacyAccounts: legacyAccount, Organizations: organization, EmailAddress: currentCustomer.value }),
        getCurrentOrdersDomesticSummary({ LegacyAccounts: legacyAccount, Organizations: organization, EmailAddress: currentCustomer.value }),
      ]);

      const combinedDomesticData = {
        deliveries: domesticDeliveries,
        orders: domesticOrders,
      };

      setDomesticData(combinedDomesticData);
      setDomesticLoading(false);
    } catch (error) {
      setDomesticLoading(false);
      setDomesticError(true);
    }
  };

  const getDeliveryScheduleData = async (parameters = {}) => {
    setDeliveryScheduleLoading(true);

    try {
      const scheduleData = await getDeliverySchedule({
        LegacyAccounts: legacyAccount,
        Organizations: organization,
        EmailAddress: currentCustomer.value,
        ...parameters,
      });

      setDeliveryScheduleData(scheduleData.dates.map((d) => ({ ...d, formattedDate: format(new Date(d.date), 'eee M/dd') })));
      setDeliveryScheduleLoading(false);
    } catch (error) {
      setDeliveryScheduleLoading(false);
      setDeliveryScheduleError(true);
    }
  };

  const getMilestoneData = async () => {
    setMilestonesLoading(true);

    try {
      const milestonesResponse = await getMilestoneTracker({
        LegacyAccounts: legacyAccount,
        Organizations: organization,
        EmailAddress: currentCustomer.value,
      });

      setMilestonesData(milestonesResponse);
      setMilestonesLoading(false);
    } catch (error) {
      setMilestonesLoading(false);
      setMilestonesError(true);
    }
  };

  const getDropsData = async () => {
    setDropsLoading(true);

    try {
      const dropsResponse = await getDroppedContainers({
        LegacyAccounts: legacyAccount,
        Organizations: organization,
        EmailAddress: currentCustomer.value,
      });

      setDropsData(dropsResponse);
      setDropsLoading(false);
    } catch (error) {
      setDropsLoading(false);
      setDropsError(true);
    }
  };

  const getGroundedContainersData = async () => {
    setGroundedContainersLoading(true);

    try {
      const groundedContainersResponse = await getGroundedContainers({
        LegacyAccounts: legacyAccount,
        Organizations: organization,
        EmailAddress: currentCustomer.value,
      });

      setGroundedContainersData(groundedContainersResponse);
      setGroundedContainersLoading(false);
    } catch (error) {
      setGroundedContainersLoading(false);
      setGroundedContainersError(true);
    }
  };

  const getOrderVolumeData = async () => {
    setOrderVolumeLoading(true);

    try {
      const orderVolumeResponse = await getOrderVolume({
        LegacyAccounts: legacyAccount,
        Organizations: organization,
        EmailAddress: currentCustomer.value,
      });

      const volumeData = [];

      orderVolumeResponse.yearlyVolume.forEach((year) => {
        const yearData = {
          id: year.year,
          data: year.monthlyVolume.map((month, i) => ({
            id: 'month',
            x: months[i],
            y: month.totalVolume,
          })),
        };
        volumeData.push(yearData);
      });

      const lows = [];
      volumeData.forEach((year) => {
        const min = floor(
          Math.min.apply(
            null,
            year.data.map((month) => month.y),
          ),
          -2,
        );
        lows.push(min);
      });

      setOrderVolumeMin(Math.min(...lows));

      const highs = [];
      volumeData.forEach((year) => {
        const max = ceil(
          Math.max.apply(
            null,
            year.data.map((month) => month.y),
          ),
          -2,
        );
        highs.push(max);
      });

      setOrderVolumeMax(Math.min(...highs));

      setOrderVolumeTotals({ direct: orderVolumeResponse.totalDirectVolume, warehouse: orderVolumeResponse.totalWarehouseVolume });
      setOrderVolumeData(volumeData);
      setOrderVolumeLoading(false);
    } catch (error) {
      setOrderVolumeLoading(false);
      setOrderVolumeError(true);
    }
  };

  // this handles setting properties on the volume summary chart.
  const handleVolumeSummaryChart = () => {
    if (organization && organization.length > 0) {
      setShowLegacyProperties(false);
    } else {
      setShowLegacyProperties(true);
    }
  };

  // this loads or reloads all the charts on the page.
  const loadChartData = () => {
    getImportData();
    getExportData();
    getDomesticData();
    getDeliveryScheduleData();
    getMilestoneData();
    getDropsData();
    getOrderVolumeData();
    getGroundedContainersData();
  };

  // this fires when there are changes to customer, accounts, and legacy properties.
  useEffect(() => {
    handleVolumeSummaryChart();
  }, [showLegacyProperties, legacyAccount, organization, currentCustomer, hasLoadedAccounts]);

  const importTotal = importData.deliveries
    && importData.orders
    && Object.values(importData.deliveries)
      .concat(Object.values(importData.orders))
      .reduce((acc, count) => acc + count, 0);

  const domesticTotal = domesticData.deliveries
    && domesticData.orders
    && Object.values(domesticData.deliveries)
      .concat(Object.values(domesticData.orders))
      .reduce((acc, count) => acc + count, 0);

  const handleToggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const handleSideNavToggle = () => {
    setIsCollapsed(!isCollapsed);
    history.push('/search');
  };

  return (
    <div className='home home-page'>
      <Helmet title='Dashboard | gwiTrack | Gulf Winds' />
      {customerAccess && customerAccess[VIEW_MULTI_SEARCH] ? (
        <DashboardHeader
          customerAccess={customerAccess}
          setCurrentCustomer={handleSetCurrentCustomer}
          setLegacyAccount={setLegacyAccount}
          setOrganization={setOrganization}
          setHasLoadedAccounts={setHasLoadedAccounts}
          setEmptyAccounts={setEmptyAccounts}
          loadChartData={(values) => loadChartData(values)}
          showSwitcher
          initialCustomer={currentCustomer?.value ? currentCustomer : null}
          onToggle={() => handleSideNavToggle()}
          isOpen={!isCollapsed}
        />
      ) : (
        <OldDashboardHeader
          customerAccess={customerAccess}
          setCurrentCustomer={handleSetCurrentCustomer}
          setLegacyAccount={setLegacyAccount}
          setOrganization={setOrganization}
          setHasLoadedAccounts={setHasLoadedAccounts}
          setEmptyAccounts={setEmptyAccounts}
          loadChartData={(values) => loadChartData(values)}
          showSwitcher
          onToggle={() => handleToggleSidebar()}
          isOpen={!isCollapsed}
        />
      )}
      <div className='page-content'>
        <NavMenu Collapsed={isCollapsed} />
        {typesLoading || (!hasLoadedAccounts &&
                  <LoadingSpinner />
        )}
        {customerAccess[SELECT_USER] && emptyAccounts && hasLoadedAccounts && (
          <div className='no-result'>
            <div className='no-result-title'>No Results Found</div>
            <div className='no-result-text'>Select a customer account to see their dashboard</div>
          </div>
        )}
        {!typesLoading && !emptyAccounts && hasLoadedAccounts && (
          <div className='charts-container'>
            <div className='charts-wrapper'>
              <Media queries={{ small: { maxWidth: 599 } }}>
                {(matches) => (
                  <>
                    {importTotal > 0 && (
                      <DashboardChart 
                        title='Imports'
                        className='imports-bucket'
                        subtitle='Status of Open Import Orders'
                        chartType='moveType'
                        moveType={0}
                        loading={importLoading}
                        data={importData}
                        error={importError}
                        legacyAccount={legacyAccount}
                        organization={organization}
                        importCategoryTypes={importCategoryTypes}
                        todaysDeliveriesTypes={todaysDeliveriesTypes}
                        currentCustomer={currentCustomer}
                      />
                    )}
                    {hasExportData && (
                      <DashboardChart
                        title='Exports'
                        className='exports-bucket'
                        subtitle='Status of Open Export Orders'
                        chartType='export'
                        moveType={1}
                        loading={exportLoading}
                        data={exportData}
                        error={exportError}
                        legacyAccount={legacyAccount}
                        organization={organization}
                        cutoffTypes={cutoffTypes}
                        emptiesTypes={emptiesTypes}
                        earlyReturnsTypes={earlyReturnsTypes}
                        currentCustomer={currentCustomer}
                      />
                    )}
                    {domesticTotal > 0 && (
                      <DashboardChart
                        title='Domestic'
                        className='domestic-bucket'
                        subtitle='Status of Open Domestic Orders'
                        chartType='moveType'
                        moveType={2}
                        loading={domesticLoading}
                        data={domesticData}
                        error={domesticError}
                        legacyAccount={legacyAccount}
                        organization={organization}
                        domesticCategoryTypes={domesticCategoryTypes}
                        todaysDeliveriesTypes={todaysDeliveriesTypes}
                        currentCustomer={currentCustomer}
                      />
                    )}
                    <DashboardChart
                      title='Delivery Schedule'
                      className='delivery-schedule delivery-schedule-bucket'
                      subtitle='Total Order Counts // Weekly'
                      chartType='deliverySchedule'
                      loading={deliveryScheduleLoading}
                      error={deliveryScheduleError}
                      data={deliveryScheduleData}
                      legacyAccount={legacyAccount}
                      organization={organization}
                      currentCustomer={currentCustomer}
                      detailsComponent={
                        !deliveryScheduleLoading && (
                          <div className='week-buttons-wrapper'>
                            <Button className='week-button previous-week' onClick={addWeeksToDeliveryScheduleDate(-1)}>
                              <Icon icon='chevron-left' position='left' />
                                    Previous Week
                            </Button>
                            <Button className='week-button next-week' onClick={addWeeksToDeliveryScheduleDate(1)}>
                                    Next Week
                              <Icon icon='chevron-right' position='right' />
                            </Button>
                          </div>
                        )
                      }
                    />
                    <DashboardChart
                      title='Milestone Tracker'
                      className='milestone-tracker-bucket'
                      subtitle='Order Progress Towards Key Dates'
                      chartType='milestone'
                      loading={milestonesLoading}
                      data={milestonesData}
                      error={milestonesError}
                      legacyAccount={legacyAccount}
                      organization={organization}
                      showResponsive={matches.small}
                      currentCustomer={currentCustomer}
                    />
                    {!dropsLoading && dropsData && Object.values(dropsData).some((count) => count > 0) && (
                      <DashboardChart
                        title='Dropped Containers'
                        className='dropped-containers-bucket'
                        subtitle='Location and Status of All Dropped Containers'
                        chartType='drop'
                        loading={dropsLoading}
                        data={dropsData}
                        error={dropsError}
                        legacyAccount={legacyAccount}
                        organization={organization}
                        refreshDrops={getDropsData}
                        currentCustomer={currentCustomer}
                      />
                    )}
                    <DashboardChart
                      title='Volume Summary'
                      className='volume-summary-bucket'
                      subtitle='Order Counts by Month'
                      chartType='line'
                      error={orderVolumeError}
                      loading={orderVolumeLoading}
                      data={orderVolumeData}
                      yMin={orderVolumeMin}
                      yMax={orderVolumeMax}
                      legacyAccount={legacyAccount}
                      organization={organization}
                      currentCustomer={currentCustomer}
                      detailsComponent={
                        !orderVolumeLoading && (
                          <>
                            {showLegacyProperties && (
                              <div>
                                      Direct:
                                {' '}
                                {orderVolumeTotals.direct}
                              </div>
                            )}
                            {showLegacyProperties && (
                              <div>
                                      Warehouse:
                                {' '}
                                {orderVolumeTotals.warehouse}
                              </div>
                            )}
                          </>
                        )
                      }
                    />
                    {!groundedContainersLoading && groundedContainersData && Object.values(groundedContainersData).some((count) => count > 0) && (
                      <DashboardChart
                        title='Grounded Containers'
                        className='grounded-containers-bucket'
                        subtitle='Location and Status of All Grounded Containers'
                        chartType='grounded'
                        loading={groundedContainersLoading}
                        data={groundedContainersData}
                        error={groundedContainersError}
                        legacyAccount={legacyAccount}
                        organization={organization}
                        refreshDrops={getGroundedContainersData}
                        currentCustomer={currentCustomer}
                      />
                    )}
                  </>
                )}
              </Media>
            </div>
          </div>
        )}
        {typesError && <div className='feedback'>Problem loading data. Please refresh to try again.</div>}
      </div>

    </div>
  );
};

export default connect(
  (state) => ({
    customerAccess: state.user && state.user.customerAccess,
    customerLoading: state.user && state.user.isLoading,
  }),
  {},
)(Home);
