import React, { useState } from 'react';
import LoadingSpinner from 'shared/loading/LoadingSpinner';
import DeliveriesModal from '../../modals/DeliveriesModal';
import 'hammerjs';
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
} from '@progress/kendo-react-charts';

const DeliverySchedulesChart = ({
  loading,
  data = [],
  legacyAccount = {},
  organization = {},
  error,
  currentCustomer,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [date, setDate] = useState(null);
  const [type, setType] = useState(null);

  const handleSelect = ({ series, category }) => {
    const selected = data.find(({ formattedDate }) => formattedDate === category);
    handleOpenModal(series.name, selected.date);
  };

  const handleOpenModal = (type, date) => {
    setType(type);
    setDate(date);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setType(null);
    setDate(null);
  };

  const categories = data?.map(({ formattedDate }) => formattedDate) || [];

  const formattedData = data?.map((d) => ({
    ...d,
    complete: d.complete || '',
    incomplete: d.incomplete || '',
  }));

  return loading ? (
    <LoadingSpinner />
  ) : error ? (
    <div>There was an error loading data from the api.</div>
  ) : (
    <>
      <Chart
        className='delivery-schedules-chart'
        transitions={false}
        seriesDefaults={{
          stack: true,
          tooltip: { visible: true },
          labels: {
            visible: true,
            position: 'insideBase',
            background: 'white',
            font: '14px bold system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            margin: {
              left: 50,
              bottom: -10,
            },
          },
        }}
        onSeriesClick={handleSelect}
      >
        <ChartLegend position='top' orientation='horizontal' />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={categories} />
        </ChartCategoryAxis>
        <ChartSeries>
          <ChartSeriesItem
            type='column'
            data={formattedData}
            field='complete'
            categoryField='formattedDate'
            name='Complete'
            color='#dbdf52'
            labels={{
              margin: {
                bottom: -5,
              },
            }}
          />
          <ChartSeriesItem
            type='column'
            data={formattedData}
            field='incomplete'
            categoryField='formattedDate'
            name='Incomplete'
            color='#131825'
            labels={{
              margin: {
                bottom: 5,
              },
            }}
          />
        </ChartSeries>
      </Chart>
      {modalOpen && (
        <DeliveriesModal
          isModalShowing={modalOpen}
          handleCloseModal={handleCloseModal}
          type={type}
          date={date}
          legacyAccount={legacyAccount}
          organization={organization}
          currentCustomer={currentCustomer}
        />
      )}
    </>
  );
};

export default DeliverySchedulesChart;
