import React from 'react';
import PropTypes from 'prop-types';
// Components
import Modal from './Modal';

const ConfirmDialogue = ({ isOpen, handleClose, headerText = 'Confirm?', text = 'Are you sure?', confirmText = 'Yes', cancelText = 'No' }) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={() => handleClose(false)}
      containerStyle='small'
      className='confirm-modal'
    >
      <div className='modal-header'>
        <h5 className='modal-title' id='add-contact-type-modal'>
          {headerText}
        </h5>
        <button className='button large close' onClick={() => handleClose(false)} />
      </div>
      <section className='contact-type-form'>
        <div className='modal-body'>{text}</div>
        <section className='modal-footer toolbar toolbar--xxs'>
          <div className='toolbar--column toolbar--right'>
            <button
              id='confirm-modal-confirm-button'
              type='button'
              className='toolbar--button--margin button button--large button--primary-color text-align--center'
              onClick={() => handleClose(true)}
            >
              {confirmText}
            </button>
            <button
              id='confirm-modal-cancel-button'
              type='button'
              className='toolbar--button--margin button button--large button--white text-align--center margin--none--xxs'
              onClick={() => handleClose(false)}
            >
              {cancelText}
            </button>
          </div>
        </section>
      </section>
    </Modal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  text: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string
};

export default ConfirmDialogue;
