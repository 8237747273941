import PropTypes from 'prop-types';
import { get } from 'lodash';
// components
import toastr from 'shared/helpers/toastr';

const errorToastr = ({ error, messagePrefix = '', messagePostFix = '', fallbackMessage = '', callback = null }) => {
  let message;
  let detail = get(error, 'response.data.Detail');
  let description = get(error, 'response.data.Description');
  let statusText = get(error, 'response.StatusText');
  if (detail) {
    if (typeof detail === 'string' && detail.indexOf('{') >= 0) {
      try {
        detail = JSON.parse(detail);
        if (detail && detail.message) {
          message = detail.message;
        }
      } catch (err) {
        if (callback) {
          callback(detail);
        }
        return toastr('error', `${messagePrefix} ${fallbackMessage} ${messagePostFix}`);
      }
    } else {
      message = detail;
    }
  } else if (description) {
    message = description;
  } else if (error.response && error.response.statusText) {
    message = statusText;
  } else {
    message = fallbackMessage;
  }
  if (callback) {
    callback(message);
  }
  return toastr('error', `${messagePrefix} ${message} ${messagePostFix}`);
};

errorToastr.propTypes = {
  error: PropTypes.object,
  messagePrefix: PropTypes.string,
  messagePostFix: PropTypes.string,
  fallbackMessage: PropTypes.string,
  callback: PropTypes.func || null
};

export default errorToastr;
