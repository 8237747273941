import React, { useEffect, useRef } from 'react';
// components
import SearchInput from './SearchInput';
import { ChipList } from 'shared/buttons/Chip';

const SearchBox = ({ visible, text, setText, setItems, items, onChipAdd, ...props }) => {
  const onRemoveHandler = (item) => {
    const newItems = items.filter((i) => i !== item);
    setItems(newItems);
  };

  useEffect(() => {
    if (!visible) {
      setText('');
      setItems([]);
    }
  }, [visible]);

  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef && inputRef.current && visible) {
      inputRef.current.focus();
    }
  }, [inputRef.current, visible]);

  return (
    <div className='chipped-search'>
      <div className='formik-row'>
        <SearchInput ref={inputRef} value={text} onChange={setText} onAdd={onChipAdd} {...props} />
      </div>
      <ChipList data={items} onItemRemove={onRemoveHandler} />
    </div>
  );
};

export default SearchBox;
