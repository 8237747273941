import React, { useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { getDayLetter } from 'shared/../helpers/date';
import { dateFormatter, shortDateFormatter } from 'shared/../constants/Formatting';
import LoadingSpinner from 'shared/loading/LoadingSpinner';
import DeliveriesModal from '../modals/DeliveriesModal';

const BarChart = ({
  loading,
  data = [],
  yMin = 'auto',
  yMax = 'auto',
  margin = { top: 20, right: 10, bottom: 100, left: 40 },
  axisTop = null,
  axisRight = null,
  // minimumFractionDigits = 2,
  legacyAccount = {},
  organization = {},
  error,
  currentCustomer
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [date, setDate] = useState(null);
  const [type, setType] = useState(null);

  const handleOpenModal = (type, date) => {
    setType(type);
    setDate(date);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setType(null);
    setDate(null);
  };

  const colors = {
    Complete: '#D9E026',
    Incomplete: '#121726',
  };

  const labelColors = {
    Complete: '#121726',
    Incomplete: '#FFFFFF',
  };

  const getColor = bar => colors[bar.id];

  const getLabelColor = bar => labelColors[bar.data.id];

  const CustomTick = tick => {
    return (
      <g transform={`translate(${tick.x},${tick.y + 5})`}>
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
              fill: '#656565',
              fontSize: 20,
          }}
        >
          <tspan x='0' dy='1em'>{getDayLetter(tick.value)}</tspan>
          <tspan x='0' dy='1em' textLength='23' lengthAdjust='spacingAndGlyphs'>{shortDateFormatter(tick.value)}</tspan>
        </text>
      </g>
    );
  };

  return loading ? <LoadingSpinner /> : (
    error ? (
      <div>There was an error loading data from the api.</div>
    ) : (
      <React.Fragment>
        <ResponsiveBar
          data={data || []}
          keys={['Complete', 'Incomplete']}
          indexBy='date'
          margin={margin}
          padding={0.3}
          colors={getColor}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          tooltip={({ id, value, color, data }) => (
            <div>
              <div style={{ color: '#121726' }}>{dateFormatter(data.date)}</div>
              <strong style={{ color: '#121726' }}>
                {id}: {value}
              </strong>
            </div>
          )}
          onClick={node => handleOpenModal(node.id, node.data.date)}
          onMouseEnter={(_data, event) => (event.currentTarget.style.cursor = 'pointer')}
          onMouseLeave={(_data, event) => (event.currentTarget.style.cursor = 'auto')}
          axisTop={axisTop}
          axisRight={axisRight}
          yMin={yMin}
          maxValue={yMax}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: 32,
            renderTick: CustomTick,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={getLabelColor}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 100,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
        {modalOpen && (
          <DeliveriesModal
            isModalShowing={modalOpen}
            handleCloseModal={handleCloseModal}
            type={type}
            date={date}
            legacyAccount={legacyAccount}
            organization={organization}
            currentCustomer={currentCustomer}
          />
        )}
      </React.Fragment>
    )
  );
};

export default BarChart;
