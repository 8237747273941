import authFetch from './fetch';
import { makeRequest } from './utils';

export const getDeliverySchedule = ({ LegacyAccounts, Organizations, EmailAddress, startDate }) =>
  makeRequest(
    authFetch.post('/api/DeliverySchedules', { LegacyAccounts, Organizations, EmailAddress, startDate }),
  );

export const getDeliveryScheduleByType = ({ LegacyAccounts, Organizations, EmailAddress, Type, DateString }) =>
  makeRequest(
    authFetch.post('/api/DeliverySchedules/ByType', {
      LegacyAccounts,
      Organizations,
      EmailAddress,
      Type,
      Date: DateString,
    }),
  );
