import 'regenerator-runtime/runtime';
import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
// Components
import App from './App';
// Redux
import { Provider } from 'react-redux';
import { createStore } from './redux';
// MSAL setup
import { MsalProvider } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import { msalInstance, clearAdalCache } from './msalConfig';
// styling
import 'toastr/build/toastr.css';
import 'flatpickr/dist/themes/material_green.css';
import './styles/index.scss';
import './shared/css/index.scss';

const { store } = createStore();

const rootElement = document.getElementById('root');

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback(async (event) => {
  // Callback that is fired whenever a user logs into the application. Any logic
  // that we want to run on only login should be placed here.
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </Provider>,
  rootElement,
);

clearAdalCache();
