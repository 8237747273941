import React from 'react';
import { UnauthenticatedTemplate } from '@azure/msal-react';

const LoggedOutTemplate = ({ children }) => {
  if (localStorage.getItem('gwi.accessToken')) {
    return null;
  }
  return <UnauthenticatedTemplate>{children}</UnauthenticatedTemplate>;
};

export default LoggedOutTemplate;
