import React from 'react';
import ReactSelect from 'react-select';
import cn from 'classnames';

const Select = ({
  isAbsolute = false, menuHeight, className, ...selectProps
}) => {
  const { value, options } = selectProps;
  if ((typeof value === 'string' || typeof value === 'number') && options.length) {
    const filteredOptions = options.filter((x) => x.value === value);
    if (filteredOptions.length === 1) {
      selectProps.value = {
        label: filteredOptions[0].label,
        value: filteredOptions[0].value,
      };
    }
  }

  let containerStyle = { margin: 0 };
  if (isAbsolute) {
    containerStyle = { ...containerStyle, position: 'absolute', width: '100%' };
  }

  const selectClassName = cn('select', { [className]: !!className });

  return (
    <ReactSelect
      className={selectClassName}
      classNamePrefix='gwi-select'
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#3384AE',
          primary25: '#3384AE',
          primary50: '#3384AE',
          danger: '#e60000',
        },
      })}
      styles={{
        container: (styles) => ({ ...styles, ...containerStyle }),
        control: (styles) => ({
          ...styles,
          borderRadius: 0,
          borderColor: '#c4c4c4',
          borderWidth: '1px',
          margin: 0,
        }),
        indicatorSeparator: () => ({ display: 'none' }),
        indicatorsContainer: (styles) => ({
          ...styles,
          margin: 0,
          padding: '0.5rem',
        }),
        menu: (styles) => ({
          ...styles,
          zIndex: 11, // resizer on tables is at 10, this must be higher
          maxHeight: menuHeight || styles.maxHeight,
        }),
        menuList: (styles) => ({
          ...styles,
          maxHeight: menuHeight || styles.maxHeight,
        }),
        dropdownIndicator: (styles) => ({
          ...styles,
          margin: 0,
          padding: 0,
        }),
        clearIndicator: (styles) => ({
          ...styles,
          margin: 0,
          padding: 0,
        }),
        option: (styles, props) => ({
          ...styles,
          margin: 0,
          color: !props.isFocused && !props.isSelected ? '#484848' : '#fff',
          ...(props.isDisabled ? { opacity: '0.5' } : {}),
        }),
        multiValue: (styles) => ({
          ...styles,
          color: '#FFFFFF',
          backgroundColor: '#3384ae',
          marginRight: '5px !important', // overrides CSS value for all selects -> has to be important
        }),
        multiValueLabel: (styles) => ({
          ...styles,
          color: '#FFFFFF',
        }),
        multiValueRemove: (styles) => ({
          ...styles,
          ':hover': {
            backgroundColor: '#00517b',
          },
        }),
        placeholder: (styles) => ({ ...styles, color: '#bbbbbb' }),
        valueContainer: (styles) => ({ ...styles, margin: 0 }),
        loadingIndicator: (styles) => ({ ...styles, marginBottom: 0 }),
        loadingMessage: (styles) => ({ ...styles, marginBottom: 0 }),
      }}
      {...selectProps}
      selectProps={selectProps}
    />
  );
};

export default Select;
