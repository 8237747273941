import { lazy } from 'react';
import { VIEW_CUSTOMER_APP } from 'shared/../constants/CustomerAccess';
import { onLazyImportError } from 'shared/../helpers/common';

const AccountPage = lazy(() => import(/* webpackChunkName: "account" */ './AccountPage').catch(onLazyImportError));

const routes = [
  {
    path: '/account',
    exact: true,
    component: AccountPage,
    requiredAccess: [VIEW_CUSTOMER_APP],
  },
];

export default routes;
