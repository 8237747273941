/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// Components
import { Dropdown, DropdownButton, DropdownList } from 'shared/dropdowns/DropdownComponent';
import Icon from 'shared/icons/IconComponent';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
// Helpers
import { logoutUserAndReloadPage } from 'shared/../helpers/auth';
// impersonation
import { useImpersonation } from 'shared/../impersonation';

const LoggedInAsDropdown = () => {
  const user = useSelector((state) => state.user);

  // impersonating

  const { isImpersonated, impersonate, stopImpersonate } = useImpersonation();

  const impersonatedCustomer = useSelector((state) => {
    if (!isImpersonated) {
      return null;
    }
    const customer = state?.customers?.find(({ email }) => (email === impersonate));
    if (!customer) {
      return null;
    }
    return `${customer.firstName} ${customer.lastName} (${customer.email})`;
  });

  const [header, setHeader] = useState('');

  const handleLogout = () => logoutUserAndReloadPage();

  useEffect(() => {
    fetch('Version', {
      credentials: 'same-origin', // required for MS Edge
    })
      .then((response) => response.json())
      .then(({ header }) => setHeader(header))
      .catch(() => console.error('Unable to get Version information.'));
  }, []);

  return (
    <Dropdown
      id='nav-user'
      className='nav--dropdown dropdown--right logged-in-as-dropdown'
      listId='nav-user-list'
      buttonId='nav-user-button'
      hoverIntent='true'
    >
      <DropdownButton
        buttonId='nav-user-button'
        listId='nav-user-list'
        className='button button--large button--transparent dropdown--button display--flex row--middle nav--dropdown-button'
      >
        {isImpersonated && (
          <>
            <BsEye className='font-size--larger color--white' />
            <span className='username display--none display--inline-block--md' title={`Impersonating ${impersonate}`}>
              <span className='color--grey'>Impersonating </span>
              {impersonatedCustomer ?? impersonate}
            </span>
          </>
        )}
        {!isImpersonated && (
          <>
            <Icon icon='user-circle' className='font-size--larger color--white' />
            <span className='username display--none display--inline-block--md' title={user.name}>
              {user.name}
            </span>
          </>
        )}
        <span className='arrow arrow--white icon--position--right' />
      </DropdownButton>
      <DropdownList listId='nav-user-list' buttonId='nav-user-button' className='nav--dropdown-list'>
        {isImpersonated && (
          <div
            className='dropdown--link dropdown--link--larger display--none--md'
            title={`Impersonating ${impersonate}`}
          >
            <span>Impersonating </span>
            <span>{impersonatedCustomer ?? impersonate}</span>
          </div>
        )}
        {isImpersonated && (
          <button
            type='button'
            className='dropdown--link dropdown--link--larger'
            title={`Stop impersonating ${impersonate}`}
            onClick={stopImpersonate}
          >
            <BsEyeSlash className='font-size--larger color--white' />
            <span>Stop impersonating</span>
          </button>
        )}
        <div
          className='dropdown--link dropdown--link--larger display--none--md'
          title={user.name}
          onClick={() => window.open('/account', '_self')}
        >
          {user.name}
        </div>
        <div
          className='dropdown--link dropdown--link--larger'
          onClick={() => window.open('/account', '_self')}
        >
          {user.email || user.username}
        </div>
        <div className='dropdown--link dropdown--link--larger'>{header}</div>
        <button type='button' onClick={handleLogout} className='dropdown--link dropdown--link--larger'>
          Logout
        </button>
      </DropdownList>
    </Dropdown>
  );
};

export default LoggedInAsDropdown;
