import React, { useState } from 'react';
import { dateFormatter } from 'shared/../constants/Formatting';
// Components
import SingleDatePicker from 'shared/inputs/SingleDatePicker';
import toastr from 'shared/helpers/toastr';
import errorToastr from 'shared/helpers/errorToastr';
// Helpers
import { getDateTimeInputFormat } from 'shared/../helpers/date';
// Fetch
import { setDcInfo } from 'src/fetch';

export const OrderDetails = ({ data }) => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dcIn, setDcIn] = useState(data && data.dcIn);
  const [dcOut, setDcOut] = useState(data && data.dcOut);
  const [dcYardLocation, setDcYardLocation] = useState(data && data.dcYardLocation);

  const saveDcInfo = async () => {
    setLoading(true);
    try {
      await setDcInfo(data.proNumber, getDateTimeInputFormat(dcIn), getDateTimeInputFormat(dcOut), dcYardLocation);
      toastr('success', 'DC Info Saved Successfully.');
      setLoading(false);
      setEditing(false);
    } catch (error) {
      errorToastr({
        error,
        messagePrefix: 'DC Info Save Failed'
      });
      setLoading(false);
    }
  };

  const renderLogistics = () => {
    if (data.moveType.toLowerCase() === 'domestic') {
      if (data.broker && data.carrier) {
        return (
          <div className='data-section'>
            <div className='section-title'>Logistics</div>
            <div className='section-info'>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Broker</label>
                </div>
                <div className='info-data'>{data.broker ? data.broker : 'N/A'}</div>
              </div>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Broker Contact</label>
                </div>
                <div className='info-data'>{data.brokerContactName ? data.brokerContactName : 'N/A'}</div>
              </div>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Carrier</label>
                </div>
                <div className='info-data'>{data.carrier ? data.carrier : 'N/A'}</div>
              </div>
              {data.isRailBilled && (
                <div className='info-row'>
                  <div className='info-label'>
                    <label className='label'>Rail Bill Status</label>
                  </div>
                  <div className='info-data'>{data.railBillStatus}</div>
                </div>
              )}
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Truck Type</label>
                </div>
                <div className='info-data'>{data.truckType ? data.truckType.toUpperCase() : 'N/A'}</div>
              </div>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Chassis Type</label>
                </div>
                <div className='info-data'>{data.chassisType ? data.chassisType.toUpperCase() : 'N/A'}</div>
              </div>
            </div>
          </div>
        );
      } else if (data.broker && !data.carrier) {
        return (
          <div className='data-section'>
            <div className='section-title'>Logistics</div>
            <div className='section-info'>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Broker</label>
                </div>
                <div className='info-data'>{data.broker ? data.broker : 'N/A'}</div>
              </div>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Broker Contact</label>
                </div>
                <div className='info-data'>{data.brokerContactName ? data.brokerContactName : 'N/A'}</div>
              </div>
              {data.isRailBilled && (
                <div className='info-row'>
                  <div className='info-label'>
                    <label className='label'>Rail Bill Status</label>
                  </div>
                  <div className='info-data'>{data.railBillStatus}</div>
                </div>
              )}
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Truck Type</label>
                </div>
                <div className='info-data'>{data.truckType ? data.truckType.toUpperCase() : 'N/A'}</div>
              </div>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Chassis Type</label>
                </div>
                <div className='info-data'>{data.chassisType ? data.chassisType.toUpperCase() : 'N/A'}</div>
              </div>
            </div>
          </div>
        );
      } else if (!data.broker && data.carrier) {
        return (
          <div className='data-section'>
            <div className='section-title'>Logistics</div>
            <div className='section-info'>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Carrier</label>
                </div>
                <div className='info-data'>{data.carrier ? data.carrier : 'N/A'}</div>
              </div>
              {data.isRailBilled && (
                <div className='info-row'>
                  <div className='info-label'>
                    <label className='label'>Rail Bill Status</label>
                  </div>
                  <div className='info-data'>{data.railBillStatus}</div>
                </div>
              )}
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Truck Type</label>
                </div>
                <div className='info-data'>{data.truckType ? data.truckType.toUpperCase() : 'N/A'}</div>
              </div>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Chassis Type</label>
                </div>
                <div className='info-data'>{data.chassisType ? data.chassisType.toUpperCase() : 'N/A'}</div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className='data-section'>
          <div className='section-title'>Logistics</div>
          <div className='section-info'>
            <div className='info-row'>
              <div className='info-label'>
                <label className='label'>SCAC</label>
              </div>
              <div className='info-data'>{data.shipLine ? data.shipLine : 'N/A'}</div>
            </div>
            <div className='info-row'>
              <div className='info-label'>
                <label className='label'>Vessel/Voyage</label>
              </div>
              <div className='info-data'>{data.vesselAndVoyage ? data.vesselAndVoyage : 'N/A'}</div>
            </div>
            <div className='info-row'>
              <div className='info-label'>
                <label className='label'>Origin</label>
              </div>
              <div className='info-data'>{data.portOfOrigin ? data.portOfOrigin : 'N/A'}</div>
            </div>
            <div className='info-row'>
              <div className='info-label'>
                <label className='label'>Destination</label>
              </div>
              <div className='info-data'>{data.portOfDischarge ? data.portOfDischarge : 'N/A'}</div>
            </div>
            {data.broker && (
              <React.Fragment>
                <div className='info-row'>
                  <div className='info-label'>
                    <label className='label'>Broker</label>
                  </div>
                  <div className='info-data'>{data.broker ? data.broker : 'N/A'}</div>
                </div>
                <div className='info-row'>
                  <div className='info-label'>
                    <label className='label'>Broker Contact</label>
                  </div>
                  <div className='info-data'>{data.brokerContactName ? data.brokerContactName : 'N/A'}</div>
                </div>
              </React.Fragment>
            )}
            {data.carrier && (
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Carrier</label>
                </div>
                <div className='info-data'>{data.carrier ? data.carrier : 'N/A'}</div>
              </div>
            )}
            {data.isRailBilled && (
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Rail Bill Status</label>
                </div>
                <div className='info-data'>{data.railBillStatus}</div>
              </div>
            )}
            <div className='info-row'>
              <div className='info-label'>
                <label className='label'>Truck Type</label>
              </div>
              <div className='info-data'>{data.truckType ? data.truckType.toUpperCase() : 'N/A'}</div>
            </div>
            <div className='info-row'>
              <div className='info-label'>
                <label className='label'>Chassis Type</label>
              </div>
              <div className='info-data'>{data.chassisType ? data.chassisType.toUpperCase() : 'N/A'}</div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className='detail-section order-details'>
      <div className='title-container'>
        <div className='title'>Order Details</div>
        <div className='title-bar' />
      </div>
      <div className='data-container'>
        {renderLogistics()}
        <div className='data-section'>
          <div className='section-title'>Cargo Information</div>
          <div className='section-info'>
            <div className='info-row'>
              <div className='info-label'>
                <label className='label'>Commodity</label>
              </div>
              <div className='info-data'>{data.commodity ? data.commodity : 'N/A'}</div>
            </div>
            <div className='info-row'>
              <div className='info-label'>
                <label className='label'>Piece Count</label>
              </div>
              <div className='info-data'>{data.pieceCount ? data.pieceCount : 'N/A'}</div>
            </div>
            <div className='info-row'>
              <div className='info-label'>
                <label className='label'>Weight</label>
              </div>
              <div className='info-data'>{`${data.weightLbs ? data.weightLbs.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''} LBS / ${data.weightKgs ? data.weightKgs.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''} KGS`}</div>
            </div>
            <div className='info-row'>
              <div className='info-label'>
                <label className='label'>Seal#</label>
              </div>
              <div className='info-data'>{data.sealNumber ? data.sealNumber : 'N/A'}</div>
            </div>
          </div>
        </div>
        {data.showReeferSection && (
          <div className='data-section'>
            <div className='section-title'>Reefer Settings</div>
            <div className='section-info'>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Temp Control</label>
                </div>
                <div className='info-data'>{data.isReeferTempControl ? data.isReeferTempControl : 'N/A'}</div>
              </div>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Genset</label>
                </div>
                <div className='info-data'>{data.isReeferGenSet ? data.isReeferGenSet : 'N/A'}</div>
              </div>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Temperature</label>
                </div>
                <div className='info-data'>{data.reeferTemperature ? data.reeferTemperature : 'N/A'}</div>
              </div>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Temp Scale</label>
                </div>
                <div className='info-data'>{data.reeferTempscale ? data.reeferTempscale : 'N/A'}</div>
              </div>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Vent Setting</label>
                </div>
                <div className='info-data'>{data.ReeferVentSetting ? data.ReeferVentSetting : 'N/A'}</div>
              </div>
            </div>
          </div>
        )}
        {data.showDcSection && (
          <div className='data-section'>
            <div className='section-title'>
              <div className='dc-title'>DC Information</div>
            </div>
            <div className='dc-section-info'>
              <div className='info-row'>
                <div className='info-row-half'>
                  <div className='info-label'>
                    <label className='label'>DC</label>
                  </div>
                  <div className='info-data'>
                    {`${data.dcNumber ? data.dcNumber : 'N/A'}`}
                  </div>
                </div>
                <div className='info-row-half right'>
                  <div className='info-label'>
                    <label className='label'>Dept</label>
                  </div>
                  <div className='info-data'>
                    {`${data.dcDept ? data.dcDept : 'N/A'}`}
                  </div>
                </div>
              </div>
              <div className='info-row'>
                <div className='info-row-half'>
                  <div className='info-label'>
                    <label className='label'>Priority</label>
                  </div>
                  <div className='info-data'>
                    {`${data.dcPriority ? data.dcPriority : 'N/A'}`}
                  </div>
                </div>
                <div className='info-row-half right'>
                  <div className='info-label'>
                    <label className='label'>WOS</label>
                  </div>
                  <div className='info-data'>
                    {`${data.dcWos ? data.dcWos : 'N/A'}`}
                  </div>
                </div>
              </div>
              <div className='info-row'>
                <div className='info-row-half'>
                  <div className='info-label'>
                    <label className='label'>DC Due Date</label>
                  </div>
                  <div className='info-data'>
                    {`${data.dcDueDate ? dateFormatter(data.dcDueDate) : 'N/A'}`}
                  </div>
                </div>
                <div className='info-row-half right'>
                  <div className='info-label'>
                    <label className='label'>ND Reason</label>
                  </div>
                  <div className='info-data'>
                    {`${data.dcNonDeliveryReason ? data.dcNonDeliveryReason : 'N/A'}`}
                  </div>
                </div>
              </div>
              <div className='info-row'>
                  <div className='info-label'>
                    <label className='label'>Full & Empty Date</label>
                  </div>
                  <div className='info-data'>
                    {`${data.dcFullEmptyDate ? dateFormatter(data.dcFullEmptyDate) : 'N/A'}`}
                  </div>
                </div>
              <div className='info-row'>
                  <div className='info-label'>
                    <label className='label'>Yard Location</label>
                  </div>
                  {!editing ? (
                    <div className='info-data form'>
                      <input
                        disabled
                        type='text'
                        value={dcYardLocation}
                        onChange={e => setDcYardLocation(e.target.value)}
                        className='text-input'
                      />
                    </div>
                  ) : (
                    <div className='info-data form'>
                      <div>
                        <input
                          type='text'
                          value={dcYardLocation}
                          onChange={e => setDcYardLocation(e.target.value)}
                          className='text-input'
                        />
                      </div>
                    </div>
                  )}
                </div>
              <div className='info-row'>
                  <div className='info-label'>
                    <label className='label'>DC In</label>
                  </div>
                  {!editing ? (
                      <SingleDatePicker
                        disabled
                        hideLabel
                        wrapperClass='wrapper-fill-column'
                        label='DC In'
                        value={dcIn}
                        onChange={val => setDcIn(val)}
                        errors={null}
                      />
                  ) : (
                    <div className='info-data form'>
                      <SingleDatePicker
                        hideLabel
                        wrapperClass='wrapper-fill-column'
                        label='DC In'
                        value={dcIn}
                        onChange={val => setDcIn(val)}
                        errors={null}
                      />
                    </div>
                  )}
                </div>
              <div className='info-row'>
                  <div className='info-label'>
                    <label className='label'>DC Out</label>
                  </div>
                  {!editing ? (
                      <SingleDatePicker
                        disabled
                        hideLabel
                        wrapperClass='wrapper-fill-column'
                        label='DC Out'
                        value={dcOut || ''}
                        onChange={val => setDcOut(val)}
                        errors={null}
                      />
                  ) : (
                    <div className='info-data form'>
                      <SingleDatePicker
                        hideLabel
                        wrapperClass='wrapper-fill-column'
                        label='DC Out'
                        value={dcOut || ''}
                        onChange={val => setDcOut(val)}
                        errors={null}
                      />
                    </div>
                  )}
                </div>
              <div className='info-row buttons'>
                <div className='info-row-half' />
                {editing ? (
                  <div className='info-row-half right'>
                    <button
                      disabled={loading}
                      className='button button--primary-color text-align--center save'
                      onClick={() => saveDcInfo()}
                     >
                       Save
                    </button>
                    <button
                      disabled={loading}
                      className='button button--white text-align--center'
                      onClick={() => setEditing(false)}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div className='info-row-half right'>
                    <button
                      className='edit button button text-align--center'
                      onClick={() => setEditing(true)}
                    >
                      Edit
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {data.showInbondInfo && (
          <div className='data-section'>
            <div className='section-title'>Customs</div>
            <div className='section-info'>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Inbond Type</label>
                </div>
                <div className='info-data'>{data.inbondType ? data.inbondType : 'N/A'}</div>
              </div>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Inbond#</label>
                </div>
                <div className='info-data'>{data.inbondNumber ? data.inbondNumber : 'N/A'}</div>
              </div>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Responsible Party</label>
                </div>
                <div className='info-data'>{data.inbondResponsible ? data.inbondResponsible : 'N/A'}</div>
              </div>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Responsible Other</label>
                </div>
                <div className='info-data'>{data.inbondResponsibleOther ? data.inbondResponsibleOther : 'N/A'}</div>
              </div>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Tender Received</label>
                </div>
                <div className='info-data'>{data.isInbondTenderReceived ? data.isInbondTenderReceived : 'N/A'}</div>
              </div>
            </div>
          </div>
        )}
        {data.showConsigneeInfo && (
          <div className='data-section'>
            <div className='section-title'>Consignee Information</div>
            <div className='section-info'>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Consignee</label>
                </div>
                <div className='info-data'>
                  {data.consigneeAddress}
                  <br />
                  {data.consigneeCityStateZip}
                </div>
              </div>
            </div>
          </div>
        )}
        {data.comments && (
          <div className='data-section comments'>
            <div className='section-title'>Comments</div>
            <div className='section-info'>
              <div className='info-row'>
                <div className='info-data'>
                  {data.comments}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
