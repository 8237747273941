import React from 'react';
import Icon from 'shared/icons/IconComponent';

const NoDataComponent = ({ children }) => {
  return (
    <div className='no-results'>
      <div className='alert alert--info-color text-align--center font-size--large' role='alert'>
        <Icon icon='info-circle' /> {children}
      </div>
    </div>
  );
};

export default NoDataComponent;
