import React, { useState, useEffect } from 'react';
// components
import Table from 'shared/tables/Table';
// helpers
import { base64ToArrayBuffer } from 'shared/../helpers/pdf';
// fetch
import { getDocumentList, getDocument } from 'src/fetch';

export const OrderFiles = ({ proNumber }) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(false);

  const loadFiles = async () => {
    setIsLoading(true);
    try {
      const response = await getDocumentList(proNumber);
      setFiles(response);
      setIsLoading(false);
    } catch (error) {
      setApiError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadFiles();
  }, []);

  const handleGetFile = async original => {
    setIsLoading(true);
    try {
      // extract file extension
      const fileType = (original.name).match(/\.([0-9a-z]+)(?:[\?#]|$)/i);
      const response = await getDocument(proNumber, original.id);
      // if we can't determine the file type, use application/octet-stream
      var blob = new Blob([base64ToArrayBuffer(response)], { type: fileType ? fileType : 'application/octet-stream' });
      var link = window.document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${original.name}`; // remove this to open in a new browser
      link.click();
      setIsLoading(false);
    } catch (getError) {
      setIsLoading(false);
    }
  };

  return files.length > 0 && (
    <div className='detail-section order-files'>
      <div className='title-container'>
        <div className='title'>Order Documents</div>
        <div className='title-bar' />
      </div>
      <div className='data-container'>
        <Table
          className='order-files-table table--primary-color'
          data={files}
          apiError={apiError} 
          isLoading={isLoading}
          columns={[
            {
              Header: 'File Name',
              accessor: 'name',
              id: 'name',
              Cell: ({ original }) => {
                return (
                  <div
                    style={{ cursor: 'pointer', color: '#016496', textAlign: 'left', width: '100%' }}
                    onClick={() => handleGetFile(original)}
                  >
                    {original.name}
                  </div>
                );
              }
            },
            { Header: 'Date', accessor: 'date', isDate: true, width: 150 }
          ]}
        />
      </div>
    </div>
  );
};
