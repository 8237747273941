import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
// Components
import LoadingModal from 'shared/loading/LoadingModal';
import MaintenanceTable from 'shared/tables/MaintenanceTable';
import Modal from 'shared/modals/Modal';
// Fetch
import { getImportWarehouseDetails, getExportWarehouseDetails } from 'src/fetch/warehouseDetails';
// Helpers
import format from 'date-fns/format';

const WarehouseRecievingDetailsModal = ({
  isModalShowing,
  handleCloseModal,
  proNumber,
  moveType,
  dataItem,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  const IMPORT_ORDER = 'IMPORT';

  const formatData = (initialData) => {
    const retData = [];
    let unitrecno = 0;
    initialData.forEach((d) => {
      d.expirationDate = d.expirationDate !== null ? format(new Date(d.expirationDate), 'MM/dd/yyyy') : '';
      d.manufactureDate = d.manufactureDate !== null ? format(new Date(d.manufactureDate), 'MM/dd/yyyy') : '';
      if (d.recno === unitrecno) {
        retData.find((r) => r.recno === d.recno).serialNumber += `\n${d.serialNumber}`;
        retData.find((r) => r.recno === d.recno).lotNumber += `\n${d.lotNumber}`;
        retData.find((r) => r.recno === d.recno).expirationDate += `\n${d.expirationDate}`;
        retData.find((r) => r.recno === d.recno).manufactureDate += `\n${d.manufactureDate}`;
      } else {
        retData.push(d);
        unitrecno = d.recno;
      }
    });
    return retData;
  };

  const loadData = async () => {
    try {
      if (moveType === IMPORT_ORDER) {
        const response = await getImportWarehouseDetails(proNumber, dataItem.rcvRecno);
        setData(formatData(response));
      } else {
        const response = await getExportWarehouseDetails(proNumber, dataItem.blRecno);
        setData(formatData(response));
      }
      setLoading(false);
    } catch (apiError) {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(async () => { await loadData(); }, [proNumber, dataItem]);

  const columns = [
    {
      Header: 'Label Recno', accessor: 'labelId', isCentered: true,
    },
    {
      Header: dataItem.masterUnitOfMeasurement, accessor: 'masterQty', isCentered: true,
    },
  ];

  if (dataItem.secondaryUnitOfMeasurement !== '') {
    columns.push({
      Header: dataItem.secondaryUnitOfMeasurement, accessor: 'secondQty', isCentered: true,
    });
    columns.push({
      Header: 'Damages', accessor: 'damagedSecondaryQty', isCentered: true,
    });
  } else {
    columns.push({
      Header: 'Damages', accessor: 'damagedMasterQty', isCentered: true,
    });
  }

  if (data.find((d) => d.serialNumber)) {
    columns.push({ Header: 'Serial#', accessor: 'serialNumber', isCentered: true });
  }

  if (data.find((d) => d.lotNumber)) {
    columns.push(
      {
        Header: 'Lot#', accessor: 'lotNumber', isCentered: true,
      },
    );
  }

  if (data.find((d) => d.expirationDate)) {
    columns.push(
      {
        Header: 'Expiration', accessor: 'expirationDate', isCentered: true,
      },
    );
  }

  if (data.find((d) => d.manufactureDate)) {
    columns.push(
      {
        Header: 'Manufacture', accessor: 'manufactureDate', isCentered: true,
      },
    );
  }

  if (data.find((d) => d.lbs)) {
    columns.push(
      {
        Header: 'Lbs', accessor: 'lbs', isCentered: true,
      },
    );
    columns.push(
      {
        Header: 'Kgs', accessor: 'kgs', isCentered: true,
      },
    );
  }

  columns.push({
    Header: 'Bay Locations', accessor: 'bayLocation', isCentered: true,
  });

  const getHeader = () => 'Warehouse Details';

  const buildCSVHeaders = () => columns
    .filter((col) => col.accessor)
    .map((col) => ({ label: col.Header, key: col.accessor }));

  return (
    <Modal
      isOpen={isModalShowing}
      title={getHeader()}
      handleClose={() => handleCloseModal(true)}
      containerStyle='large'
      className='grounded-containers-modal'
    >
      <div className='modal-header'>
        <h5 className='modal-title' id='drop-modal'>
          Warehouse Details
        </h5>
        <button className='button button--large button--close' onClick={() => handleCloseModal(false)} />
      </div>
      <LoadingModal isOpen={loading} />
      <section className='modal-body'>
        {!error && !loading && (
          <MaintenanceTable
            wrapperClassName='grounded-containers-table-container'
            className='grounded-containers-table table--primary-color'
            data={data}
            apiError={error}
            isLoading={loading}
            columns={columns}
            tableOpts={{
              showPagination: data && data.length > 0,
              showPaginationBottom: true,
              showPageSizeOptions: true,
              pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
              defaultPageSize: 15,
              pageSize: data && data.length ? undefined : 0,
            }}
          />
        )}
        {error && !loading && <div>There was an error loading the data. Please try again.</div>}
      </section>
      <section className='modal-footer toolbar toolbar--xxs'>
        <CSVLink
          id='export-details'
          className='toolbar--button--margin button button--large button--primary-color text-align--center'
          headers={buildCSVHeaders()}
          data={data}
          filename={`${getHeader()}.csv`}
        >
          Export Details
        </CSVLink>
        <button
          id='close-drop-modal-button'
          type='button'
          className='toolbar--button--margin button button--large button--white text-align--center margin--none--xxs'
          onClick={() => handleCloseModal(false)}
        >
          Cancel
        </button>
      </section>
    </Modal>
  );
};

export default connect(
  (state) => ({
    customerAccess: state.user && state.user.customerAccess,
  }),
  {},
)(WarehouseRecievingDetailsModal);
