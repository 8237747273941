import authFetch from './fetch';

export const getTodaysDeliveriesImport = async ({ LegacyAccounts, Organizations, EmailAddress }) => {
  try {
    const { data } = await authFetch.post('api/TodaysDeliveries/ImportSummary', { LegacyAccounts, Organizations, EmailAddress, Category: 0 });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getTodaysDeliveriesDomestic = async ({ LegacyAccounts, Organizations, EmailAddress }) => {
  try {
    const { data } = await authFetch.post('api/TodaysDeliveries/DomesticSummary', { LegacyAccounts, Organizations, EmailAddress, Category: 2 });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getTodaysDeliveriesByType = async ({ LegacyAccounts, Organizations, EmailAddress, Category, Type }) => {
  try {
    const { data } = await authFetch.post('api/TodaysDeliveries/ByType', { LegacyAccounts, Organizations, EmailAddress, Category, Type });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getTodaysDeliveriesTypes = async () => {
  try {
    const { data } = await authFetch.get('api/TodaysDeliveries/Types');
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getTodaysDeliveriesCategories = async () => {
  try {
    const { data } = await authFetch.get('api/TodaysDeliveries/Categories');
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

