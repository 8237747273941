import React from 'react';

const LoadingSpinner = props => {
  return (
    <div className='loading-indicator-wrapper'>
      <div className='loading-indicator' />
      {props.text !== null && <span className='loading-indicator-text'>{props.text || 'Loading...'}</span>}
    </div>
  );
};

export default LoadingSpinner;
