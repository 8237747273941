import { msalInstance } from 'src/msalConfig';

export const logoutUserAndReloadPage = () => {
  localStorage.removeItem('impersonate');
  if (localStorage.getItem('gwi.accessToken')) {
    localStorage.removeItem('gwi.accessToken');
    localStorage.removeItem('gwi.refreshToken');
    window.open('/', '_self');
  } else {
    msalInstance.logoutRedirect();
  }
};
