import authFetch from './fetch';

export const getCurrentOrdersImportSummary = async ({ LegacyAccounts, Organizations, EmailAddress }) => {
  try {
    const { data } = await authFetch.post('api/CurrentOrders/ImportSummary', { LegacyAccounts, Organizations, EmailAddress });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getCurrentOrdersDomesticSummary = async ({ LegacyAccounts, Organizations, EmailAddress }) => {
  try {
    const { data } = await authFetch.post('api/CurrentOrders/DomesticSummary', { LegacyAccounts, Organizations, EmailAddress });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getImportOrdersByType = async ({ LegacyAccounts, Organizations, Status, EmailAddress }) => {
  try {
    const { data } = await authFetch.post('api/CurrentOrders/ImportsByType', { LegacyAccounts, Organizations, EmailAddress, Status });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getDomesticOrdersByType = async ({ LegacyAccounts, Organizations, Status, EmailAddress }) => {
  try {
    const { data } = await authFetch.post('api/CurrentOrders/DomesticsByType', { LegacyAccounts, Organizations, EmailAddress, Status });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getImportCategoryTypes = async () => {
  try {
    const { data } = await authFetch.get('api/CurrentOrders/ImportCategoryTypes');
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getDomesticCategoryTypes = async () => {
  try {
    const { data } = await authFetch.get('api/CurrentOrders/DomesticCategoryTypes');
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};