import authFetch from './fetch';
import { makeRequest } from './utils';

export const getExportWarehouseInformation = (proNumber) => makeRequest(authFetch.get(`api/OrdersWarehouseDetails/${proNumber}/Export/Warehouse`));
export const getImportWarehouseInformation = (proNumber) => makeRequest(authFetch.get(`api/OrdersWarehouseDetails/${proNumber}/Import/Warehouse`));
export const getImportWarehouseDetails = (proNumber, recNo) => makeRequest(authFetch.get(`api/OrdersWarehouseDetails/${proNumber}/Import/Warehouse/Details/${recNo}`));
export const getExportWarehouseDetails = (proNumber, recNo) => makeRequest(authFetch.get(`api/OrdersWarehouseDetails/${proNumber}/Export/Warehouse/Details/${recNo}`));

export const getWarehouseDetails = (orderId, moveType) => {
  const warehouseDetailsUrl = moveType === 'IMPORT' ? `api/OrdersWarehouseDetails/${orderId}/Import/Warehouse/Details`
    : `api/OrdersWarehouseDetails/${orderId}/Export/Warehouse/Details`;

  return makeRequest(
    authFetch.get(warehouseDetailsUrl),
  );
};
