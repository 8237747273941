import React, { useState, useMemo } from 'react';
import LoadingSpinner from 'shared/loading/LoadingSpinner';
import GroundedContainersModal from '../modals/GroundedContainersModal';

const GroundedContainersChart = ({
  loading,
  data,
  legacyAccount = {},
  organization = {},
  refreshData,
  error,
  currentCustomer,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [type, setType] = useState(null);

  const emptyData = useMemo(
    () => data.receivingToday === 0 && data.todaysCutoffs === 0 && data.groundedToday === 0 && data.totalGrounded === 0,
    [data],
  );

  const handleOpenModal = (type) => {
    setType(type);
    setModalOpen(true);
  };

  const handleCloseModal = (shouldRefresh) => {
    setType(null);
    if (shouldRefresh) {
      refreshData();
    }
    setModalOpen(false);
  };

  return (
    <React.Fragment>
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <div>There was an error loading data from the api.</div>
      ) : (
        <React.Fragment>
          <div className='table-chart'>
            <div className='drop-chart-container'>
              {emptyData && <div className='empty-state'>No Grounded Containers</div>}
              {!emptyData && (
                <React.Fragment>
                  <div className='header-row'>
                    <div className='cell name header-cell'>Receiving Today</div>
                    <div className='cell header-cell'>Todays Cutoffs</div>
                    <div className='cell header-cell'>Grounded Today</div>
                    <div className='cell header-cell total'>Total Grounded</div>
                  </div>
                  <div className='drop-row'>
                    <div className='cell name' onClick={() => handleOpenModal(1)}>
                      <div className='name-inner'>{data.receivingToday}</div>
                    </div>
                    <div className='cell pending-drop' onClick={() => handleOpenModal(2)}>
                      <div>{data.todaysCutoffs}</div>
                    </div>
                    <div className='cell' onClick={() => handleOpenModal(3)}>
                      <div>{data.groundedToday}</div>
                    </div>
                    <div className='cell total' onClick={() => handleOpenModal(4)}>
                      <div>{data.totalGrounded}</div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          {modalOpen && (
            <GroundedContainersModal
              isModalShowing={modalOpen}
              handleCloseModal={(shouldRefresh) => handleCloseModal(shouldRefresh)}
              type={type}
              legacyAccount={legacyAccount}
              organization={organization}
              currentCustomer={currentCustomer}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default GroundedContainersChart;
