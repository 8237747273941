import React, { useState, useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// MSAL
import { useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { LoggedInTemplate, LoggedOutTemplate } from 'src/auth/templates';
import RequestInterceptor from './RequestInterceptor';
// Components
import { Alert } from 'styled-alert-component';
import LoadingSpinner from 'shared/loading/LoadingSpinner';
import Layout from 'shared/layout/Layout';
import LoggedOutLayout from 'shared/layout/LoggedOutLayout';
import errorToastr from 'shared/toastr/errorToastr';
import AccessRoute from 'shared/router/AccessRoute';
// impersonation
import { ImpersonationProvider } from 'shared/../impersonation';
// pages routes
import { loggedInAuthRoutes, loggedOutAuthRoutes } from 'src/auth/pageRoutes';
import accountRoutes from 'src/account/pageRoutes';
// Redux
import { setCustomers, setUser, setHeapProperties } from './redux/actions';
// Fetch
import { getUser, getCustomers, getUserHeapProperties } from 'src/fetch';
// Customer Access
import { VIEW_CUSTOMER_APP } from 'shared/../constants/CustomerAccess';
// Pages
import AccessDenied from 'shared/pages/AccessDenied';
import Home from './home/components/pages/Home';
import OldSearch from './search/components/pages/OldSearch';
import Search from './search/components/pages/Search';

import { VIEW_MULTI_SEARCH } from './shared/constants/CustomerAccess';

const LoggedInApp = () => {
  // MSAL auto login to regenerate token if needed
  const gwiGwiAccessToken = localStorage.getItem('gwi.accessToken');
  useMsalAuthentication(gwiGwiAccessToken ? InteractionType.None : InteractionType.Redirect);

  // redux
  const dispatch = useDispatch();
  const customerAccess = useSelector((state) => state.user && state.user.customerAccess);

  // state
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadUser = () => getUser().then((user) => dispatch(setUser(user)));
    const loadUserHeapProperties = () => getUserHeapProperties().then((heapProps) => dispatch(setHeapProperties(heapProps)));
    const loadCustomers = () => getCustomers().then((customers) => dispatch(setCustomers(customers)));

    Promise.all([
      loadUser(),
      loadUserHeapProperties(),
      loadCustomers(),
    ])
      .then(() => setIsLoading(false))
      .catch((error) => errorToastr({ error, messagePrefix: 'Failed to load page.' }));
  }, [dispatch]);

  return (
    <ImpersonationProvider>
      <RequestInterceptor>
        {/* The RequestInterceptor adds Bearer token to all axios requests */}
        <Layout>
          <div>
            {isLoading && <LoadingSpinner />}
            {!isLoading && !customerAccess && <Alert error>Could not load user! Please refresh and try again.</Alert>}
            <Switch>
              <Route exact path='/access-denied' component={AccessDenied} />
              {!isLoading && customerAccess && customerAccess[VIEW_MULTI_SEARCH] && (
                <AccessRoute path='/search/:number?' component={Search} requiredAccess={[VIEW_CUSTOMER_APP]} />
              )}
              {!isLoading && customerAccess && (
                <AccessRoute
                  path='/search/:number?'
                  component={OldSearch}
                  requiredAccess={[VIEW_CUSTOMER_APP]}
                />
              )}
              {/* This route needs to be last */}
              {!isLoading && customerAccess && (
                <AccessRoute
                  exact
                  path='/'
                  component={Home}
                  requiredAccess={[VIEW_CUSTOMER_APP]}
                />
              )}
              {!isLoading && customerAccess && (
                [
                  ...loggedInAuthRoutes,
                  ...accountRoutes,
                ].map((props) => <AccessRoute key={props.path} {...props} />)
              )}
            </Switch>
          </div>
        </Layout>
      </RequestInterceptor>
    </ImpersonationProvider>
  );
};

const LoggedOutApp = () => (
  <LoggedOutLayout>
    <Switch>
      {loggedOutAuthRoutes.map((props) => <Route key={props.path} {...props} />)}
    </Switch>
  </LoggedOutLayout>
);

const App = () => (
  <Router>
    <Suspense fallback={<LoadingSpinner />}>
      <LoggedInTemplate>
        <LoggedInApp />
      </LoggedInTemplate>
      <LoggedOutTemplate>
        <LoggedOutApp />
      </LoggedOutTemplate>
    </Suspense>
  </Router>
);

export default App;
