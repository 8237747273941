import React, { useRef, useState } from 'react';
import { Grid } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import cn from 'classnames';
import './style.scss';

const BaseGrid = ({
  children,
  data,
  total,
  sortable,
  sort,
  onSortChange,
  page,
  pageable,
  onPageChange,
  className,
  onRowSelect,
  columns,
  onItemChange,
  onChangesSaved,
  onChangesDiscarded,
  onEdit,
  editable = false,
  editEnabled = false,
  exportEnabled = true,
  addDisabled = false,
  enableColumnsToDisplay,
  columnsToDisplay,
  onAdd,
  handleAdd,
  excelExport,
  isExporting,
  actionComponents,
  recycleBin,
  showRecycleBin,
  hideRecycleBin,
  onAddMultiple,
  filteringOptions,
  onFilterClear,
  onFilterApply,
  filterApplied,
  currentFilter,
  name,
  ...props
}) => {


  const gridClassName = cn('old-base-grid', {
    [className]: className
  });

  const onSelectionChange = ({ dataItems, endRowIndex }) => {
    if (onRowSelect) {
      const selectedDataItem = dataItems[endRowIndex];
      onRowSelect(selectedDataItem);
    }
  };

  return (
    <>
      <Tooltip openDelay={100} position='right' anchorElement='pointer'>
        <Grid
          resizable
          data={data}
          sortable={sortable}
          sort={sort}
          onSortChange={onSortChange}
          skip={page?.skip}
          pageSize={page?.take}
          total={total}
          pageable={
            pageable && {
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true
            }
          }
          onItemChange={onItemChange}
          onPageChange={onPageChange}
          className={gridClassName}
          selectable={!!onRowSelect}
          onSelectionChange={onSelectionChange}
          {...props}
        >
          {children ? children : null}
        </Grid>
      </Tooltip>
    </>
  );
};

export default BaseGrid;
