import React from 'react';
import Icon from 'shared/icons/IconComponent';

export const TrackingDates = ({ data }) => {

  const renderDateTime = dateTime => {
    if (dateTime === 'hold') {
      return (
        <div className='date-data'>
          <div className='date'>Booking Hold</div>
          <div className='time'></div>
        </div>
      );
    } else {
      if (dateTime && dateTime.split) {
        const split = dateTime.split(' ');
        if (split.length) {
          return (
            <div className='date-data'>
              <div className='date'>{split[0]}</div>
              <div className='time'>{split.length === 2 && split[1] !== '00:00' ? split[1] : ''}</div>
            </div>
          );
        }
      } else {
        return 'N/A';
      }
    }
  };

  return (
    <div className='detail-section tracking-dates'>
      <div className='title-container'>
        <div className='title'>
          {data.isBookingHold ? (
            <span>
              Tracking Dates{' '}
              <Icon icon='exclamation-triangle' className='warning' />
              {`${data.bookingHoldReason ? ` - ${data.bookingHoldReason}` : ''}`}
            </span>
          ) : (
            <span>Tracking Dates</span>
          )}
        </div>
        <div className='title-bar' />
      </div>
      <div className='data-container'>
        {data.targetDelivery && (
          <div className='info-row'>
            <div className='info-label'>
              <label className='label'>Target Delivery</label>
            </div>
            <div className='info-data'>
              {renderDateTime(data.targetDelivery)}
            </div>
          </div>
        )}
        {(data.moveType.toLowerCase() === 'export' || data.moveType.toLowerCase() === 'rail to port') && (
          <div className='info-row'>
            <div className='info-label'>
              <label className='label'>Booking on File</label>
            </div>
            <div className='info-data'>
              {renderDateTime(data.bookingOnFileDate)}
            </div>
          </div>
        )}
        {(data.moveType.toLowerCase() === 'import' || data.moveType.toLowerCase() === 'rail to port') && (
          <div className='info-row'>
            <div className='info-label'>
              <label className='label'>ETA</label>
            </div>
            <div className='info-data'>
              {renderDateTime(data.etaDate)}
            </div>
          </div>
        )}
        {(data.moveType.toLowerCase() === 'import' || data.moveType.toLowerCase() === 'rail to port') && (
          <div className='info-row'>
            <div className='info-label'>
              <label className='label'>Actual Arrival</label>
            </div>
            <div className='info-data'>
              {renderDateTime(data.actualArrival)}
            </div>
          </div>
        )}
        {(data.moveType.toLowerCase() === 'import' || data.moveType.toLowerCase() === 'rail to port') && (
          <div className='info-row'>
            <div className='info-label'>
              <label className='label'>Grounded</label>
            </div>
            <div className='info-data'>
              {renderDateTime(data.groundedDateTime)}
            </div>
          </div>
        )}
        {(data.moveType.toLowerCase() === 'import' || data.moveType.toLowerCase() === 'rail to port') && (
          <div className='info-row'>
            <div className='info-label'>
              <label className='label'>Customs Release</label>
            </div>
            <div className='info-data'>
              {renderDateTime(data.customsReleaseDateTime)}
            </div>
          </div>
        )}
        {(data.moveType.toLowerCase() === 'import' || data.moveType.toLowerCase() === 'rail to port') && (
          <div className='info-row'>
            <div className='info-label'>
              <label className='label'>Line Release</label>
            </div>
            <div className='info-data'>
              {renderDateTime(data.lineReleaseDate)}
            </div>
          </div>
        )}
        {(data.moveType.toLowerCase() === 'import' || data.moveType.toLowerCase() === 'rail to port') && (
          <div className='info-row'>
            <div className='info-label'>
              <label className='label'>Last Free Day</label>
            </div>
            <div className='info-data'>
              {renderDateTime(data.lastFreeDate)}
            </div>
          </div>
        )}
        <div className='info-row'>
          <div className='info-label'>
            <label className='label'>Available</label>
          </div>
          <div className='info-data'>
            {renderDateTime(data.available)}
          </div>
        </div>
        {data.dropRelease && (
          <div className='info-row'>
            <div className='info-label'>
              <label className='label'>Drop Release</label>
            </div>
            <div className='info-data'>
              {renderDateTime(data.dropRelease)}
            </div>
          </div>
        )}
        {(data.moveType.toLowerCase() === 'export' || data.moveType.toLowerCase() === 'rail to port') && (
          <div className='info-row'>
            <div className='info-label'>
              <label className='label'>First Receiving Date</label>
            </div>
            <div className='info-data'>
              {data.isBookingHold ? renderDateTime('hold') : renderDateTime(data.firstReceival)}
            </div>
          </div>
        )}
        {(data.moveType.toLowerCase() === 'export' || data.moveType.toLowerCase() === 'rail to port') && (
          <div className='info-row'>
            <div className='info-label'>
              <label className='label'>Document Cut-off</label>
            </div>
            <div className='info-data'>
              {data.isBookingHold ? renderDateTime('hold') : renderDateTime(data.documentCutoff)}
            </div>
          </div>
        )}
        {(data.moveType.toLowerCase() === 'export' || data.moveType.toLowerCase() === 'rail to port') && (
          <div className='info-row'>
            <div className='info-label'>
              <label className='label'>Cut-off</label>
            </div>
            <div className='info-data'>
              {data.isBookingHold ? renderDateTime('hold') : renderDateTime(data.cutoff)}
            </div>
          </div>
        )}
        <div className='info-row'>
          <div className='info-label'>
            <label className='label'>Per Diem LFD</label>
          </div>
          <div className='info-data'>
            {renderDateTime(data.perDiemLfd)}
          </div>
        </div>
        <div className='info-row'>
          <div className='info-label'>
            <label className='label'>Trace Terminate</label>
          </div>
          <div className='info-data'>
            {renderDateTime(data.traceTerminate)}
          </div>
        </div>
      </div>
    </div>
  );
};
