import React from 'react';

const SummaryStatus = ({ category, count, text, circleClassName, types = [], handleOpenModal }) => {
  return (
    <div
      className={`summary-status ${count > 0 ? 'active' : ''}`}
      onClick={() => {
        if (count > 0) {
          handleOpenModal(
            category,
            types.length && types.find((x) => x.text === text).value,
            `Exports - ${text} Containers`,
          );
        }
      }}
    >
      <div className='count'>{count}</div>
      <div className={`circle ${circleClassName}`} />
      <div className='summary-legend'>{text}</div>
    </div>
  );
};

export default SummaryStatus;
