import { handleActions } from 'redux-actions';

const initialState = {
  legacyAccounts: [],
  organizations: [],
};

export default handleActions(
  {
    SET_LEGACY_ACCOUNTS: (state, { payload }) => {
      return { ...state, legacyAccounts: [...payload] };
    },
    SET_ORGANIZATIONS: (state, { payload }) => {
      return { ...state, organizations: [...payload] };
    },
  },
  initialState
);
