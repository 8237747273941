import React from 'react';
import cn from 'classnames';
import { ChipList } from '@progress/kendo-react-buttons';
import BaseChip from './Chip';
import './style.scss';

const BaseChipList = ({
  flat = true,
  onItemRemove,
  className,
  selection = 'none',
  textField = 'value',
  valueField = 'value',
  data,
  ...props
}) => {
  const listData = flat ? data.map((item) => ({ value: item })) : data;

  const removeItemHandler = (dataItem) => () => {
    const value = dataItem[valueField];
    onItemRemove(value);
  };

  const listClassName = cn('base-chip-list', { [className]: className });

  return (
    <ChipList
      className={listClassName}
      chip={(props) => <BaseChip {...props} onRemove={removeItemHandler(props.dataItem)} />}
      selection={selection}
      data={listData}
      textField={textField}
      valueField={valueField}
      {...props}
    />
  );
};

export default BaseChipList;
