import React, { useState } from 'react';
import LoadingSpinner from 'shared/loading/LoadingSpinner';
import ExportModal from '../../modals/ExportModal';
import { SummaryStatus } from './controls';

const ExportChart = ({
  loading,
  data,
  legacyAccount = {},
  organization = {},
  error,
  cutoffTypes = [],
  emptiesTypes = [],
  earlyReturnsTypes = [],
  currentCustomer,
}) => {
  // modals
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCategory, setModalCategory] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [modalName, setModalName] = useState(null);

  const handleOpenModal = (category, type, name) => {
    setModalCategory(category);
    setModalType(type);
    setModalName(name);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalCategory(null);
    setModalType(null);
    setModalName(null);
    setModalOpen(false);
  };

  return loading ? (
    <LoadingSpinner />
  ) : error ? (
    <div>There was an error loading data from the api.</div>
  ) : (
    <>
      <div className='summary-chart exports'>
        <div className='summary-summary'>{`Today's Cutoffs: ${
          data.cutoffs.dispatched + data.cutoffs.empty + data.cutoffs.loaded + data.cutoffs.terminated
        }`}</div>
        <div className='status-container'>
          <SummaryStatus
            category='cutoffs'
            count={data.cutoffs.empty}
            text='Empty'
            circleClassName='empty'
            types={cutoffTypes}
            handleOpenModal={handleOpenModal}
          />

          <div className='summary-divider exports' />

          <SummaryStatus
            category='cutoffs'
            count={data.cutoffs.loaded}
            text='Loaded'
            circleClassName='loaded'
            types={cutoffTypes}
            handleOpenModal={handleOpenModal}
          />

          <div className='summary-divider exports' />

          <SummaryStatus
            category='cutoffs'
            count={data.cutoffs.dispatched}
            text='Dispatched'
            circleClassName='dispatched'
            types={cutoffTypes}
            handleOpenModal={handleOpenModal}
          />

          <div className='summary-divider exports' />

          <SummaryStatus
            category='cutoffs'
            count={data.cutoffs.terminated}
            text='Terminated'
            circleClassName='terminated'
            types={cutoffTypes}
            handleOpenModal={handleOpenModal}
          />
        </div>
      </div>
      <div className='export-charts'>
        <div className='rollings'>
          <div className='returns-summary'>{`Rollings: ${
            data.rollings.rollings
          }`}</div>
          <div className='status-container'>
            <SummaryStatus
              category='rollings'
              count={data.rollings.rollings}
              text='Rolling'
              circleClassName='rolling'
              types={cutoffTypes}
              handleOpenModal={handleOpenModal}
            />
          </div>
        </div>

        <div className='empties'>
          <div className='empties-summary'>{`Today's Empties: ${data.empties.scheduled + data.empties.delivered}`}</div>
          <div className='status-container'>
            <div
              className={`empties-status ${data.empties.scheduled > 0 ? 'active' : ''}`}
              onClick={() => {
                if (data.empties.scheduled > 0) {
                  handleOpenModal(
                    'empties',
                    emptiesTypes.length && emptiesTypes.find((x) => x.text === 'Scheduled').value,
                    'Exports - Scheduled Empty Containers',
                  );
                }
              }}
            >
              <div className='count'>{data.empties.scheduled}</div>
              <div className='circle scheduled' />
              <div className='empties-legend'>Scheduled</div>
            </div>

            <div className='empties-divider' />

            <div
              className={`empties-status ${data.empties.delivered > 0 ? 'active' : ''}`}
              onClick={() => {
                if (data.empties.delivered > 0) {
                  handleOpenModal(
                    'empties',
                    emptiesTypes.length && emptiesTypes.find((x) => x.text === 'Delivered').value,
                    'Exports - Delivered Empty Containers',
                  );
                }
              }}
            >
              <div className='count'>{data.empties.delivered}</div>
              <div className='circle delivered' />
              <div className='empties-legend'>Delivered</div>
            </div>
          </div>
        </div>

        <div className='returns'>
          <div className='returns-summary'>{`Today's Early Returns: ${
            data.earlyReturns.scheduled + data.earlyReturns.delivered
          }`}</div>
          <div className='status-container'>
            <div
              className={`returns-status ${data.earlyReturns.scheduled > 0 ? 'active' : ''}`}
              onClick={() => {
                if (data.earlyReturns.scheduled > 0) {
                  handleOpenModal(
                    'earlyReturns',
                    earlyReturnsTypes.length && earlyReturnsTypes.find((x) => x.text === 'Scheduled').value,
                    'Exports - Scheduled Empty Containers',
                  );
                }
              }}
            >
              <div className='count'>{data.earlyReturns.scheduled}</div>
              <div className='circle scheduled' />
              <div className='returns-legend'>Scheduled</div>
            </div>

            <div className='returns-divider' />

            <div
              className={`returns-status ${data.earlyReturns.delivered > 0 ? 'active' : ''}`}
              onClick={() => {
                if (data.earlyReturns.delivered > 0) {
                  handleOpenModal(
                    'earlyReturns',
                    earlyReturnsTypes.length && earlyReturnsTypes.find((x) => x.text === 'Delivered').value,
                    'Exports - Terminated Empty Containers',
                  );
                }
              }}
            >
              <div className='count'>{data.earlyReturns.delivered}</div>
              <div className='circle delivered' />
              <div className='returns-legend'>Terminated</div>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <ExportModal
          isModalShowing={modalOpen}
          handleCloseModal={handleCloseModal}
          category={modalCategory}
          type={modalType}
          name={modalName}
          legacyAccount={legacyAccount}
          organization={organization}
          currentCustomer={currentCustomer}
        />
      )}
    </>
  );
};

export default ExportChart;
