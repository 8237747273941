import React from 'react';
import { NavLink } from 'react-router-dom';
// Components
import Header from 'shared/layout/Header';
import Body from 'shared/layout/Body';
import Footer from 'shared/layout/Footer';

const LoggedOutLayout = ({ children }) => (
  <div className='wrapper margin--off main' id='wrapper'>
    <div className='wrapper react-wrapper margin--off'>
      <Header
        leftChildren={(
          <div className='logo'>
            <NavLink exact to='/' className='logo--link'>
              <img
                src={require('shared/../imgs/logoWhite.png')}
                style={{ height: '50px' }}
                alt='Customer | Gulf Winds'
                className='logo--image'
              />
            </NavLink>
          </div>
        )}
        rightChildren={[<div key='block' className='display--inline-block margin--off' />]}
      />
      <Body>{children}</Body>
      <Footer />
    </div>
    <div className='modals' id='modals' />
  </div>
);

export default LoggedOutLayout;
