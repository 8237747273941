import React from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';

const LoggedInTemplate = ({ children }) => {
  if (localStorage.getItem('gwi.accessToken')) {
    return <>{children}</>;
  }
  return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>;
};

export default LoggedInTemplate;
