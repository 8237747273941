import React from 'react';

export const OrderNumbers = ({ data }) => {
  return (
    <div className='detail-section order-numbers'>
      <div className='title-container'>
        <div className='title'>Order Numbers</div>
        <div className='title-bar' />
      </div>
      <div className='data-container'>
        <div className='info-row'>
          <div className='info-label'>
            <label className='label'>Container</label>
          </div>
          <div className='info-data'>{`${data.containerNumber ? data.containerNumber : 'TBD'} - ${data.equipmentSizeAndType}`}</div>
        </div>
        <div className='info-row'>
          <div className='info-label'>
            <label className='label'>PRO#</label>
          </div>
          <div className='info-data'>{data.proNumber}</div>
        </div>
        <div className='info-row'>
          <div className='info-label'>
            <label className='label'>{data.primaryReferenceNumber ? `${data.primaryReferenceNumberType}#` : ''}</label>
          </div>
          <div className='info-data'>{data.isBookingHold ? 'Booking Hold' : data.primaryReferenceNumber}</div>
        </div>
        {data.poNumber && (
          <div className='info-row'>
            <div className='info-label'>
              <label className='label'>PO#</label>
            </div>
            <div className='info-data'>{data.poNumber ? data.poNumber : 'N/A'}</div>
          </div>
        )}
        <div className='info-row'>
          <div className='info-label'>
            <label className='label'>BILL TO REF#</label>
          </div>
          <div className='info-data'>{data.billToReferenceNumber ? data.billToReferenceNumber : '-'}</div>
        </div>
      </div>
    </div>
  );
};
