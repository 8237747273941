import React from 'react';
import './style.scss';

const GridErrorMessage = ({ name, error }) => {
  const title = `Problem loading${name ? ` ${name}` : ''} grid. Please try again`;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1em' }}>
      <div className='error-message'>
        <span className="k-icon k-i-error error-message-icon" />
        <div className="error-message-title">{title}</div>
        <div className="error-message-details">{error.message}</div>
      </div>
    </div>
  );
};

export default GridErrorMessage;
