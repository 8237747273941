import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import errorToastr from 'shared/toastr/errorToastr';
import Select from 'shared/inputs/Select';
import DatePicker from 'shared/dateinputs/DatePicker';
// Fetch
import {
  getInventoryActivityReport,
  getOnhandReport,
  getProductStatusReport,
  getAvgInventoryReport
} from 'src/fetch/reports';
// Fetch
import { getCustomerAccounts } from 'src/fetch';

const WarehouseOptions = [
  {
    value: 'All',
    label: 'All',
  },
  {
    value: 'GW105',
    label: 'GW105 - BARBOURS CUT',
  },
  {
    value: 'GW106',
    label: 'GW106 - BARBOURS CUT',
  },
  {
    value: 'GW108A',
    label: 'GW108A - BAYPORT',
  },
  {
    value: 'GW108B',
    label: 'GW108B - BAYPORT',
  },
  {
    value: 'GW10B',
    label: 'GW10B - BARBOURS CUT / RAILSIDING',
  },
  {
    value: 'GW116',
    label: 'GW116 - BAYPORT DROP YARD',
  },
  {
    value: 'GW119',
    label: 'GW119 - THIRD PARTY WAREHOUSE',
  },
  {
    value: 'GW120',
    label: 'GW120 - DALLAS',
  },
  {
    value: 'GW126',
    label: 'GW126 - BARBOURS CUT CY',
  },
];
const ActivityTypeOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Inbound',
    value: 'inbound',
  },
  {
    label: 'Outbound',
    value: 'outbound',
  },
  {
    label: 'Adjustment',
    value: 'adjustment',
  },
];
const reportTypeOptions = [
  {
    label: 'Detail',
    value: 'detail',
  },
  {
    label: 'Summary',
    value: 'summary',
  },
];

const Report = ({ reportType, setReportTypeOptions, ReportTypeOptions }) => {
  const [activityType, setActivityType] = useState('all');
  const [errors, setErrors] = useState({});

  const [showUnarrived, setShowUnarrived] = useState(false);
  const [showUnshipped, setShowUnshipped] = useState(false);
  const [filteredLegacyAccounts, setfilteredLegacyAccounts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const validate = () => {
    const errors = {};
    if (reportType === 0) {
      if (!ReportTypeOptions[reportType].FromDate) {
        errors.FromDate = 'FromDate is required';
      }
      if (!ReportTypeOptions[reportType].ToDate) {
        errors.ToDate = 'ToDate is required';
      }
      if (!ReportTypeOptions[reportType].Customer) {
        errors.Customer = 'Please select Customer';
      }
      if (!ReportTypeOptions[reportType].Warehouse) {
        errors.Warehouse = 'Please select Warehouse';
      }
      if (!ReportTypeOptions[reportType].ActivityType) {
        errors.ActivityType = 'Please select ActivityType';
      }
    }
    if (reportType === 1) {
      if (!ReportTypeOptions[reportType].StartDate) {
        errors.StartDate = 'StartDate is required';
      }
      if (!ReportTypeOptions[reportType].Customer) {
        errors.Customer = 'Please select Customer';
      }
      if (!ReportTypeOptions[reportType].Warehouse) {
        errors.Warehouse = 'Please select Warehouse';
      }

      if (!ReportTypeOptions[reportType].ReportTypes) {
        errors.ReportTypes = 'Please select ReportTypes';
      }
    }
    if (reportType === 2) {
      if (!ReportTypeOptions[reportType].Customer) {
        errors.Customer = 'Please select Customer';
      }
      if (!ReportTypeOptions[reportType].Warehouse) {
        errors.Warehouse = 'Please select Warehouse';
      }
    }
    if (reportType === 3) {
      if (!ReportTypeOptions[reportType].StartDate) {
        errors.StartDate = 'StartDate is required';
      }
      if (!ReportTypeOptions[reportType].EndDate) {
        errors.StartDate = 'EndDate is required';
      }
      if (!ReportTypeOptions[reportType].Customer) {
        errors.Customer = 'Please select Customer';
      }
      if (!ReportTypeOptions[reportType].Warehouse) {
        errors.Warehouse = 'Please select Warehouse';
      }
    }

    setErrors(errors);
    return Object.keys(errors).length === 0
  };

  const handleChange = (event) => {
    console.log(event);
    let { name, value, checked } = event.target;
    setReportTypeOptions((prevData) => {
      const updatedOptions = [...prevData];
      if (name === 'ShowUnarrived') {
        updatedOptions[reportType][name] = checked;
        setShowUnarrived(checked);
      } else if (name === 'ShowUnshipped') {
        updatedOptions[reportType][name] = checked;
        setShowUnshipped(checked);
      } else {updatedOptions[reportType][name] = value;}
      return updatedOptions;
    });
    validate();
  };

  const handleSelectChange = (name, value) => {
    setReportTypeOptions((prevData) => {
      const updatedOptions = [...prevData];
      console.log(updatedOptions);
      updatedOptions[reportType][name] = value;
      return updatedOptions;
    });
  };

  useEffect(() => {
    // customers
    const fetchAccounts = async () => {
      try {
        const accountsResponse = await getCustomerAccounts();

        const organizations = accountsResponse.organizations || [];
        const legacyAccounts = accountsResponse.legacyAccounts || [];
        console.log('Orgs :', organizations);
        console.log('legacyAccounts :', legacyAccounts);
        const filteredLegacyAccounts = legacyAccounts.filter((legacy) => {
          return legacy.name.trim() === legacy.name.toUpperCase();
        });

        setfilteredLegacyAccounts(filteredLegacyAccounts);
      } catch (error) {
        console.error('Error fetching customer accounts:', error);
      }
    };
    fetchAccounts();
  }, []);

  const handleContinueClick = () => {
    if (!validate()) { return; }

    // Inventory activity report
    if (reportType === 0) {
      getInventoryActivityReport(
        ReportTypeOptions[reportType].FromDate,
        ReportTypeOptions[reportType].ToDate,
        ReportTypeOptions[reportType].Customer,
        ReportTypeOptions[reportType].Warehouse,
        activityType
      )
        .then((data) => {
          // Export and download the file
          handleDownload(data.document);
        })
        .catch((error) => errorToastr({ error, messagePrefix: 'Failed to load api data.' }));
    } else if (reportType === 1) {
      // OnHand report
      getOnhandReport(
        ReportTypeOptions[reportType].StartDate,
        reportType,
        ReportTypeOptions[reportType].Customer,
        selectedCustomer.type == 1 ? 'Customer' : 'BillTo',
        ReportTypeOptions[reportType].Warehouse,
        showUnarrived,
        showUnshipped
      )
        .then((data) => {
          // Export and download the file
          handleDownload(data.document);
        })
        .catch((error) => errorToastr({ error, messagePrefix: 'Failed to load api data.' }));
    } else if (reportType === 2) {
      // Product Status
      getProductStatusReport(
        ReportTypeOptions[reportType].Customer,
        selectedCustomer.type == 1 ? 'Customer' : 'BillTo',
        ReportTypeOptions[reportType].Warehouse
      )
        .then((data) => {
          // Export and download the file
          handleDownload(data.document);
        })
        .catch((error) => errorToastr({ error, messagePrefix: 'Failed to load api data.' }));
    } else if (reportType === 3) {
      // Avg Inventory
      getAvgInventoryReport(
        ReportTypeOptions[reportType].StartDate,
        ReportTypeOptions[reportType].EndDate,
        ReportTypeOptions[reportType].Customer,
        selectedCustomer.type == 1 ? 'Customer' : 'BillTo',
        ReportTypeOptions[reportType].Warehouse,
      )
        .then((data) => {
          // Export and download the file
          handleDownload(data.document);
        })
        .catch((error) => errorToastr({ error, messagePrefix: 'Failed to load api data.' }));
    }
  };

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
  function handleDownload(fileData) {
    // Create a workbook and worksheet
    const timestamp = Date.now(); // Get a unique timestamp
    const filename = `report_${reportType}_${timestamp}.xlsx`; // Generate the filename

    console.log(base64ToArrayBuffer(fileData));
    const blob = new Blob([base64ToArrayBuffer(fileData)], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Create a download link and simulate a click to trigger the download
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = filename;
    downloadLink.click();
  }

  return (
    <div className="search-data">
      {reportType === 0 && (
        <>
                  <div className="form-group">
            <label className="label">From date:</label>
                <div className="form-control">
                <DatePicker
                    name='FromDate'
                    value={ReportTypeOptions[reportType].FromDate}
                    onChange={handleChange}
                    required
                />
                {errors.FromDate && <span className="error">{errors.FromDate}</span>}
              </div>
          </div>
          <div className="form-group">
            <label className="label">To date:</label>
                <div className="form-control">
                    <DatePicker
                        name='ToDate'
                        value={ReportTypeOptions[reportType].ToDate}
                        onChange={handleChange}
                        required
                    />
              {errors.ToDate && <span className="error">{errors.ToDate}</span>}
            </div>
          </div>
          <div className="form-group">
            <label className="label">Customer:</label>
            <div className="form-control">
              <Select
                className="select"
                name="Customer"
                options={filteredLegacyAccounts
                    .filter((legacy) => legacy.type === 1) // Filter customers with type 1
                    .map((legacy) => ({
                      label: legacy.name,
                      value: legacy.accountId,
                      type: legacy.type,
                    }))}
                value={ReportTypeOptions[reportType].Customer}
                onChange={(selected) => {
                  setSelectedCustomer(selected);
                  handleSelectChange('Customer', selected.value);
                }}
                required
              />
              {errors.Customer && <span className="error">{errors.Customer}</span>}
            </div>
          </div>
          <div className="form-group">
            <label className="label">Warehouse:</label>
            <div className="form-control">
              <Select
                className="select"
                name="Warehouse"
                options={WarehouseOptions.map((t) => ({
                  label: t.label,
                  value: t.value,
                }))}
                value={ReportTypeOptions[reportType].Warehouse}
                onChange={(selected) => {
                  handleSelectChange('Warehouse', selected.value);
                }}
              />
              {errors.Warehouse && <span className="error">{errors.Warehouse}</span>}
            </div>
          </div>
          <div className="form-group">
            <label className="label">Activity:</label>
            <div className="form-control">
              <Select
                className="select"
                name="ActivityType"
                options={ActivityTypeOptions.map((t) => ({
                  label: t.label,
                  value: t.value,
                }))}
                value={ReportTypeOptions[reportType].ActivityType}
                onChange={(selected) => {
                  handleSelectChange('ActivityType', selected.value);
                }}
              />
              {errors.ActivityType && <span className="error">{errors.ActivityType}</span>}
            </div>
          </div>
          <div className="form-group">
            <label className="label"></label>
            <button className="form-control btn" onClick={handleContinueClick}>
                            Continue
            </button>
          </div>
        </>
      )}
      {reportType === 1 && (
        <>
          <div className="form-group">
            <label className="label">Start Date:</label>
                <div className="form-control">
                    <DatePicker
                        name='StartDate'
                        value={ReportTypeOptions[reportType].StartDate}
                        onChange={handleChange}
                        required
                    />
              {errors.StartDate && <span className="error">{errors.StartDate}</span>}
            </div>
          </div>
          <div className="form-group">
            <label className="label">Report Type:</label>
            <div className="form-control">
              <Select
                className="select"
                name="ReportTypes"
                options={reportTypeOptions.map((t) => ({
                  label: t.label,
                  value: t.value,
                }))}
                value={reportTypeOptions[reportType].ReportTypes}
                onChange={(selected) => {
                  handleSelectChange('ReportTypes', selected.value);
                }}
              />
              {errors.ReportTypes && <span className="error">{errors.ReportTypes}</span>}
            </div>
          </div>
          <div className="form-group">
            <label className="label">Customer:</label>
            <div className="form-control">
              <Select
                className="select"
                name="Customer"
                options={filteredLegacyAccounts.map((legacy) => ({
                  label: legacy.name,
                  value: legacy.accountId,
                  type: legacy.type,
                }))}
                value={ReportTypeOptions[reportType].Customer}
                onChange={(selected) => {
                  setSelectedCustomer(selected);
                  handleSelectChange('Customer', selected.value);
                }}
              />
              {errors.Customer && <span className="error">{errors.Customer}</span>}
            </div>
          </div>
          <div className="form-group">
            <label className="label">Warehouse:</label>
            <div className="form-control">
              <Select
                className="select"
                name="Warehouse"
                options={WarehouseOptions.map((t) => ({
                  label: t.label,
                  value: t.value,
                }))}
                value={ReportTypeOptions[reportType].Warehouse}
                onChange={(selected) => {
                  handleSelectChange('Warehouse', selected.value);
                }}
              />
              {errors.Warehouse && <span className="error">{errors.Warehouse}</span>}
            </div>
          </div>
          <div className="form-group">
            <label className="label" htmlFor="unarrived">Arrived:</label>
            <div className="">
              <input
                id="unarrived"
                type="checkbox"
                name="ShowUnarrived"
                className="form-check-input"
                checked={ReportTypeOptions[reportType].ShowUnarrived}
                onChange={handleChange}
              />     
            </div> 
          </div>
          <div className="form-group">
            <label className="label" htmlFor="ShowUnshipped">Shipped:</label>
            <div className="">
              <input
                id="ShowUnshipped"
                type="checkbox"
                name="ShowUnshipped"
                className="form-check-input"
                checked={ReportTypeOptions[reportType].ShowUnshipped}
                onChange={handleChange}
              />            
            </div>
          </div>
          <div className="form-group">
            <label className="label"></label>
            <button className="form-control btn" onClick={handleContinueClick}>
                            Continue
            </button>
          </div>
        </>
      )}
      {reportType === 2 && (
        <>
          <div className="form-group">
            <label className="label">Customer:</label>
            <div className="form-control">
              <Select
                className="select"
                name="Customer"
                options={filteredLegacyAccounts.map((legacy) => ({
                  label: legacy.name,
                  value: legacy.accountId,
                  type: legacy.type,
                }))}
                value={ReportTypeOptions[reportType].Customer}
                onChange={(selected) => {
                  setSelectedCustomer(selected);
                  handleSelectChange('Customer', selected.value);
                }}
                required
              />
              {errors.Customer && <span className="error">{errors.Customer}</span>}
            </div>
          </div>
          <div className="form-group">
            <label className="label">Warehouse:</label>
            <div className="form-control">
              <Select
                className="select"
                name="Warehouse"
                options={WarehouseOptions.map((t) => ({
                  label: t.label,
                  value: t.value,
                }))}
                value={ReportTypeOptions[reportType].Warehouse}
                onChange={(selected) => {
                  handleSelectChange('Warehouse', selected.value);
                }}
              />
              {errors.Warehouse && <span className="error">{errors.Warehouse}</span>}
            </div>
          </div>
          <div className="form-group">
            <label className="label"></label>
            <button className="form-control btn" onClick={handleContinueClick}>
                            Continue
            </button>
          </div>
        </>
      )}
      {reportType === 3 && (
        <>
          <div className="form-group">
            <label className="label">Start Date:</label>
                <div className="form-control">
                    <DatePicker
                        name='StartDate'
                        value={ReportTypeOptions[reportType].StartDate}
                        onChange={handleChange}
                        required
                    />
              
              {errors.StartDate && <span className="error">{errors.StartDate}</span>}
            </div>
          </div>
          <div className="form-group">
            <label className="label">End Date:</label>
                <div className="form-control">
                    <DatePicker
                        name='EndDate'
                        value={ReportTypeOptions[reportType].EndDate}
                        onChange={handleChange}
                        required
                    />
             
              {errors.EndDate && <span className="error">{errors.EndDate}</span>}
            </div>
          </div>
          <div className="form-group">
            <label className="label">Customer:</label>
            <div className="form-control">
              <Select
                className="select"
                name="Customer"
                options={filteredLegacyAccounts.map((legacy) => ({
                  label: legacy.name,
                  value: legacy.accountId,
                  type: legacy.type,
                }))}
                value={ReportTypeOptions[reportType].Customer}
                onChange={(selected) => {
                  setSelectedCustomer(selected);
                  handleSelectChange('Customer', selected.value);
                }}
                required
              />
              {errors.Customer && <span className="error">{errors.Customer}</span>}
            </div>
          </div>
          <div className="form-group">
            <label className="label">Warehouse:</label>
            <div className="form-control">
              <Select
                className="select"
                name="Warehouse"
                options={WarehouseOptions.map((t) => ({
                  label: t.label,
                  value: t.value,
                }))}
                value={ReportTypeOptions[reportType].Warehouse}
                onChange={(selected) => {
                  handleSelectChange('Warehouse', selected.value);
                }}
              />
              {errors.Warehouse && <span className="error">{errors.Warehouse}</span>}
            </div>
          </div>
          <div className="form-group">
            <label className="label"></label>
            <button className="form-control btn" onClick={handleContinueClick}>
                            Continue
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(Report);
