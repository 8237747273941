const requestStatuses = {
  INIT: 'init',
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed',
  DISCONNECTED: 'disconnected',
  DISABLED: 'disabled',
};

export default requestStatuses;
