import React from 'react';
import HoverIntent from 'react-hoverintent';

class Dropdown extends React.Component {
  constructor() {
    super();
    this.state = { mouseover: false };
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
  }
  onMouseOver() {
    this.setState({ mouseover: true });
    let dropdownButtonId = this.props.buttonId;
    let dropdownButton = document.getElementById(dropdownButtonId);
    dropdownButton.setAttribute('aria-expanded', 'true');
  }
  onMouseOut() {
    this.setState({ mouseover: false });
    let dropdownButtonId = this.props.buttonId;
    let dropdownButton = document.getElementById(dropdownButtonId);
    dropdownButton.setAttribute('aria-expanded', 'false');
  }
  render() {
    const element = document.querySelector('.no-touchevents');
    let { mouseover } = this.state;
    var className = 'dropdown margin--off';
    if (this.props.className !== undefined && this.props.className !== '') {
      className += ' ' + this.props.className;
    }
    var id = '';
    if (this.props.id !== undefined && this.props.id !== '') {
      id = this.props.id;
    }

    if (this.props.hoverIntent !== undefined && this.props.hoverIntent !== '' && element) {
      return (
        <HoverIntent onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} timeout={200}>
          <div className={className + ' has-hover-intent' + (mouseover ? ' dropdown-is-open' : '')} id={id}>
            {this.props.children}
          </div>
        </HoverIntent>
      );
    } else {
      return (
        <div className={className} id={id}>
          {this.props.children}
        </div>
      );
    }
  }
}

class DropdownButton extends React.Component {
  constructor(props) {
    super(props);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  toggleDropdown(e) {
    const element = document.querySelector('.touchevents');
    var dropdownButton = e.currentTarget;
    var dropdownId = dropdownButton.attributes.getNamedItem('aria-controls').value;
    var dropdown = document.getElementById(dropdownId);
    if (element || !dropdown.parentNode.classList.contains('has-hover-intent')) {
      if (dropdown.parentNode.classList.contains('dropdown-is-open')) {
        dropdownButton.setAttribute('aria-expanded', 'false');
        dropdown.setAttribute('aria-expanded', 'false');
        dropdown.setAttribute('aria-hidden', 'true');
        dropdown.parentNode.classList.remove('dropdown-is-open');
        document.removeEventListener('click', this.handleClickOutside, true);
      } else {
        dropdownButton.setAttribute('aria-expanded', 'true');
        dropdown.setAttribute('aria-expanded', 'true');
        dropdown.setAttribute('aria-hidden', 'false');
        dropdown.parentNode.classList.add('dropdown-is-open');
        document.addEventListener('click', this.handleClickOutside, true);
      }
      if (this.props.onClick !== undefined && this.props.onClick !== null) {
        this.props.onClick(e);
      }
    }
  }
  handleClickOutside(e) {
    const target = e.target;
    const id = e.target.id;
    const buttonId = this.props.buttonId;
    const listId = this.props.listId;
    var i = 0;
    var parent = target;
    var parentId = id;
    if (target.tagName.toLowerCase() === 'a') {
      this.closeAllDropdowns();
    } else if (id !== buttonId) {
      while (parentId !== buttonId) {
        parent = parent.parentNode;
        parentId = parent.id;
        if (parentId === listId) {
          break;
        }
        if (parent.tagName === undefined || i >= 100) {
          this.closeAllDropdowns();
          break;
        }
        i += 1;
      }
    }
    document.removeEventListener('click', this.handleClickOutside, true);
  }
  closeAllDropdowns() {
    var dropdowns = document.getElementsByClassName('dropdown');
    for (var i = 0; i < dropdowns.length; i++) {
      dropdowns[i].classList.remove('dropdown-is-open');
    }
  }
  handleKeyDown(e) {
    if (e.keyCode === 27) {
      this.closeAllDropdowns();
    }
    if (this.props.onKeyDown !== undefined && this.props.onKeyDown !== null) {
      this.props.onKeyDown(e);
    }
  }
  render() {
    var className = 'dropdown--button';
    if (this.props.className !== undefined && this.props.className !== '') {
      className += ' ' + this.props.className;
    }
    var buttonId = '';
    if (this.props.buttonId !== undefined && this.props.buttonId !== '') {
      buttonId = this.props.buttonId;
    }
    var listId = '';
    if (this.props.listId !== undefined && this.props.listId !== '') {
      listId = this.props.listId;
    }
    return (
      <button
        type='button'
        className={className}
        id={buttonId}
        aria-controls={listId}
        aria-haspopup='true'
        aria-expanded='false'
        onClick={this.toggleDropdown}
        onKeyDown={this.handleKeyDown}
      >
        {this.props.children}
      </button>
    );
  }
}

class DropdownList extends React.Component {
  render() {
    var className = 'dropdown--list';
    if (this.props.className !== undefined && this.props.className !== '') {
      className += ' ' + this.props.className;
    }
    var buttonId = '';
    if (this.props.buttonId !== undefined && this.props.buttonId !== '') {
      buttonId = this.props.buttonId;
    }
    var listId = '';
    if (this.props.listId !== undefined && this.props.listId !== '') {
      listId = this.props.listId;
    }
    return (
      <div className={className} id={listId} aria-labelledby={buttonId} aria-expanded='false' aria-hidden='true'>
        {this.props.children}
      </div>
    );
  }
}

export { Dropdown, DropdownButton, DropdownList };
