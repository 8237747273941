// Translated from https://medium.com/sopra-steria-norge/msal-react-automatically-sign-in-users-and-get-app-roles-30893449ed87
import React from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import axios from 'axios';
import { loginRequest } from './msalConfig';
import { refreshUserToken } from 'src/fetch';
import { logoutUserAndReloadPage } from 'shared/../helpers/auth';
import toastr from 'shared/helpers/toastr';

let lastInterceptor;

const renewToken = async () => {
  try {
    // Try to renew
    const currentRefreshToken = localStorage.getItem('gwi.refreshToken');
    const newTokens = await refreshUserToken(currentRefreshToken);
    const { accessToken: newAccessToken, refreshToken: newRefreshToken } = newTokens;

    localStorage.setItem('gwi.accessToken', newAccessToken);
    localStorage.setItem('gwi.refreshToken', newRefreshToken);

    return newAccessToken;
  } catch (error) {
    // If it fails with 401, log the user out
    if (error?.response?.status === 401) {
      logoutUserAndReloadPage();
    }
  }
};

const getGwiAccessToken = async () => {
  let accessToken = localStorage.getItem('gwi.accessToken');
  const tokenDataEncoded = accessToken.split('.')[1];
  const tokenDataDecoded = Buffer.from(tokenDataEncoded, 'base64');
  const tokenData = JSON.parse(tokenDataDecoded);

  // If token is expired
  if (tokenData.exp < new Date() / 1000) {
    // Renew token
    accessToken = await renewToken();
  }

  return accessToken;
};

const RequestInterceptor = ({ children }) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  const getBearerToken = async () => {
    if (localStorage.getItem('gwi.accessToken')) {
      const tk = await getGwiAccessToken();
      return `Bearer ${tk}`;
    }

    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      account,
    });

    return `Bearer ${response.accessToken}`;
  };

  /* eslint-disable no-param-reassign */
  const addHeaders = async (config) => {
    if (!account && !localStorage.getItem('gwi.accessToken')) {
      throw Error('No active account! Verify a user has been signed in.');
    }

    const bearer = await getBearerToken();
    config.headers.Authorization = bearer;

    if (localStorage.getItem('impersonate') && localStorage.getItem('impersonate') !== 'null') {
      config.headers.impersonate = localStorage.getItem('impersonate');
    }

    return config;
  };

  if (!lastInterceptor) {
    lastInterceptor = axios.interceptors.request.use(addHeaders);
  }

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 403
          && localStorage.getItem('impersonate')
          && localStorage.getItem('impersonate') !== 'null') {
        // eslint-disable-next-line quotes
        toastr('error', "You can't use the impersonate function");
      } else {
        return Promise.reject(error);
      }
    },
  );

  /* eslint-enable no-param-reassign */

  return <>{children}</>;
};

export default RequestInterceptor;
