import React, { useState, useEffect, useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { withRouter } from 'react-router-dom';
// Components
import Select from 'shared/inputs/Select';
import toastr from 'shared/helpers/toastr';
import Icon from 'shared/icons/IconComponent';
// Fetch
import { getCustomers, getCustomerAccounts } from 'src/fetch';
// Customer Access
import { SELECT_USER } from 'shared/../constants/CustomerAccess';

const defaultOption = { label: 'All Accounts', value: null, type: null };
const defaultCustomerOption = { label: 'Select Customer Account', value: null };
const DashboardHeader = ({
  showSwitcher = false,
  onSearch,
  value,
  customerAccess,
  setCurrentCustomer,
  setLegacyAccount,
  setOrganization,
  history,
  setHasLoadedAccounts,
  setEmptyAccounts,
  loadChartData
}) => {
  // state
  const [selected, setSelected] = useState({ ...defaultOption });
  const [legacyAccounts, setLegacyAccounts] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [customerAccounts, setCustomerAccounts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({ ...defaultCustomerOption });
  const [isLoading, setIsLoading] = useState(false);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [search, setSearch] = useState(value);
  const [customerAndAccountStateUpdated, setCustomerAndAccountStateUpdated] = useState(false);

  const load = async () => {
    setIsLoading(true);
    setHasLoadedAccounts(false);
    try {
      const accountsResponse = await getCustomerAccounts();

      if (!accountsResponse.legacyAccounts.length && !accountsResponse.organizations.length) {
        setEmptyAccounts(true);
      }

      if (customerAccess && customerAccess[SELECT_USER]) {
        setLoadingCustomers(true);
        const customersResponse = await getCustomers();
        setCustomerAccounts([
          defaultCustomerOption,
          ...customersResponse.filter(u => !u.deleted).map(c => ({ label: `${c.firstName} ${c.lastName} (${c.email})`, value: c.email }))
        ]);
        setLoadingCustomers(false);
      }

      setLegacyAccounts(accountsResponse.legacyAccounts);
      setOrganizations(accountsResponse.organizations);

      if (setHasLoadedAccounts) {
        setHasLoadedAccounts(true);
      }

      setIsLoading(false);
    } catch (error) {
      toastr('error', 'Could not get accounts. Refresh to try again.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (showSwitcher) {
      load();
    }
  }, []);

  // create the options when we have both legacyAccounts
  const options = useMemo(() => {
    if (legacyAccounts || organizations) {
      if (legacyAccounts.length && organizations.length) {
        const combined = [
          ...legacyAccounts.map(x => ({ label: x.name, value: [x], type: 'legacyAccount' })),
          ...organizations.map(x => ({ label: x.organizationName, value: [x], type: 'organization' }))
        ];
        setSelected({ ...defaultOption });
        return [defaultOption, ...sortBy(combined, ['label'])];
      } else if (legacyAccounts.length > 1 && !organizations.length) {
        setSelected({ ...defaultOption });
        return [
          defaultOption,
          ...sortBy(
            legacyAccounts.map(x => ({ label: x.name, value: [x], type: 'legacyAccount' })),
            ['label']
          )
        ];
      } else if (!legacyAccounts.length && organizations.length > 1) {
        setSelected({ ...defaultOption });
        return [
          defaultOption,
          ...sortBy(
            organizations.map(x => ({ label: x.organizationName, value: [x], type: 'organization' })),
            ['label']
          )
        ];
      } else if (legacyAccounts.length === 1 && !organizations.length) {
        setSelected({ label: legacyAccounts[0].name, value: [legacyAccounts[0]], type: 'legacyAccount' });
      } else if (organizations.length === 1 && !legacyAccounts.length) {
        setSelected({ label: organizations[0].organizationName, value: [organizations[0]], type: 'organization' });
      } else {
        setSelected({ ...defaultOption });
      }
    }
    return [];
  }, [legacyAccounts, organizations]);

  const handleSubmit = async () => {
    if (onSearch) {
      onSearch(search);
    } else {
      try {
        if (Number.isInteger(parseInt(search))) {
          history.push(`/search?pro=${search}`);
        } else {
          history.push(`/search?container=${search}`);
        }
      } catch {
        history.push(`/search?pro=${search}`);
      }
    }
  };

  //this handles the accounts dropdown on customer change
  const handleChangeCustomer = async selectedCustomer => {
    setIsLoading(true);
    if (showSwitcher) {
      setHasLoadedAccounts(false);
    }
    try {
      const response = await getCustomerAccounts(selectedCustomer.value);
      setLegacyAccounts(response.legacyAccounts);
      setOrganizations(response.organizations);
      if (response.legacyAccounts.length || response.organizations.length) {
        setEmptyAccounts(false);
      }
      setIsLoading(false);

      if (showSwitcher) {
        setHasLoadedAccounts(true);
      }
    } catch (accountsError) {
      console.log('accountsError', accountsError);
      setIsLoading(false);
      toastr('error', 'Could not assume customer account. Please refresh to try again.');
    }
  };

  // this looks at the current account value and sets legacy accounts
  // or organizations based on the current account value.
  const handleSetCustomerAccounts = currentAccount => {
    if (currentAccount.value) {
      if (currentAccount.type === 'legacyAccount') {
        setLegacyAccount(currentAccount.value);
        setOrganization(null);
      } else if (currentAccount.type === 'organization') {
        setOrganization(currentAccount.value);
        setLegacyAccount(null);
      }
    } else {
      setLegacyAccount(null);
      setOrganization(null);
    }
  };

  // This loads the data for the charts.
  // customer and account state must be explictly set as updated
  // so that the right values are in place.
  useEffect(() => {
    if (customerAndAccountStateUpdated === true) {
      loadChartData();
      setCustomerAndAccountStateUpdated(false);
    }
  }, [customerAndAccountStateUpdated]);

  return (
    <div className='old-dashboard-header'>
      <div className='filters-container'>
        <div className='switcher-wrap'>
          {showSwitcher && customerAccess[SELECT_USER] && (
            <div className='customer-filter-container'>
              <Select
                className='customer-select'
                options={customerAccounts}
                value={selectedCustomer}
                onChange={selected => {
                  setSelected({ ...defaultOption });
                  setOrganization(null);
                  setLegacyAccount(null);
                  setSelectedCustomer(selected);
                  setCurrentCustomer(selected);
                  handleChangeCustomer(selected);
                  setCustomerAndAccountStateUpdated(true);
                }}
                isLoading={isLoading}
                isDisabled={isLoading || loadingCustomers}
              />
            </div>
          )}
          {showSwitcher && (
            <div className='filter-container'>
              <Select
                className='customer-account-select'
                options={options}
                value={selected}
                onChange={selected => {
                  setSelected(selected);
                  handleSetCustomerAccounts(selected);
                  setCustomerAndAccountStateUpdated(true);
                }}
                isLoading={isLoading}
                isDisabled={isLoading}
              />
            </div>
          )}
        </div>
      </div>
      <div className='old-search-container'>
        <input
          className='search-input'
          placeholder='Search for Container, PRO, BKG, BOL or PO'
          id='search'
          value={search}
          onChange={e => setSearch(e.target.value)}
          onKeyUp={e => {
            if ((e.which === 13 || e.keyCode === 13) && search.length) {
              handleSubmit();
            }
          }}
        />
        <Icon
          onClick={() => {
            if (search.length) {
              handleSubmit();
            }
          }}
          icon='bars'
          className='bars-icon'
        />
      </div>
    </div>
  );
};

export default withRouter(DashboardHeader);
