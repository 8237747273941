import React, { useState } from 'react';
import LoadingSpinner from 'shared/loading/LoadingSpinner';
import OrderSummaryModal from '../modals/OrdersModal';
import ProgressModal from '../modals/ProgressModal';

const MoveTypeChart = ({
  loading,
  moveType,
  data,
  legacyAccount = {},
  organization = {},
  error,
  importCategoryTypes = [],
  domesticCategoryTypes = [],
  todaysDeliveriesTypes = [],
  currentCustomer
}) => {
  // Order Progress
  const [progressModalOpen, setProgressModalOpen] = useState(false);
  const [progressCategory, setProgressCategory] = useState(null);
  const [progressName, setProgressName] = useState(null);

  // Order Summary
  const [summaryModalOpen, setSummaryModalOpen] = useState(false);
  const [summaryCategory, setSummaryCategory] = useState(null);
  const [summaryName, setSummaryName] = useState(null);

  const handleOpenProgressModal = (category, name) => {
    setProgressCategory(category);
    setProgressName(name);
    setProgressModalOpen(true);
  };

  const handleCloseProgressModal = () => {
    setProgressCategory(null);
    setProgressName(null);
    setProgressModalOpen(false);
  };

  const handleOpenSummaryModal = (category, name) => {
    setSummaryCategory(category);
    setSummaryName(name);
    setSummaryModalOpen(true);
  };

  const handleCloseSummaryModal = () => {
    setSummaryCategory(null);
    setSummaryName(null);
    setSummaryModalOpen(false);
  };

  return loading ? (
    <LoadingSpinner />
  ) : (
    error ? (
      <div>There was an error loading data from the api.</div>
    ) : (
      <React.Fragment>
        {moveType === 0 && (
          <React.Fragment>
            <div className='progress-chart'>
              <div className='progress-summary'>{`Today's Deliveries: ${data.deliveries.awaitingDispatch + data.deliveries.awaitingPickup + data.deliveries.inProgress + data.deliveries.complete}`}</div>
              <div className='status-container'>
                <div
                  className={`progress-status ${data.deliveries.awaitingDispatch > 0 && 'active'}`}
                  onClick={() => {
                    if (data.deliveries.awaitingDispatch > 0) {
                      handleOpenProgressModal(todaysDeliveriesTypes.length && todaysDeliveriesTypes.find(x => x.text === 'AwaitingDispatch').value, 'Awaiting Dispatch');
                    }
                  }}
                >
                  <div className='count'>{data.deliveries.awaitingDispatch}</div>
                  <div className='circle dispatch' />
                  <div className='progress-legend'>Awaiting Dispatch</div>
                </div>

                <div className='progress-divider' />
                <div
                  className={`progress-status ${data.deliveries.awaitingPickup > 0 && 'active'}`}
                  onClick={() => {
                    if (data.deliveries.awaitingPickup > 0) {
                      handleOpenProgressModal(todaysDeliveriesTypes.length && todaysDeliveriesTypes.find(x => x.text === 'AwaitingPickup').value, 'Awaiting Pickup');
                    }
                  }}
                >
                  <div className='count'>{data.deliveries.awaitingPickup}</div>
                  <div className='circle outgate' />
                  <div className='progress-legend'>Awaiting Pickup</div>
                </div>

                <div className='progress-divider' />
                <div
                  className={`progress-status ${data.deliveries.inProgress > 0 && 'active'}`}
                  onClick={() => {
                    if (data.deliveries.inProgress > 0) {
                      handleOpenProgressModal(todaysDeliveriesTypes.length && todaysDeliveriesTypes.find(x => x.text === 'InProgress').value, 'In Transit');
                    }
                  }}
                >
                  <div className='count'>{data.deliveries.inProgress}</div>
                  <div className='circle inTransit' />
                  <div className='progress-legend'>In Transit</div>
                </div>

                <div className='progress-divider' />
                <div
                  className={`progress-status ${data.deliveries.complete > 0 && 'active'}`}
                  onClick={() => {
                    if (data.deliveries.complete > 0) {
                      handleOpenProgressModal(todaysDeliveriesTypes.length && todaysDeliveriesTypes.find(x => x.text === 'Complete').value, 'Delivered');
                    }
                  }}
                >
                  <div className='count'>{data.deliveries.complete}</div>
                  <div className='circle delivered' />
                  <div className='progress-legend'>Delivered</div>
                </div>
              </div>
            </div>
            <div className='summary-chart'>
              <div className='summary-summary'>{`Total Open Orders: ${data.orders.inTransit + data.orders.arrivedHold + data.orders.arrivedPendingScheduling + data.orders.scheduled}`}</div>
              <div className='status-container'>
                <div
                  className={`summary-status ${data.orders.inTransit > 0 && 'active'}`}
                  onClick={() => {
                    if (data.orders.inTransit > 0) {
                      handleOpenSummaryModal(importCategoryTypes.length && importCategoryTypes.find(x => x.text === 'InTransit').value, 'Imports - In Transit');
                    }
                  }}
                >
                  <div className='count'>{data.orders.inTransit}</div>
                  <div className='circle inTransit' />
                  <div className='summary-legend'>In Transit</div>
                </div>
                <div className='summary-divider imports' />
                <div
                  className={`summary-status ${data.orders.arrivedHold > 0 && 'active'}`}
                  onClick={() => {
                    if (data.orders.arrivedHold > 0) {
                      handleOpenSummaryModal(importCategoryTypes.length && importCategoryTypes.find(x => x.text === 'ArrivedHold').value, 'Imports - Arrived/Hold');
                    }
                  }}
                >
                  <div className='count'>{data.orders.arrivedHold}</div>
                  <div className='circle hold' />
                  <div className='summary-legend'>Arrived/Hold</div>
                </div>
                <div className='summary-divider imports' />
                <div
                  className={`summary-status ${data.orders.arrivedPendingScheduling > 0 && 'active'}`}
                  onClick={() => {
                    if (data.orders.arrivedPendingScheduling > 0) {
                      handleOpenSummaryModal(importCategoryTypes.length && importCategoryTypes.find(x => x.text === 'ArrivedPendingScheduling').value, 'Imports - Arrived/Pending Scheduling');
                    }
                  }}
                >
                  <div className='count'>{data.orders.arrivedPendingScheduling}</div>
                  <div className='circle pending' />
                  <div className='summary-legend'>Arrived/Pending Scheduling</div>
                </div>
                <div className='summary-divider imports' />
                <div
                  className={`summary-status ${data.orders.scheduled > 0 && 'active'}`}
                  onClick={() => {
                    if (data.orders.scheduled > 0) {
                      handleOpenSummaryModal(importCategoryTypes.length && importCategoryTypes.find(x => x.text === 'Scheduled').value, 'Imports - Scheduled');
                    }
                  }}
                >
                  <div className='count'>{data.orders.scheduled}</div>
                  <div className='circle scheduled' />
                  <div className='summary-legend'>Scheduled</div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {moveType === 2 && (
          <React.Fragment>
            <div className='progress-chart'>
              <div className='progress-summary'>{`Today's Deliveries: ${data.deliveries.awaitingDispatch + data.deliveries.awaitingPickup + data.deliveries.inProgress + data.deliveries.complete}`}</div>
              <div className='status-container'>
                <div
                  className={`progress-status ${data.deliveries.awaitingDispatch > 0 && 'active'}`}
                  onClick={() => {
                    if (data.deliveries.awaitingDispatch > 0) {
                      handleOpenProgressModal(todaysDeliveriesTypes.length && todaysDeliveriesTypes.find(x => x.text === 'AwaitingDispatch').value, 'Awaiting Dispatch');
                    }
                  }}
                >
                  <div className='count'>{data.deliveries.awaitingDispatch}</div>
                  <div className='circle dispatch' />
                  <div className='progress-legend'>Awaiting Dispatch</div>
                </div>

                <div className='progress-divider' />
                <div
                  className={`progress-status ${data.deliveries.awaitingPickup > 0 && 'active'}`}
                  onClick={() => {
                    if (data.deliveries.awaitingPickup > 0) {
                      handleOpenProgressModal(todaysDeliveriesTypes.length && todaysDeliveriesTypes.find(x => x.text === 'AwaitingPickup').value, 'Awaiting Pickup');
                    }
                  }}
                >
                  <div className='count'>{data.deliveries.awaitingPickup}</div>
                  <div className='circle outgate' />
                  <div className='progress-legend'>Awaiting Pickup</div>
                </div>

                <div className='progress-divider' />
                <div
                  className={`progress-status ${data.deliveries.inProgress > 0 && 'active'}`}
                  onClick={() => {
                    if (data.deliveries.inProgress > 0) {
                      handleOpenProgressModal(todaysDeliveriesTypes.length && todaysDeliveriesTypes.find(x => x.text === 'InTransit').value, 'In Transit');
                    }
                  }}
                >
                  <div className='count'>{data.deliveries.inProgress}</div>
                  <div className='circle inTransit' />
                  <div className='progress-legend'>In Transit</div>
                </div>

                <div className='progress-divider' />
                <div
                  className={`progress-status ${data.deliveries.complete > 0 && 'active'}`}
                  onClick={() => {
                    if (data.deliveries.complete > 0) {
                      handleOpenProgressModal(todaysDeliveriesTypes.length && todaysDeliveriesTypes.find(x => x.text === 'Complete').value, 'Delivered');
                    }
                  }}
                >
                  <div className='count'>{data.deliveries.complete}</div>
                  <div className='circle delivered' />
                  <div className='progress-legend'>Delivered</div>
                </div>
              </div>
            </div>
            <div className='summary-chart'>
              <div className='summary-summary'>{`Total Open Orders: ${data.orders.scheduled + data.orders.arrivedPendingScheduling + data.orders.newOrder}`}</div>
              <div className='status-container'>
                <div
                  className={`summary-status ${data.orders.newOrder > 0 && 'active'}`}
                  onClick={() => {
                    if (data.orders.newOrder > 0) {
                      handleOpenSummaryModal(domesticCategoryTypes.length && domesticCategoryTypes.find(x => x.text === 'NewOrder').value, 'Domestic - New Orders');
                    }
                  }}
                >
                  <div className='count'>{data.orders.newOrder}</div>
                  <div className='circle new' />
                  <div className='summary-legend'>New Orders</div>
                </div>
                <div className='summary-divider domestic' />
                <div
                  className={`summary-status ${data.orders.arrivedPendingScheduling > 0 && 'active'}`}
                  onClick={() => {
                    if (data.orders.arrivedPendingScheduling > 0) {
                      handleOpenSummaryModal(domesticCategoryTypes.length && domesticCategoryTypes.find(x => x.text === 'ArrivedPendingScheduling').value, 'Domestic - Arrived/Pending Scheduling');
                    }
                  }}
                >
                  <div className='count'>{data.orders.arrivedPendingScheduling}</div>
                  <div className='circle hold' />
                  <div className='summary-legend'>Arrived/Pending Scheduling</div>
                </div>
                <div className='summary-divider domestic' />
                <div
                  className={`summary-status ${data.orders.scheduled > 0 && 'active'}`}
                  onClick={() => {
                    if (data.orders.scheduled > 0) {
                      handleOpenSummaryModal(domesticCategoryTypes.length && domesticCategoryTypes.find(x => x.text === 'Scheduled').value, 'Domestic - Scheduled');
                    }
                  }}
                >
                  <div className='count'>{data.orders.scheduled}</div>
                  <div className='circle scheduled' />
                  <div className='summary-legend'>Scheduled</div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {progressModalOpen && (
          <ProgressModal
            isModalShowing={progressModalOpen}
            handleCloseModal={handleCloseProgressModal}
            moveCategory={moveType}
            progressType={progressCategory}
            progressName={progressName}
            legacyAccount={legacyAccount}
            organization={organization}
            currentCustomer={currentCustomer}
          />
        )}
        {summaryModalOpen && (
          <OrderSummaryModal
            isModalShowing={summaryModalOpen}
            handleCloseModal={handleCloseSummaryModal}
            type={moveType}
            category={summaryCategory}
            summaryName={summaryName}
            legacyAccount={legacyAccount}
            organization={organization}
            currentCustomer={currentCustomer}
          />
        )}
      </React.Fragment>
    )
  );
};

export default MoveTypeChart;
