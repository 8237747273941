import format from 'date-fns/format';

export const dateFormatter = val => {
  if (val === null || val === undefined) {
    return val;
  }

  // allow for shortcuts
  let today = new Date();
  let newVal = new Date();

  if (!isNaN(val)) {
    // convert dates like 0428 to 04/28/YYYY
    if (val.toString().length === 4) {
      const month = val.toString().slice(0, 2);
      const day = val.toString().slice(2, 4);
      val = `${month}/${day}/${today.getFullYear()}`;
    } else {
      val = newVal.setDate(today.getDate() + Number(val));
    }
  } else if (val.split('/').length === 2) {
    // apply a year since we can assume it wasn't provided
    today.getFullYear();
    val = new Date(val).setFullYear(today.getFullYear());
  }

  return format(new Date(val), 'MM/dd/yyyy');
};

export const timeFormatter = val => {
  if (val !== null && val !== undefined && val.toString().length === 4 && val.indexOf(':') === -1) {
    // allow for 0800 to be formatted as 08:00
    const hours = val.toString().slice(0, 2);
    const minutes = val.toString().slice(2, 4);
    return `${hours}:${minutes}`;
  } else if (val !== null && val !== undefined && val.toString() && val.toString().split(':').length === 2) {
    const split = val.toString().split(':');
    // allow for 8:2 to be formatted as 08:20
    // allow for 8:00 to be formatted as 08:00
    // allow for 08:2 to be formatted as 08:20
    return `${split[0].length === 1 ? '0' : ''}${split[0]}:${split[1]}${split[1].length === 1 ? '0' : ''}`;
  }

  return val;
};

export const shortDateFormatter = val => {
  return format(new Date(dateFormatter(val)), 'MM/dd');
};

export const mediumDateFormatter = val => {
  return format(new Date(dateFormatter(val)), 'M/d/yy');
};

export const shortDateTimeFormatter = val => {
  return format(new Date(`${dateFormatter(val)} ${new Date(val).getHours()}:${new Date(val).getMinutes()}`), 'MM/dd HH:mm');
};

export const longDateTimeFormatter = val => {
  return format(new Date(`${dateFormatter(val)} ${new Date(val).getHours()}:${new Date(val).getMinutes()}`), 'MM/dd/yyyy HH:mm');
};

// Calling val.toFixed(2) drops decimals down and creates a string representation of the number. If we then parse out the
// string into a float and call .toString() on that we can remove any 0s padded on at the end. I.e. '16.00` becomes '16'.
// Take that final string value and parse it back to a float and return.
export const trimDecimals = (val, decimals = 2) => parseFloat(parseFloat(val.toFixed(decimals)).toString());

// format percent and ensure at least 1 decimal place even if it is .0%
export const formatPercent = val => {
  let percent = `${trimDecimals(parseFloat(val * 100))}`;
  if (percent.indexOf('.') === -1) {
    return `${percent}.0%`;
  }
  return `${percent}%`;
};
