import React, { useState } from 'react';
import LoadingSpinner from 'shared/loading/LoadingSpinner';
import DropModal from '../modals/DropModal';

const DropChart = ({ loading, data, legacyAccount = {}, organization = {}, refreshDrops, error, currentCustomer }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [type, setType] = useState(null);

  const handleOpenModal = (type) => {
    setType(type);
    setModalOpen(true);
  };

  const handleCloseModal = (shouldRefresh) => {
    setType(null);
    if (shouldRefresh) {
      refreshDrops();
    }
    setModalOpen(false);
  };

  return (
    <React.Fragment>
      {loading ? <LoadingSpinner /> : (
        error ? (
          <div>There was an error loading data from the api.</div>
        ) : (
          <React.Fragment>
            <div className='table-chart'>
              <div className='drop-chart-container'>
                {data.gwiTotal === 0 && data.custTotal === 0 && <div className='empty-state'>No Dropped Containers</div>}
                {data.gwiTotal > 0 && (
                  <React.Fragment>
                    <div className='header-row'>
                      <div className='cell name header-cell'>Location</div>
                      <div className='cell header-cell'>Pending Scheduling</div>
                      <div className='cell header-cell'>Scheduled for Delivery</div>
                      <div className='cell header-cell total'>Totals</div>
                    </div>
                    <div className='drop-row'>
                      <div className='cell name'>
                        <div className='name-inner'>Gulf Winds</div>
                      </div>
                      <div className='cell pending-drop' onClick={() => handleOpenModal('GWIPending')}>
                        <div>{data.gwiPending}</div>
                      </div>
                      <div className='cell' onClick={() => handleOpenModal('GWIComplete')}>
                        <div>{data.gwiComplete}</div>
                      </div>
                      <div className='cell total' onClick={() => handleOpenModal('GWITotal')}>
                        <div>{data.gwiTotal}</div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                {data.custTotal > 0 && (
                  <React.Fragment>
                    <div className='header-row'>
                      <div className='cell name header-cell' />
                      <div className='cell header-cell'>Pending Drop Release</div>
                      <div className='cell header-cell'>Released Pending Pickup</div>
                      <div className='cell header-cell total' />
                    </div>
                    <div className='drop-row'>
                      <div className='cell name'>
                        <div className='name-inner'>Customer</div>
                      </div>
                      <div className='cell pending-drop' onClick={() => handleOpenModal('CustPending')}>
                        <div>{data.custPending}</div>
                      </div>
                      <div className='cell' onClick={() => handleOpenModal('CustComplete')}>
                        <div>{data.custComplete}</div>
                      </div>
                      <div className='cell total' onClick={() => handleOpenModal('CustTotal')}>
                        <div>{data.custTotal}</div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                {data.gwiReturnTotal > 0 && (
                  <React.Fragment>
                    <div className='header-row'>
                      <div className='cell name header-cell' />
                      <div className='cell header-cell'>Pending Scheduling</div>
                      <div className='cell header-cell'>Scheduled for Terminate</div>
                      <div className='cell header-cell total' />
                    </div>
                    <div className='drop-row'>
                      <div className='cell name'>
                        <div className='name-inner'>GWI Yard Return</div>
                      </div>
                      <div className='cell pending-drop' onClick={() => handleOpenModal('GWIReturnPending')}>
                        <div>{data.gwiReturnPending}</div>
                      </div>
                      <div className='cell' onClick={() => handleOpenModal('GWIReturnComplete')}>
                        <div>{data.gwiReturnComplete}</div>
                      </div>
                      <div className='cell total' onClick={() => handleOpenModal('GWIReturnTotal')}>
                        <div>{data.gwiReturnTotal}</div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                {data.custTotal > 0 && (data.gwiTotal || data.gwiReturnTotal > 0) > 0 && (
                  <React.Fragment>
                    <div className='header-row totals' />
                    <div className='drop-row'>
                      <div className='cell name'>
                        <div className='name-inner'>Total Containers</div>
                      </div>
                      <div className='cell pending-drop' onClick={() => handleOpenModal('TotalPending')}>
                        <div>{data.totalPending}</div>
                      </div>
                      <div className='cell' onClick={() => handleOpenModal('TotalComplete')}>
                        <div>{data.totalComplete}</div>
                      </div>
                      <div className='cell total' onClick={() => handleOpenModal('Total')}>
                        <div>{data.total}</div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
            {modalOpen && (
              <DropModal
                isModalShowing={modalOpen}
                handleCloseModal={(shouldRefresh) => handleCloseModal(shouldRefresh)}
                type={type}
                legacyAccount={legacyAccount}
                organization={organization}
                currentCustomer={currentCustomer}
              />
            )}
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

export default DropChart;
