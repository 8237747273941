import { PublicClientApplication, LogLevel } from '@azure/msal-browser';

const tenant = 'e229788c-b41f-4ace-bd37-bf7ba2192586';
const clientId = 'a10d50f7-7737-4134-8f50-288dd1794b3b';

export const msalConfig = {
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${tenant}`,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'localStorage'
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  }
};

export const loginRequest = {
  scopes: [`https://gwii.com/${clientId}/webread`]
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const clearAdalCache = () => {
  let containsAdalToken = false;

  for (let i = localStorage.length - 1; i >= 0; i--) {
    const cacheKey = localStorage.key(i);

    if (cacheKey) {
      if (cacheKey.startsWith('adal')) {
        containsAdalToken = true;
        localStorage.removeItem(cacheKey);
      }
    }
  }

  if (containsAdalToken) {
    msalInstance.logoutRedirect();
    window.location.reload();
  }
};
