import qs from 'qs';
import isNil from 'lodash/isNil';

export const getQueryParams = (params) => (
  !isNil(params) && Object.keys(params).length > 0
    ? `?${qs.stringify(params)}`
    : ''
);

export const makeRequest = (request) =>
  request
    .then(({ data }) => data)
    .catch((error) => {
      console.log(error);
      throw error;
    });
