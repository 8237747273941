import authFetch from './fetch';
import { makeRequest } from './utils';

export const getGroundedContainers = async (payload) => makeRequest(
  authFetch.post('api/GroundedContainers/Summary', payload)
);

export const getGroundedContainersByBucket = async (type, payload) => makeRequest(
  authFetch.post(`api/GroundedContainers/${type}`, payload)
);

export const getGroundedContainersBuckets = async () => makeRequest(
  authFetch.get('api/GroundedContainers/Buckets')
);
