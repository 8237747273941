import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { user, accounts, customers } from './reducers';

// In development, use the browser's Redux dev tools extension if installed
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  accounts,
  customers,
  user,
});

export default () => {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
  return { store };
};
