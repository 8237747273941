import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
// Components
import OldDashboardHeader from 'shared/toolbars/OldDashboardHeader';
import LoadingSpinner from 'shared/loading/LoadingSpinner';
import {
  OrderNumbers,
  OrderProgress,
  OrderManagement,
  TrackingDates,
  OrderDetails,
  OrderFiles
} from '../detail-sections';
// Fetch
import { searchOrders } from 'src/fetch';
// Customer Access
import { VIEW_DOCUMENTS } from 'shared/../constants/CustomerAccess';

const Search = ({ location, customerAccess }) => {
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  const defaultSearchValue = query.pro && query.pro.length ? query.pro : query.container && query.container.length ? query.container : '';
  const [search, setSearch] = useState(defaultSearchValue);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const handleSearch = async value => {
    setLoading(true);
    setData(null);

    try {
      let response;
      if (Number.isInteger(parseInt(value))) {
        response = await searchOrders(null, null, null, value, null);
      } else {
        response = await searchOrders(null, null, null, null, value);
      }

      setData(response);
      setLoading(false);
      setHasSearched(true);
      window.history.pushState('', '', '/search');
    } catch (error) {
      setHasSearched(true);
      setData(null);
      setLoading(false);
      window.history.pushState('', '', '/search');
    }
  };

  useEffect(() => {
    if ((query.pro && query.pro.length) || (query.container && query.container.length)) {
      handleSearch(query.pro ? query.pro : query.container);
    }
  }, []);

  return (
    <div className='search old-search'>
      <OldDashboardHeader
        customerAccess={customerAccess}
        onSearch={val => {
          setSearch(val);
          handleSearch(val);
        }}
        value={search}
      />
      <div className='search-data'>
        <React.Fragment>
          {loading && <LoadingSpinner />}
          {hasSearched ? (
            data ? (
              <React.Fragment>
                <div className='top-row'>
                  <OrderNumbers data={data} />
                  <OrderProgress data={data} />
                  <OrderManagement data={data} />
                </div>
                <div className='middle-row'>
                  <TrackingDates data={data} />
                  <OrderDetails data={data} />
                </div>
                {customerAccess && customerAccess[VIEW_DOCUMENTS] && (
                  <div className='bottom-row'>
                    <OrderFiles proNumber={data.proNumber} />
                  </div>
                )}
              </React.Fragment>
            ) : (
              !loading && (
                <div className='no-result'>
                  <div className='no-result-title'>No Results Found</div>
                  <div className='no-result-text'>Please try a new search query</div>
                </div>
              )
            )
          ) : !loading && (
            <div className='no-result'>
              <div className='no-result-title'>Order Search</div>
              <div className='no-result-text'>Please enter a search query to get started</div>
            </div>
          )}
        </React.Fragment>
      </div>
    </div>
  );
};

export default withRouter(connect(
  state => ({
    customerAccess: state.user && state.user.customerAccess,
    customerLoading: state.user && state.user.isLoading
  }),
  {}
)(Search));

