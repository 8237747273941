import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
// Components
import LoadingModal from 'shared/loading/LoadingModal';
import MaintenanceTable from 'shared/tables/MaintenanceTable';
import Modal from 'shared/modals/Modal';
// Fetch
import { getGroundedContainersByBucket } from 'src/fetch';
// Customer Access
import { VIEW_MULTI_SEARCH } from '../../../shared/constants/CustomerAccess';

const GroundedContainersModal = ({
  isModalShowing,
  handleCloseModal,
  type,
  legacyAccount, 
  organization,
  currentCustomer,
  customerAccess,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  const hasMultiSearch = useMemo(() => customerAccess && customerAccess[VIEW_MULTI_SEARCH], [customerAccess]);

  const loadData = async () => {
    try {
      const response = await getGroundedContainersByBucket(type, {
        LegacyAccounts: legacyAccount,
        Organizations: organization,
        EmailAddress: currentCustomer.value,
      });
      setData(response);
      setLoading(false);
    } catch (apiError) {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    if (type) {
      loadData();
    }
  }, [type]);

  const columns = [
    {
      Header: 'Pro #',
      accessor: 'proNumber',
      isCentered: true,
      width: 115,
      id: 'proNumber',
      Cell: ({ original }) =>
        hasMultiSearch ? (
          <Link className='link' to={`/search?type=0&query=${original.proNumber}`} target='_blank'>
            {original.proNumber}
          </Link>
        ) : (
          <Link className='link' to={`/search?pro=${original.proNumber}`} target='_blank'>
            {original.proNumber}
          </Link>
        ),
    },
    { Header: 'Container', accessor: 'container', isCentered: true, width: 155 },
    { Header: 'BOL/BKG/Ref', accessor: 'bolBkg', isCentered: true, width: 155 },
    { Header: 'First Rcv', accessor: 'firstRcv', isCentered: true, isDate: true, width: 155 },
    { Header: 'Cutoff', accessor: 'cutoff', isCentered: true, isDate: true, width: 155 },
    { Header: 'PerDiemLfd', accessor: 'perDiemLfd', isCentered: true, isDate: true },
    { Header: 'Vessel', accessor: 'vessel', isCentered: true },
  ];

  const getHeader = () => {
    switch (type) {
      case 1:
        return 'Grounded Containers - Receiving Today';
      case 2:
        return 'Grounded Containers - Todays Cutoffs';
      case 3:
        return 'Grounded Containers - Grounded Today';
      case 4:
        return 'Grounded Containers - Total Grounded';
      default:
        return '';
    }
  };

  const buildCSVHeaders = () => {
    return columns
      .filter((col) => col.accessor)
      .map((col) => {
        return { label: col.Header, key: col.accessor };
      });
  };

  return (
    <Modal
      isOpen={isModalShowing}
      title={getHeader()}
      handleClose={() => handleCloseModal()}
      containerStyle='large'
      className='grounded-containers-modal'
    >
      <div className='modal-header'>
        <h5 className='modal-title' id='drop-modal'>
          Grounded Containers
        </h5>
        <button className='button button--large button--close' onClick={() => handleCloseModal()} />
      </div>
      <LoadingModal isOpen={loading} />
      <section className='modal-body'>
        {!error && !loading && (
          <MaintenanceTable
            wrapperClassName='grounded-containers-table-container'
            className='grounded-containers-table table--primary-color'
            data={data}
            apiError={error}
            isLoading={loading}
            columns={columns}
            tableOpts={{
              showPagination: data && data.length > 0,
              showPaginationBottom: true,
              showPageSizeOptions: true,
              pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
              defaultPageSize: 15,
              pageSize: data && data.length ? undefined : 0,
            }}
          />
        )}
        {error && !loading && <div>There was an error loading the data. Please try again.</div>}
      </section>
      <section className='modal-footer toolbar toolbar--xxs'>
        <CSVLink
          id='export-details'
          className='toolbar--button--margin button button--large button--primary-color text-align--center'
          headers={buildCSVHeaders()}
          data={data}
          filename={`${getHeader()}.csv`}
        >
          Export Details
        </CSVLink>
        <button
          id='close-drop-modal-button'
          type='button'
          className='toolbar--button--margin button button--large button--white text-align--center margin--none--xxs'
          onClick={() => handleCloseModal()}
        >
          Cancel
        </button>
      </section>
    </Modal>
  );
};

export default connect(
  (state) => ({
    customerAccess: state.user && state.user.customerAccess,
  }),
  {},
)(GroundedContainersModal);
