import React, { useState } from 'react';
// Components
import Modal from 'shared/modals/Modal';
import toastr from 'shared/helpers/toastr';
import errorToastr from 'shared/helpers/errorToastr';
// Fetch
import { updateDropReleases, setOrderHot } from 'src/fetch';

// eslint-disable-next-line import/prefer-default-export
export const OrderManagement = ({ data }) => {
  const [isHot, setIsHot] = useState(data && data.isHot);
  const [showReleaseModal, setShowReleaseModal] = useState(false);
  const [showHotModal, setShowHotModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [released, setReleased] = useState(false);

  const handleToggleHot = async () => {
    setLoading(true);
    try {
      await setOrderHot(data.proNumber, !isHot);
      setIsHot(!isHot);
      toastr('success', 'Order Update Saved Successfully.');
      setLoading(false);
      setShowHotModal(false);
    } catch (error) {
      setLoading(false);
      errorToastr({
        error,
        messagePrefix: 'Order Update Failed'
      });
    }
  };

  const handleReleaseContainer = async () => {
    setLoading(true);
    try {
      await updateDropReleases([data.proNumber]);
      toastr('success', 'Container Released Successfully.');
      setLoading(false);
      setShowReleaseModal(false);
      setReleased(true);
    } catch (error) {
      setLoading(false);
      errorToastr({
        error,
        messagePrefix: 'Container Release Failed'
      });
    }
  };

  const renderModalBody = () => {
    if (loading) {
      return <div>Loading...</div>;
    }
    return <div>You are about to release this container. Do you wish to continue?</div>;
  };

  const renderHotModalBody = () => {
    if (loading) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          {`You are about to mark this order as ${isHot ? 'no longer hot' : 'hot'}. Do you wish to continue?`}
        </div>
      );
    }
  };

  return (
    <div className='detail-section order-management'>
      <div className='title-container'>
        <div className='title'>Order Management</div>
        <div className='title-bar' />
      </div>
      <div className='data-container'>
        <div className='toggle'>
          <label className='label' htmlFor='hotToggle'>Hot Order?</label>
          <div className="toggle-container" onClick={() => setShowHotModal(true)}>
            <div className={`toggle-button ${isHot ? 'on' : 'off'}`}>
              {isHot ? 'Yes' : 'No'}
            </div>
          </div>
        </div>
        {data.canSetDropRelease && (
          <div className='button-container'>
            <div>
             <label className='label'>Drop Release</label>
            </div>
            <button
              id='release-button'
              className='button button text-align--center'
              disabled={!data.canSetDropRelease || loading || released}
              onClick={() => setShowReleaseModal(true)}
            >
              Release
            </button>
          </div>
        )}
      </div>
      <Modal
        isOpen={showReleaseModal}
        handleClose={() => setShowReleaseModal(false)}
        containerStyle='small'
        className='order-management-release-modal'
      >
        <div className='modal-header'>
          <h5 className='modal-title' id='confirm-drop-modal'>
            Confirmation
          </h5>
          <button className='button button--large button--close' onClick={() => setShowReleaseModal(false)} />
        </div>
        <section className='modal-body'>
          {renderModalBody()}
        </section>
        <section className='modal-footer toolbar toolbar--xxs'>
          <button
            id='order-management-release-button'
            type='button'
            className='toolbar--button--margin button button--large button--primary-color text-align--center'
            onClick={() => handleReleaseContainer()}
            disabled={loading}
          >
            Release
          </button>
          <button
            id='order-management-release-cancel-button'
            type='button'
            className='toolbar--button--margin button button--large button--white text-align--center margin--none--xxs'
            onClick={() => setShowReleaseModal(false)}
            disabled={loading}
          >
            Cancel
          </button>
        </section>
      </Modal>
      <Modal
        isOpen={showHotModal}
        handleClose={() => setShowHotModal(false)}
        containerStyle='small'
        className='order-management-update-modal'
      >
        <div className='modal-header'>
          <h5 className='modal-title' id='confirm-drop-modal'>
            Confirmation
          </h5>
          <button className='button button--large button--close' onClick={() => setShowHotModal(false)} />
        </div>
        <section className='modal-body'>
          {renderHotModalBody()}
        </section>
        <section className='modal-footer toolbar toolbar--xxs'>
          <button
            id='order-management-update-button'
            type='button'
            className='toolbar--button--margin button button--large button--primary-color text-align--center'
            onClick={() => handleToggleHot()}
            disabled={loading}
          >
            Update
          </button>
          <button
            id='order-management-update-cancel-button'
            type='button'
            className='toolbar--button--margin button button--large button--white text-align--center margin--none--xxs'
            onClick={() => setShowHotModal(false)}
            disabled={loading}
          >
            Cancel
          </button>
        </section>
      </Modal>
    </div>
  );
};
