import React, { useState } from 'react';
import isNil from 'lodash/isNil';
// components
import { Button } from '@progress/kendo-react-buttons';
import { TextArea } from '@progress/kendo-react-inputs';
// toast
import toastr from 'shared/helpers/toastr';
import errorToastr from 'shared/helpers/errorToastr';
// fetch
import { sendOrderComment } from 'src/fetch/orders';

const CommentSection = ({ initialValue, proNumber }) => {
  const [comment, setComment] = useState(initialValue ?? '');
  const [isSending, setIsSending] = useState(false);

  const onChange = ({ value }) => setComment(value);

  const onSend = () => {
    setIsSending(true);
    sendOrderComment(proNumber, comment)
      .then(() => {
        toastr('success', 'Comment sent successfully');
      })
      .catch((error) => {
        errorToastr({ error });
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  return (
    <div className='detail-section comment-section'>
      <div className='title-container'>
        <div className='title'>Comment</div>
        <div className='title-bar' />
      </div>
      <div className='data-container'>
        <TextArea value={comment} onChange={onChange} disabled={isSending} />
        <Button
          icon={isSending ? 'loading' : 'save'}
          onClick={onSend}
          disabled={comment === '' || isNil(comment)}
        >
          Send & Save
        </Button>
      </div>
    </div>
  );
};

export default CommentSection;
