import React from 'react';
import cn from 'classnames';
import LineChart from './LineChart';
import BarChart from './BarChart';
import MilestoneChart from './MilestoneChart';
import DropChart from './DropChart';
import GroundedContainersChart from './GroundedContainersChart';
import MoveTypeChart from './MoveTypeChart';
import ExportChart from './ExportChart';
import DeliverySchedulesChart from './DeliverySchedulesChart';
import './style.scss'

const strokeColors = ['#121726', '#D9E026', '#016496', '#0079a4', '#e60000', '#ef940b', '#259e27', '#1ba3ce'];

const DashboardChart = ({
  loading = true,
  title,
  subtitle,
  chartType,
  detailsComponent,
  data = [],
  // tickRotationBottom = -45,
  // legendBottom = '',
  // legendBottomOffset = 0,
  // legendBottomPosition = 'middle',
  // leftFormat = (d) => `${d}`,
  // legendLeft = '',
  // legendLeftOffset = 0,
  // legendLeftPosition = 'middle',
  // tickValuesLeft = undefined,
  // yMin = 'auto',
  yMax = 'auto',
  // margin = { top: 20, right: 10, bottom: 100, left: 40 },
  // axisTop = null,
  // axisRight = null,
  // minimumFractionDigits = 2,
  legacyAccount = [],
  organization = [],
  refreshDrops,
  refreshData,
  moveType,
  error,
  cutoffTypes = [],
  emptiesTypes = [],
  earlyReturnsTypes = [],
  importCategoryTypes = [],
  domesticCategoryTypes = [],
  todaysDeliveriesTypes = [],
  showResponsive = false,
  currentCustomer,
  onParametersChange,
  className,
}) => {
  // super helpful theme props
  // https://github.com/plouc/nivo/issues/308#issuecomment-507883123
  const theme = {
    tooltip: {
      container: {
        background: '#121726',
        color: '#fff',
        fontSize: '12px',
        borderRadius: '10px',
        boxShadow: 'none',
        padding: '10px 14px',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: -65,
        left: '50%',
        marginLeft: '-67px',
        width: '135px',
        textAlign: 'center'
      }
    },
    axis: {
      ticks: {
        text: {
          fontSize: '14px'
        }
      },
      legend: {
        text: {
          fill: '#0073ae',
          fontSize: '16px'
        }
      }
    },
    background: 'transparent'
  };

  const renderChart = () => {
    switch (chartType) {
      case 'moveType':
        return (
          <MoveTypeChart
            data={data}
            title={title}
            moveType={moveType}
            legacyAccount={legacyAccount}
            organization={organization}
            loading={loading}
            error={error}
            importCategoryTypes={importCategoryTypes}
            domesticCategoryTypes={domesticCategoryTypes}
            todaysDeliveriesTypes={todaysDeliveriesTypes}
            currentCustomer={currentCustomer}
          />
        );
      case 'export':
        return (
          <ExportChart
            data={data}
            title={title}
            moveType={moveType}
            legacyAccount={legacyAccount}
            organization={organization}
            loading={loading}
            error={error}
            cutoffTypes={cutoffTypes}
            emptiesTypes={emptiesTypes}
            earlyReturnsTypes={earlyReturnsTypes}
            currentCustomer={currentCustomer}
          />
        );
      case 'line':
        return <LineChart data={data} theme={theme} strokeColors={strokeColors} loading={loading} error={error} />;
      case 'bar':
        return (
          <BarChart
            data={data}
            legacyAccount={legacyAccount}
            organization={organization}
            yMax={yMax}
            loading={loading}
            error={error}
            currentCustomer={currentCustomer}
          />
        );
      case 'deliverySchedule':
        return (
          <DeliverySchedulesChart
            data={data}
            legacyAccount={legacyAccount}
            organization={organization}
            loading={loading}
            error={error}
            currentCustomer={currentCustomer}
            onParametersChange={onParametersChange}
          />
        );
      case 'milestone':
        return (
          <MilestoneChart
            data={data}
            legacyAccount={legacyAccount}
            organization={organization}
            loading={loading}
            error={error}
            showResponsive={showResponsive}
            currentCustomer={currentCustomer}
          />
        );
      case 'drop':
        return (
          <DropChart
            data={data}
            legacyAccount={legacyAccount}
            organization={organization}
            loading={loading}
            refreshDrops={refreshDrops}
            error={error}
            currentCustomer={currentCustomer}
          />
        );
      case 'grounded':
        return (
          <GroundedContainersChart
            data={data}
            legacyAccount={legacyAccount}
            organization={organization}
            loading={loading}
            refreshData={refreshData}
            error={error}
            currentCustomer={currentCustomer}
          />
        );
      default:
        return <div>No Data Yet</div>;
    }
  };

  const getSubtitle = string => {
    const split = string.split('//');
    return (
      <div>
        {split[0]}
        <span className='subtitle-slash'>{'//'}</span>
        {split[1]}
      </div>
    );
  };

  const dashboardChartClassName = cn('dashboard-chart', className);

  return (
    <div className={dashboardChartClassName}>
      <div className='title-container'>
        <div className='title'>{title}</div>
        <div className='title-bar' />
      </div>
      <div className='subtitle'>{subtitle.indexOf('//') > -1 ? getSubtitle(subtitle) : `${subtitle}`}</div>
      <div className='details'>{detailsComponent}</div>
      <div className='chart-wrapper' style={{ height: '390px', marginTop: 0 }}>
        {renderChart()}
      </div>
    </div>
  );
};

export default DashboardChart;
