import authFetch from './fetch';

export const getMilestoneTracker = async ({ LegacyAccounts, Organizations, EmailAddress }) => {
  try {
    const { data } = await authFetch.post('api/MilestoneTracker', { LegacyAccounts, Organizations, EmailAddress });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getMilestonesByType = async ({ LegacyAccounts, Organizations, EmailAddress, Type, DayCount }) => {
  try {
    const { data } = await authFetch.post('api/MilestoneTracker/ByType', { LegacyAccounts, Organizations, EmailAddress, Type, DayCount });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};
