import React, { forwardRef } from 'react';
import './style.scss';
import isNil from 'lodash/isNil';
import { format } from 'date-fns';
// components
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Button } from '@progress/kendo-react-buttons';
import CustomDateInput from './CustomDateInput';

const getReadOnlyDate = (date) => (date ? format(date, 'MM/dd/yyyy') : '');
const ReadOnlyDate = (props) => {
  const {
    value,
    placeholder = 'mm/dd/yyyy',
  } = props;

  if (isNil(value)) {
    return (
      <div className="read-only empty">
        {placeholder}
      </div>
    );
  }

  return (
    <div className="read-only">
      {getReadOnlyDate(value)}
    </div>
  );
};

const DateInput = forwardRef(({ width, allowClear = true, ...props }, ref) => {
  const { onChange } = props;
  const onClear = () => onChange({ value: null });
  return (
    <span className='k-widget k-dateinput date-input-container' {...(!isNil(width) ? { style: { width } } : {})}>
      <span className='k-dateinput-wrap'>
        <CustomDateInput ref={ref} {...props} />
        {allowClear && <Button themeColor='secondary' fillMode='flat' icon='x' onClick={onClear} />}
      </span>
    </span>
  );
});

const AppDatePicker = forwardRef(({
  readOnly, width, allowClear = true, ...props
}, ref) => {
  const { value, defaultValue } = props;

  if (readOnly) {
    return (
      <ReadOnlyDate value={value ?? defaultValue} />
    );
  }

  return (
    <DatePicker
      ref={ref}
      dateInput={(inputProps) => <DateInput allowClear={allowClear} width={width} {...inputProps} />}
      format="MM/dd/yyyy"
      {...props}
    />
  );
});

export default AppDatePicker;
