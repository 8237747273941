import authFetch from './fetch';
import { makeRequest } from './utils';

const routes = {
  loginPath: () => '/api/Auth/login',
  refreshPath: () => '/api/Auth/refresh',
  resetPasswordPath: () => '/api/Auth/resetPassword',
  forgotPasswordPath: () => '/api/Auth/forgotPassword',
};

export const resetPassword = (data) => makeRequest(
  authFetch.post(routes.resetPasswordPath(), data),
);

export const requestPasswordReset = (data) => makeRequest(
  authFetch.post(routes.forgotPasswordPath(), data),
);

export const authenticateUser = (username, password, mfaCode) => {
  const basic = Buffer.from(`${username}:${password}`).toString('base64');
  return makeRequest(
    authFetch.post(routes.loginPath(), { mfaCode }, { authorization: `Basic ${basic}` }, { avoidInterception: true }),
  );
};

export const refreshUserToken = (refreshToken) => makeRequest(
  authFetch.post(routes.refreshPath(), { refreshToken }, {}, { avoidInterception: true }),
);
