import React, { useState, useEffect, forwardRef } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { isNaN, isNil } from 'lodash';
import { format } from 'date-fns';

const formatDate = (val) => {
  if (isNil(val)) {
    return val;
  }

  // allow for shortcuts
  const today = new Date();
  const newVal = new Date();
  let result = val;

  if (!isNaN(val)) {
    // convert dates like 0428 to 04/28/YYYY
    if (val.toString().length === 4) {
      const month = val.toString().slice(0, 2);
      const day = val.toString().slice(2, 4);
        result = `${month}/${day}/${today.getFullYear()}`;
      
    } else {
        //result = newVal.setDate(today.getDate() + Number(val));
        
    }
  } else if (val.split('/').length === 2) {
    // apply a year since we can assume it wasn't provided
    result = new Date(val).setFullYear(today.getFullYear());
    }

  return format(new Date(result), 'MM/dd/yyyy');
};

const CustomDateInput = forwardRef(({
  id, onChange, value, onBlur, placeholder, visited, validationMessage, ...props
}, ref) => {
  const [textDate, setTextDate] = useState();

  const handleBlur = (e) => {
    if (e.target.value) {
      const formatted = formatDate(e.target.value);

      // Check if any change have happened to avoid firing onChange for the same value
      const newValue = new Date(formatted);
      if (isNil(value) || formatted !== format(value, 'MM/dd/yyyy')) {
        setTextDate(formatted);
        onChange({ value: newValue });
      }
    } else {
      setTextDate('');

      // Check if Date value is not null already to avoid firing onChange for the same value
      if (value) {
        onChange({ value: null });
      }
    }

    if (onBlur) {
      onBlur(e);
    }
  };

  useEffect(() => {
    if (value) {
      setTextDate(format(new Date(value), 'MM/dd/yyyy'));
    } else {
      setTextDate('');
    }
  }, [value]);

  return (
    <Input
      ref={ref}
      placeholder={placeholder || 'mm/dd/yyyy or days'}
      {...props}
      className='k-input'
      onBlur={handleBlur}
      value={textDate}
      onChange={({ value }) => setTextDate(value)}
    />
  );
});

export default CustomDateInput;
