import { default as toast } from 'toastr';

const toastr = (type = 'success', message = '', title = '', otherOptions = {}) => {
  toast[type](message, title, {
    positionClass: 'toast-bottom-right',
    ...otherOptions
  });
};

export default toastr;
