import React from 'react';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import isFunction from 'lodash/isFunction';
import ReactTooltip from 'react-tooltip';
// components
import ReactTable, { ReactTableDefaults } from 'react-table';
import NoDataComponent from './NoDataComponent';
import TablePagination from './TablePagination';
// classes
import 'react-table/react-table.css';
// helpers
import { dateFormatter, formatPercent } from 'shared/../constants/Formatting';
import { getClientDateTime } from 'shared/../helpers/date';

// Any styles we apply (overriding defualt table styles) have to contain fontWeight or else
// classes will override font-weight and apply bold to the last column.
const baseStyle = { fontWeight: 400, textAlign: 'center' };

// sorting for numeric values that are displayed with non-numeric chars (i.e. $, %);
const numberSort = (a, b) => Number(a.replace(/[^0-9.-]+/g, '')) - Number(b.replace(/[^0-9.-]+/g, ''));

// sort for date
const dateSort = (a, b) => (Number(new Date(b)) || 0) - (Number(new Date(a)) || 0);

class Table extends React.Component {
  table = null;

  state = {
    selected: [],
    dragging: false,
    dragInfo: null,
  };

  getSortedDragIndexes = () => {
    const { dragInfo } = this.state;

    if (!dragInfo || !this.table) {
      return { startIndex: -1, endIndex: -1 };
    }

    // we have to get the index of the items in the sorted table data (if data is sorted)
    // to determine what should be highlighted. If we are sorted find the right index
    // otherwise the current start and end is sufficient
    const resolvedState = this.table.getResolvedState();
    const sortedData = [...resolvedState.sortedData];
    const sortedStartIndex = resolvedState.sorted.length ? findIndex(sortedData, (x) => x._index === dragInfo.startIndex) : dragInfo.startIndex;
    const sortedEndIndex = resolvedState.sorted.length ? findIndex(sortedData, (x) => x._index === dragInfo.endIndex) : dragInfo.endIndex;

    // get the rows between sortedStart and sortedEnd then determine if we fall in that range
    const isDragDown = sortedStartIndex < sortedEndIndex;

    return { startIndex: isDragDown ? sortedStartIndex : sortedEndIndex, endIndex: isDragDown ? sortedEndIndex : sortedStartIndex, isDragDown };
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.clearSelection !== this.props.clearSelection && this.props.clearSelection) {
      this.setState({ selected: [] });
    }
    ReactTooltip.rebuild();
  };

  componentDidMount = () => {
    if (this.props.defaultSelected) {
      this.setState({ selected: this.props.defaultSelected });
    }
  };

  render() {
    const {
      data,
      columns,
      defaultSort,
      noResultsText,
      sortable,
      justifyContent,
      textAlign,
      justifyHeader,
      headerClass,
      hideDefaultTooltip = false,
      selectable,
      multiSelect = false,
      onSelect,
      tableOpts,
      id = '',
      className = '',
      bodyClassName = '',
      onDragEnd,
      showSelectStyle = true,
      preventDeselect,
      preventEmptyCell,
      hasSelects,
      onPaste,
      fixedHeight,
    } = this.props;

    const formatColumn = (col) => {
      if (col.isCurrency) {
        col.id = col.accessor;
        col.accessor = (d) => (d[col.id] !== null && d[col.id] !== undefined ? `$${d[col.id].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : '');
        col.sortMethod = numberSort;
        col.style = {
          ...baseStyle,
          textAlign: 'end',
        };
        col.hideTooltip = true;
      } else if (col.isNumeric) {
        col.id = col.accessor;
        col.accessor = (d) => (d[col.id] !== null && d[col.id] !== undefined ? `${d[col.id].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : '');
        col.sortMethod = numberSort;
        col.style = {
          ...baseStyle,
          textAlign: 'end',
        };
        col.hideTooltip = true;
      } else if (col.isPercent) {
        col.id = col.accessor;
        col.accessor = (d) => (d[col.id] ? formatPercent(d[col.id]) : '');
        col.sortMethod = numberSort;
        col.style = baseStyle;
        col.hideTooltip = true;
      } else if (col.isDate) {
        col.id = col.accessor;
        col.accessor = (d) => (d[col.id] ? dateFormatter(d[col.id]) : '');
        col.sortMethod = dateSort;
        col.style = baseStyle;
        col.hideTooltip = true;
      } else if (col.isDateTime) {
        col.id = col.accessor;
        col.accessor = (d) => (d[col.id] ? getClientDateTime(d[col.id], true) : '');
        col.sortMethod = dateSort;
        col.style = baseStyle;
        col.hideTooltip = true;
      } else if (col.isCentered) {
        col.style = baseStyle;
        col.hideTooltip = true;
      }
    };
    // Handle column types for simplicity - currency, number, etc...
    columns.forEach((col) => {
      formatColumn(col);
      // handle nested columns
      if (col.columns) {
        col.columns.forEach((nestedCol) => formatColumn(nestedCol));
      }
    });

    return (
      <ReactTable
        ref={(r) => (this.table = r)}
        className={`table ReactVirtualized__Table  -striped ${ className}`}
        data={data || []}
        defaultSorted={defaultSort ? [defaultSort] : []}
        columns={columns}
        noDataText={noResultsText || 'There are no results available'}
        NoDataComponent={NoDataComponent}
        pageSize={tableOpts && tableOpts.showPageSizeOptions ? undefined : data && data.length ? data.length : 0}
        showPagination={false}
        sortable={sortable}
        PaginationComponent={TablePagination}
        style={fixedHeight ? { maxHeight: fixedHeight } : {}}
        column={{
          ...ReactTableDefaults.column,
          headerClassName: `ReactVirtualized__Table__headerRow table--header-cell no-border ${headerClass || ''}`,
          footerClassName: 'card--secondary-color card--content',
          style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: justifyContent || 'flex-end',
            ...(textAlign ? { textAlign } : {}),
            fontWeight: 400,
          },
          Cell: (cellInfo) => {
            const {
              column, original, index, value,
            } = cellInfo;
            const cellClassName = column.cellClassName
              ? typeof column.cellClassName === 'string'
                ? column.cellClassName
                : column.cellClassName(original)
              : '';
            if ((isFunction(column.editable) && column.editable(original)) || column.editable) {
              if (column.disabled && column.disabled(original)) {
                return (
                  <div
                    className={`cell-disabled ${cellClassName}`}
                    data-tip={typeof value === 'string' && !hideDefaultTooltip && !column.hideTooltip ? value : null}
                  >
                    {column.formatter ? column.formatter(value) : value}
                  </div>
                );
              }

              return (
                <div
                  className={cellClassName}
                  data-tip={typeof value === 'string' && !hideDefaultTooltip && !column.hideTooltip ? value : null}
                  key={column.generateKey ? column.generateKey(original) : undefined}
                  style={{ height: '100%', width: '100%', textAlign: justifyContent || 'right' }}
                  contentEditable
                  suppressContentEditableWarning
                  onFocus={(e) => {
                    // reset errors if they exist on focus
                    if (e.target.style.color === 'red') {
                      e.target.style.color = '';
                      e.target.innerHTML = '';
                    }
                  }}
                  onBlur={async (e) => {
                    // DON'T CHANGE THIS
                    // React is doing something weird and if we reference the data from deconstructed props
                    // above, editable cells break and do abnormal things. Even if we rename the variable from
                    // `data` to `newData`.
                    const data = [...this.props.data];
                    let val = e.target.innerText;

                    // convert value before doing any validation
                    if (val && column.formatter) {
                      val = column.formatter(val);
                    }

                    let isValid = true;
                    if (column.validator) {
                      e.persist(); // needed for async validation
                      isValid = await column.validator(val);
                    }

                    if (isValid) {
                      const newLineItem = { ...data[index] };

                      // if the new value coming in doesn't exist or is empty string, then
                      // reset it to what the current value is in the data array instead of
                      // updating that value.
                      if (preventEmptyCell && (val === '' || val === null || val === undefined)) {
                        val = newLineItem[column.id];
                      }

                      newLineItem[column.id] = val;
                      data[index] = newLineItem;

                      if (this.props.onChange) {
                        this.props.onChange(data, index);
                      }

                      // Keep the value we sent to onChange and stored in the data
                      // array in sync for the user. Mainly useful when preventEmptyCell === true
                      e.target.innerText = val;
                    } else {
                      const err = column.validatorError ? column.validatorError : 'Error Validating Input';
                      e.target.innerText = err;
                      e.target.style.color = 'red';
                    }
                  }}
                  dangerouslySetInnerHTML={{
                    __html: this.props.data[index]
                      ? this.props.data[index][column.id] && column.formatter
                        ? column.formatter(this.props.data[index][column.id])
                        : this.props.data[index][column.id]
                      : '',
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      const editableDivs = document.querySelectorAll('div[contenteditable=true]');
                      for (let i = 0; i < editableDivs.length; ++i) {
                        const current = editableDivs[i];
                        if (current === e.target && i !== editableDivs.length - 1) {
                          editableDivs[++i].focus();
                          return;
                        }
                      }
                    }
                  }}
                  onMouseDown={(e) => {
                    this.setState({
                      dragging: true,
                      dragInfo: {
                        startIndex: index, value: e.target.innerText, column: column.id, original,
                      },
                    });
                  }}
                  onMouseMove={() => {
                    const { dragInfo, dragging } = this.state;
                    if (dragging && dragInfo) {
                      const { startIndex } = dragInfo;
                      if (startIndex !== index) {
                        this.setState({ dragInfo: { ...dragInfo, endIndex: index } });
                      }
                    }
                  }}
                  onPaste={(e) => {
                    if (onPaste) {
                      onPaste(e, column.id, index);
                      e.preventDefault();
                    }
                  }}
                />
              );
            } if (column.draggable) {
              // handle draggable cells differently. Need to capture mouseDown and mouseMove.
              // call preventDefault on the event to prevent table text from being highlighted.
              return (
                <div
                  className={cellClassName}
                  data-tip={typeof value === 'string' && !hideDefaultTooltip && !column.hideTooltip ? value : null}
                  onMouseDown={(e) => {
                    this.setState({
                      dragging: true,
                      dragInfo: {
                        startIndex: index, value: e.target.innerText, column: column.id, original,
                      },
                    });
                    e.preventDefault();
                  }}
                  onMouseMove={(e) => {
                    const { dragInfo, dragging } = this.state;
                    if (dragging && dragInfo) {
                      const { startIndex } = dragInfo;
                      if (startIndex !== index) {
                        this.setState({ dragInfo: { ...dragInfo, endIndex: index } });
                      }
                    }
                    e.preventDefault();
                  }}
                >
                  {value}
                </div>
              );
            }

            return (
              <div
                className={cellClassName}
                style={{ whiteSpace: 'pre', overflow: 'visible' }}
                data-tip={typeof value === 'string' && !hideDefaultTooltip && !column.hideTooltip ? value : null}
              >
                {value}
              </div>
            );
          },
        }}
        getTrGroupProps={() => ({
          className: 'ReactVirtualized__Table__row table--row',
        })}
        getTdProps={(state, rowInfo, column) => {
          let isDragSelected = false;
          if (rowInfo) {
            const { dragInfo } = this.state;
            const { index } = rowInfo;

            if (dragInfo && dragInfo.startIndex !== undefined && dragInfo.endIndex !== undefined && column.id === dragInfo.column) {
              // get the actual start/end indexes based on the sortedData
              const { startIndex, endIndex, isDragDown } = this.getSortedDragIndexes();
              const sortedData = [...this.table.getResolvedState().sortedData];

              // pull in the right order so ensure we swap end and start if we are dragging up
              const selectRange = sortedData.slice(startIndex, endIndex + 1).map((x) => x._index);
              isDragSelected = selectRange.indexOf(index) > -1;
            }
          }

          return {
            className: `ReactVirtualized__Table__rowColumn${isDragSelected ? ' drag-selected' : ''}`,
            style: { alignItems: 'stretch' },
            onMouseUp: () => {
              const { dragInfo, dragging } = this.state;

              if (dragging && dragInfo && dragInfo.startIndex !== dragInfo.endIndex && dragInfo.endIndex !== undefined && onDragEnd) {
                // if the new value coming in doesn't exist or is empty string, then
                // don't fire drag end and just let the state reset if preventEmptyCell === true
                const { value } = dragInfo;
                if (!preventEmptyCell || (value !== '' && value !== null && value !== undefined)) {
                  const sortedData = [...this.table.getResolvedState().sortedData];
                  const { startIndex, endIndex, isDragDown } = this.getSortedDragIndexes();
                  onDragEnd({
                    ...dragInfo, startIndex, endIndex, sortedData, isDragDown,
                  });
                }
              }

              this.setState({ dragging: false, dragInfo: null });
            },
          };
        }}
        getTableProps={() => ({
          className: hasSelects ? 'overflow-visible' : '',
        })}
        getTbodyProps={() => ({
          className: `${bodyClassName || 'table--body'}${hasSelects ? ' overflow-visible' : ''}`,
          ...(id && { id }),
        })}
        getTheadThProps={() => ({
          style: { justifyContent: justifyHeader || 'center' },
        })}
        getTrProps={(state, rowInfo) => {
          if (selectable && rowInfo && rowInfo.row) {
            return {
              onClick: (e, handleOriginal) => {
                // do nothing if the user clicked a link
                if (e.target.tagName.toLowerCase() === 'a') {
                  return;
                }

                let { selected } = this.state;
                const { index } = rowInfo;
                if (selected.indexOf(index) > -1 && !preventDeselect) {
                  selected.splice(selected.indexOf(index), 1);
                } else if (multiSelect) {
                  selected.push(index);
                } else {
                  selected = [index];
                }
                this.setState({ selected }, () => {
                  if (onSelect) {
                    onSelect(selected);
                  }

                  if (handleOriginal) {
                    handleOriginal();
                  }
                });
              },
              style: {
                background: showSelectStyle && this.state.selected.indexOf(rowInfo.index) > -1 ? '#008BD2' : undefined,
                cursor: 'pointer',
                color: showSelectStyle && this.state.selected.indexOf(rowInfo.index) > -1 ? '#ffffff' : undefined,
              },
            };
          }
          return {};
        }}
        {...tableOpts}
      />
    );
  }
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.oneOfType([PropTypes.func, PropTypes.array]).isRequired,
  noResultsText: PropTypes.string,
  sortable: PropTypes.bool,
  justifyContent: PropTypes.string,
  justifyHeader: PropTypes.string,
  selectable: PropTypes.bool,
  multiSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  tableOpts: PropTypes.object,
  preventDeselect: PropTypes.bool,
  clearSelection: PropTypes.bool,
  defaultSelected: PropTypes.arrayOf(PropTypes.number),
};

export default Table;
