import * as React from 'react';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  render() {
    let currentDate = new Date();

    let currentYear = currentDate.getFullYear();

    return (
      <footer className='footer margin--off' id='footer'>
        <div className='inner footer--inner'>
          <div className='row margin--off' style={{ maxWidth: '100vw' }}>
            <div className='col col--md-4 display--none display--block--md' />
            <div className='col col--md-4 text-align--center'>
              <p>
                &copy; <span className='current-year'>{currentYear}</span>{' '}
                <a href='http://www.gwii.com' className='footer--link' title='Open Gwii.com in a new tab' target='_blank' rel='noopener noreferrer'>
                  Gulf Winds. All Rights Reserved.
                </a>{' '}
              </p>
            </div>
            <div className='col col--md-4 text-align--right--md' />
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
