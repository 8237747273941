import axios from 'axios';
import isNil from 'lodash/isNil';

const axiosMsalFetch = async ({
  method = 'GET', url = '', data = {}, headers = {}, avoidInterception = false,
}) => {
  try {
    const axiosInstance = avoidInterception ? axios.create() : axios;
    const response = await axiosInstance.request({
      url, method, data, headers,
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

// keep axios syntax for simplicity
const authFetch = {
  get: (url, headers, params) => axiosMsalFetch({
    url, method: 'GET', headers, ...params,
  }),
  patch: (url, data, headers, params) => axiosMsalFetch({
    url, data, method: 'PATCH', headers, ...params,
  }),
  put: (url, data, headers, params) => axiosMsalFetch({
    url, data, method: 'PUT', headers, ...params,
  }),
  post: (url, data, headers, params) => axiosMsalFetch({
    url, data, method: 'POST', headers, ...params,
  }),
  delete: (url, data, headers, params) => axiosMsalFetch({
    url, data, method: 'DELETE', headers, ...params,
  }),
};

export default authFetch;

// we need to define a fetch for redux variables that only fetches when they don't exist
const isEmpty = (val) => isNil(val) || val?.length === 0 || Object.keys(val).length === 0;

export const reduxFetch = async (fetch, reduxValue, ...args) => {
  if (!isEmpty(reduxValue)) {
    return Promise.resolve(reduxValue);
  }
  try {
    const data = await fetch.apply(...args);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
