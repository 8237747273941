import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
// Components
import LoadingModal from 'shared/loading/LoadingModal';
import Modal from 'shared/modals/Modal';
import MaintenanceTable from 'shared/tables/MaintenanceTable';
// Fetch
import { getTodaysCutoffsByType, getTodaysEmptiesByType, getTodaysEarlyReturnsByType, getTodaysRollings } from 'src/fetch';
// Customer Access
import { VIEW_MULTI_SEARCH } from '../../../shared/constants/CustomerAccess';

const ExportModal = ({ isModalShowing, handleCloseModal, category, type, name, legacyAccount, organization, currentCustomer, customerAccess }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  const hasMultiSearch = customerAccess && customerAccess[VIEW_MULTI_SEARCH];

  const loadData = async () => {
    setLoading(true);

    try {
      let response;
      switch (category) {
        case 'cutoffs':
          response = await getTodaysCutoffsByType({
            LegacyAccounts: legacyAccount,
            Organizations: organization,
            Type: type,
            EmailAddress: currentCustomer.value
          });
          break;
        case 'empties':
          response = await getTodaysEmptiesByType({
            LegacyAccounts: legacyAccount,
            Organizations: organization,
            Type: type,
            EmailAddress: currentCustomer.value
          });
          break;
        case 'earlyReturns':
          response = await getTodaysEarlyReturnsByType({
            LegacyAccounts: legacyAccount,
            Organizations: organization,
            Type: type,
            EmailAddress: currentCustomer.value
          });
          break;
        case 'rollings':
          response = await getTodaysRollings({
            LegacyAccounts: legacyAccount,
            Organizations: organization,
            EmailAddress: currentCustomer.value
          });
          break;
      }
      setData(response);
      setLoading(false);
    } catch (apiError) {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const columns = [
    {
      Header: 'Pro #',
      accessor: 'proNumber',
      isCentered: true,
      width: 115,
      id: 'proNumber',
      Cell: ({ original }) => (
        hasMultiSearch ? (
          <Link className='link' to={`/search?type=0&query=${original.proNumber}`} target='_blank'>
            {original.proNumber}
          </Link>
        ) : (
          <Link className='link' to={`/search?pro=${original.proNumber}`} target='_blank'>
            {original.proNumber}
          </Link>
        )
      ),
    },
    { Header: 'Equip #', accessor: 'equipmentNumber', isCentered: true, width: 155 },
    { Header: 'BOL/BKG/Ref', accessor: 'primaryReferenceNumber', isCentered: true, width: 155 },
    { Header: 'PO #', accessor: 'poNumber', isCentered: true, width: 155 },
    { Header: 'Move Type', accessor: 'moveType', isCentered: true, width: 155 },
    { Header: 'Pickup', accessor: 'pickup', isCentered: true },
    { Header: 'Delivery', accessor: 'delivery', isCentered: true }
  ];

  const buildCSVHeaders = () => {
    return columns.map(col => {
      return { label: col.Header, key: col.accessor };
    });
  };

  return (
    <Modal
      isOpen={isModalShowing}
      handleClose={() => handleCloseModal()}
      containerStyle='large'
      className='export-modal'
    >
      <div className='modal-header'>
        <h5 className='modal-title' id='progress-modal'>
          {name}
        </h5>
        <button className='button button--large button--close' onClick={() => handleCloseModal()} />
      </div>
      <LoadingModal isOpen={loading} />
      <section className='modal-body'>
        {!error && !loading && (
            <MaintenanceTable
                className='export-table table--primary-color'
                data={data}
                apiError={error}
                isLoading={loading}
                columns={columns}
                tableOpts={{
                    showPagination: data && data.length > 0,
                    showPaginationBottom: true,
                    showPageSizeOptions: true,
                    pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
                    defaultPageSize: 15,
                    pageSize: data && data.length ? undefined : 0,
                }}
            />
        )}

        {error && !loading && <div>There was an error loading the data. Please try again.</div>}
      </section>
      <section className='modal-footer toolbar toolbar--xxs'>
        <CSVLink
          id='export-details'
          className='toolbar--button--margin button button--large button--primary-color text-align--center'
          headers={buildCSVHeaders()}
          data={data}
          filename={`${type}.csv`}
        >
          Export Details
        </CSVLink>
        <button
          id='close-progress-modal-button'
          type='button'
          className='toolbar--button--margin button button--large button--white text-align--center margin--none--xxs'
          onClick={() => handleCloseModal()}
        >
          Cancel
        </button>
      </section>
    </Modal>
  );
};

export default connect(
  state => ({
    customerAccess: state.user && state.user.customerAccess,
  }),
  {}
)(ExportModal);
