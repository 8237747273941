import authFetch from './fetch';
import { makeRequest } from './utils';

export const getTodaysCutoffs = ({ LegacyAccounts, Organizations, EmailAddress }) => makeRequest(
  authFetch.post('api/Exports/TodaysCutoffs', { LegacyAccounts, Organizations, EmailAddress }),
);

export const getTodaysCutoffsByType = async ({ LegacyAccounts, Organizations, EmailAddress, Type }) => makeRequest(
  authFetch.post('api/Exports/TodaysCutoffsByType', { LegacyAccounts, Organizations, EmailAddress, Type: Type }),
);

export const getTodaysEmpties = async ({ LegacyAccounts, Organizations, EmailAddress }) => makeRequest(
  authFetch.post('api/Exports/TodaysEmpties', { LegacyAccounts, Organizations, EmailAddress }),
);

export const getTodaysEmptiesByType = async ({ LegacyAccounts, Organizations, EmailAddress, Type }) => makeRequest(
  authFetch.post('api/Exports/TodaysEmptiesByType', { LegacyAccounts, Organizations, EmailAddress, Type: Type }),
);

export const getTodaysEarlyReturns = async ({ LegacyAccounts, Organizations, EmailAddress }) => makeRequest(
  authFetch.post('api/Exports/TodaysEarlyReturns', { LegacyAccounts, Organizations, EmailAddress }),
);

export const getTodaysEarlyReturnsByType = async ({ LegacyAccounts, Organizations, EmailAddress, Type }) => makeRequest(
  authFetch.post('api/Exports/TodaysEarlyReturnsByType', { LegacyAccounts, Organizations, EmailAddress, Type: Type }),
);

export const getTodaysRollingsSummary = async ({ LegacyAccounts, Organizations, EmailAddress }) => makeRequest(
  authFetch.post('api/Exports/TodaysRolling/Summary', { LegacyAccounts, Organizations, EmailAddress }),
);

export const getTodaysRollings = async ({ LegacyAccounts, Organizations, EmailAddress }) => makeRequest(
  authFetch.post('api/Exports/TodaysRolling', { LegacyAccounts, Organizations, EmailAddress }),
);

export const getTodaysCutoffTypes = async () => makeRequest(
  authFetch.get('api/Exports/TodaysCutoffTypes'),
);

export const getTodaysEmptiesTypes = async () => makeRequest(
  authFetch.get('api/Exports/TodaysEmptiesTypes'),
);

export const getTodaysEarlyReturnsTypes = async () => makeRequest(
  authFetch.get('api/Exports/TodaysEarlyReturnsTypes'),
);
