import authFetch from './fetch';

export const getCustomers = async () => {
  try {
    const { data } = await authFetch.get('/api/Customers');
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getCustomerAccounts = async email => {
  try {
    const { data } = await authFetch.get(`/api/Customers/Accounts?emailAddress=${email}`);
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getLegacyAccountTypes = async () => {
  try {
    const { data } = await authFetch.get('/api/Customers/AccountTypes');
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};
