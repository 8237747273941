import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ icon, className, position, onClick, title, ...props }) => {
  return (
    icon && (
      <span
        title={title}
        onClick={onClick}
        className={`icon icon-${icon} icon--position--${position ? position : 'left'} ${className ? className : ''}`}
        {...props}
      />
    )
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired
};

export default Icon;
