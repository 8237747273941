import React from 'react';

const Body = ({ children }) => {
  return (
    <div className='content wrapper--inner react-wrapper--inner margin--off'>
      <main className='main' id='main'>
        <div className='main--inner margin--off'>
          <div className='main--content'>
            <div className='body-container'>{children}</div>
          </div>
        </div>
      </main>
      <div className='overlay' id='overlay' />
    </div>
  );
};

export default Body;
