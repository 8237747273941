import React from 'react';
import cn from 'classnames';
import { Chip } from '@progress/kendo-react-buttons';

const BaseChip = ({ onRemove, className, ...props }) => {
  const chipClassName = cn('base-chip', { [className]: className });
  return (
    <Chip
      removable={onRemove}
      onRemove={onRemove}
      className={chipClassName}
      {...props}
    />
  );
};

export default BaseChip;
