import * as React from 'react';
import PropTypes from 'prop-types';

const Header = ({ leftChildren, rightChildren }) => (
  <div className='header' id='header'>
    <div className='header-inner margin--off'>
      <div className='headers' id='headers'>
        <div className='headers--inner margin--off'>
          <div className='header--top'>
            <div className='header--top--inner inner margin--off'>
              <div className='row row--small row--middle margin--off row--no-wrap flex-1--auto'>
                <div className='col text-align--left'>{leftChildren}</div>
                <div className='col text-align--right'>{rightChildren}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Header.propTypes = {
  leftChildren: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  rightChildren: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default Header;
