import authFetch from './fetch';
import { makeRequest } from './utils';

export const sendOrderComment = (proNumber, comment) => makeRequest(
  authFetch.patch(`/api/Orders/${proNumber}/comment`, { comment }),
);

export const getOrderVolume = async ({ LegacyAccounts, Organizations, EmailAddress }) => {
  try {
    const { data } = await authFetch.post('/api/Orders/Volume', { LegacyAccounts, Organizations, EmailAddress });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const searchOrders = async (LegacyAccounts, Organizations, EmailAddress, ProNumber, ContainerNumber) => {
  try {
    const { data } = await authFetch.post('api/Orders/Search', {
      LegacyAccounts,
      Organizations,
      EmailAddress,
      proNumber: ProNumber,
      containerNumber: ContainerNumber
    });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getMultiSearchCategories = async () => {
  try {
    const { data } = await authFetch.get('/api/Orders/MultiSearchCategories');
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const multiSearchOrders = async (LegacyAccounts, Organizations, EmailAddress, SearchTerms, Category) => {
  try {
    const { data } = await authFetch.post('api/Orders/MultiSearch', {
      LegacyAccounts,
      Organizations,
      EmailAddress,
      values: SearchTerms,
      Category: Category
    });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const validateEquipmentNumber = async equipmentNumber => {
  try {
    const { data } = await authFetch.post(`api/Orders/ValidateEquipmentNumber?equipmentNumber=${equipmentNumber}`);
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getOrganizations = async () => {
  try {
    const { data } = await authFetch.get('/api/Organizations');
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const setOrderHot = async (proNumber, isHot) => {
  try {
    const { data } = await authFetch.post('api/Orders/SetOrderHot', { proNumber: proNumber, isHot: isHot });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const setDcInfo = async (proNumber, dcIn, dcOut, dcYardLocation) => {
  try {
    const { data } = await authFetch.post('api/Orders/SetDcInfo', {
      proNumber: proNumber,
      dcIn: dcIn,
      dcOut: dcOut,
      dcYardLocation: dcYardLocation
    });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getDocumentList = async proNumber => {
  try {
    const { data } = await authFetch.get(`api/Orders/DocumentList?ProNumber=${proNumber}`);
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getDocument = async (proNumber, id) => {
  try {
    const { data } = await authFetch.get(`api/Orders/Document?ProNumber=${proNumber}&DocumentId=${id}`);
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};
