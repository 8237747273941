import format from 'date-fns/format';
import getDay from 'date-fns/getDay';

export const getTimeInputFormat = val => {
  if (val === null || val === undefined) {
    return val;
  }

  return format(new Date(val), 'HH:mm');
};

export const getDateInputFormat = val => {
  if (val === null || val === undefined) {
    return val;
  }

  return format(new Date(val), 'yyyy-MM-dd');
};

export const getDateTimeInputFormat = val => {
  if (val === null || val === undefined) {
    return val;
  }

  return format(new Date(val), 'yyyy-MM-ddTHH:mm');
};

export const getUTCDateTime = val => {
  if (val === null || val === undefined) {
    return val;
  }

  return new Date(val).toISOString();
};

export const getClientDateTime = (val, ignoreSeconds) => {
  if (val === null || val === undefined) {
    return val;
  }

  const formatString = `MM/dd/yyyy h:mm${ignoreSeconds ? '' : ':ss'} A`;

  if (typeof val === 'string' && val[val.length - 1].toLowerCase() !== 'z') {
    return format(new Date(val + 'Z').toLocaleString('en-US', { timeZone: 'America/Chicago' }), formatString);
  }

  return format(new Date(val).toLocaleString(), formatString);
};

export const getDayLetter = date => {
  const number = getDay(date);
  const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  return days[number];
};
