import authFetch from './fetch';

export const getInventoryActivityReport = async (startDate, endDate, customerCode, warehouse, activityType) => {
  try {
    const apiUrl = '/api/CustomerReport/InventoryActivity';
    const requestBody = {
      startDate,
      endDate,
      customerCode,
      warehouse,
      activityType,
    };
    const { data } = await authFetch.post(apiUrl, requestBody);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOnhandReport = async (startDate, reportType, customerId, customerType, warehouse, showUnarrived, showUnshipped) => {
  try {
    const apiUrl = '/api/CustomerReport/OnHand';
    const requestBody = {
      startDate,
      reportType,
      customerId,
      customerType,
      warehouse,
      showUnarrived,
      showUnshipped,
    };
    const { data } = await authFetch.post(apiUrl, requestBody);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getProductStatusReport = async (customerId, customerType, warehouse) => {
  try {
    const apiUrl = '/api/CustomerReport/ProductStatus';
    const requestBody = {
      customerId,
      customerType,
      warehouse,
    };
    const { data } = await authFetch.post(apiUrl, requestBody);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAvgInventoryReport = async (startDate, endDate, customerId, customerType, warehouse) => {
  try {
    const apiUrl = '/api/CustomerReport/AvgInventory';
    const requestBody = {
      startDate,
      endDate,
      customerId,
      customerType,
      warehouse,
    };
    const { data } = await authFetch.post(apiUrl, requestBody);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
