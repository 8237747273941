/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import Media from 'react-media';
import { Helmet } from 'react-helmet';
// Components
import DashboardHeader from 'shared/toolbars/DashboardHeader';
import LoadingSpinner from 'shared/loading/LoadingSpinner';
import Modal from 'shared/modals/Modal';
import Icon from 'shared/icons/IconComponent';
import Select from 'shared/inputs/Select';
import Report from './Report';
import {
  CommentSection,
  OrderNumbers,
  OrderProgress,
  OrderManagement,
  TrackingDates,
  OrderDetails,
  OrderFiles,
} from '../detail-sections';

import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
// Fetch
import { searchOrders, multiSearchOrders, getMultiSearchCategories } from 'src/fetch';
// Customer Access
import { VIEW_DOCUMENTS } from 'shared/../constants/CustomerAccess';
import WarehouseDetails from '../warehouse-tab/WarehouseDetails';
import InventoryUnitGrid from '../warehouse-tab/InventoryUnitGrid';

const Search = ({ location, customerAccess, history }) => {
  // Tab
  const [selectedTab, setSelectedTab] = useState(0);

  const onSelectTab = ({ selected: newSelected }) => {
    setSelectedTab(newSelected);
  };

  // Page Data
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [search, setSearch] = useState(params.query && params.query.length ? params.query : '');

  const [data, setData] = useState(null);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [apiError, setApiError] = useState(false);

  // sidebar/modal settings
  const [modalShowing, setModalShowing] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [status, setStatus] = useState(params?.status == 1 ?'print':'search');
  const [selectedRow, setSelectedRow] = useState();

  const [searchTypeOptions, setSearchTypeOptions] = useState([]);
  const [searchType, setSearchType] = useState({ label: 'Pro Number', value: 0 });
  const REPORT_TYPE_OPTIONS = [
    {
      description: 'Inventory Activity',
      FromDate: '',
      ToDate: '',
      StartDate: '',
      ReportType: 'detail',
      Warehouse: 'All',
      Customer: '',
      CustomerType: '',
      Activity: 'all',
      ShowUnarrived: false,
      ShowUnshipped: false,
      value: 0,
    },
    {
      description: 'On Hand',
      FromDate: '',
      ToDate: '',
      StartDate: '',
      Warehouse: 'All',
      Customer: '',
      CustomerType: '',
      Activity: 'all',
      ShowUnarrived: false,
      ShowUnshipped: false,
      value: 1,
    },
    {
      description: 'Product Status',
      FromDate: '2023-11-01',
      ToDate: '2023-11-22',
      Warehouse: 'All',
      Customer: '',
      Activity: 'all',
      value: 2,
    },
    {
      description: 'Avg Inventory',
      FromDate: '2023-11-01',
      ToDate: '2023-11-23',
      Warehouse: 'All',
      Customer: '',
      Activity: 'all',
      value: 3,
    },
  ];
  const [ReportTypeOptions, setReportTypeOptions] = useState(REPORT_TYPE_OPTIONS)
  const [isLoading, setIsLoading] = useState(true);
  const loadOptions = async () => {
    try {
      const optionsResponse = await getMultiSearchCategories();
      setSearchTypeOptions(optionsResponse);
      setIsLoading(false);

      if (search && search.length && searchType) {
        await handleSearch();
      }
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadOptions();
  }, []);

  const handleSearch = async () => {
    setResults([]);
    setLoading(true);
    setData(null);
    setSelectedRow(null);

    const mappedSearchValues = search.indexOf(' ') > -1 || search.indexOf(',') > -1
      ? search.split(/[ ,]+/)
      : [search];

    try {
      // get all results
      const response = await multiSearchOrders(null, null, null, mappedSearchValues, searchType.value);
      setResults(response);
      // load first result details
      const singleProResponse = await searchOrders(null, null, null, response[0].proNumber, null);
      setData(singleProResponse);

      setSelectedRow(0);
      setSelectedTab(0);
    } catch (error) {
      setHasSearched(true);
      setSelectedRow(null);
      setApiError(error);
      setData(null);
    }

    setHasSearched(true);
    setLoading(false);
    window.history.pushState('', '', '/search');
  };

  const handleSingleSearch = async (pro, isLarge) => {
    if (!isLarge) {
      setIsCollapsed(true);
      setModalShowing(false);
    }
    setSelectedTab(0);
    setLoading(true);
    try {
      const response = await searchOrders(null, null, null, pro, null);
      setData(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setApiError(true);
    }
  };

  const mobileColumns = [
    {
      Header: 'Pro #', accessor: 'proNumber', isCentered: true, width: 90,
    },
    {
      Header: 'Equip #', accessor: 'containerNumber', width: 110, Cell: ({ original }) => <div style={{ textAlign: 'left', marginTop: 0 }}>{original.containerNumber}</div>,
    },
    { Header: 'BOL/BKG/Ref', accessor: 'primaryReferenceNumber', Cell: ({ original }) => <div style={{ textAlign: 'left', marginTop: 0 }}>{original.primaryReferenceNumber}</div> },
    { Header: 'PO #', accessor: 'poNumber', Cell: ({ original }) => <div style={{ textAlign: 'left', marginTop: 0 }}>{original.poNumber}</div> },
  ];
    
  const [reportType, setReportType] = useState(ReportTypeOptions[0]?.value);

  const handleToggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
    setModalShowing(!modalShowing);
  };
  const handleClickSearch = () => {
    setIsCollapsed(!isCollapsed);
    setStatus('search');
    history.push('search');
  }
  const handleClickPrint = () => {
    setIsCollapsed(!isCollapsed);
    setStatus('print');
  }

  return (
    <div className='search search-page'>
      <Helmet title='Order Search | gwiTrack | Gulf Winds' />
      <DashboardHeader
        customerAccess={customerAccess}
        resultCount={results.length}
        onToggle={() => handleToggleSidebar()}
        isOpen={!isCollapsed}
      />
      <div className='page-content'>
        <Media queries={{ small: '(max-width: 699px)', medium: '(min-width: 700px) and (max-width: 1199px)', large: '(min-width: 1200px)' }}>
          {(matches) => (
            <>
              {matches.small && (
                <Modal
                  isOpen={modalShowing}
                  handleClose={() => handleToggleSidebar()}
                  containerStyle='large'
                  className='search-page-modal'
                >
                  <div className='modal-header'>
                    <h5 className='modal-title' id='search-modal'>
                      Search
                    </h5>
                    <button className='button button--large button--close' onClick={() => handleToggleSidebar()} />
                  </div>
                  <section className='modal-body'>
                    <div className='search-inputs'>
                      <Select
                        className='select search-type'
                        options={searchTypeOptions.map((t) => ({ label: t.description, value: t.value }))}
                        value={searchType}
                        onChange={(selected) => {
                          setSearchType(selected);
                        }}
                        isLoading={isLoading}
                        isDisabled={isLoading}
                      />
                      <div className='search-input-wrapper'>
                        <input
                          className='search-input'
                          placeholder='Search for Container, PRO, BKG, BOL or PO'
                          id='modal-search'
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          onKeyUp={(e) => {
                            if ((e.which === 13 || e.keyCode === 13) && search.length) {
                              handleSearch();
                            }
                          }}
                        />
                        <Icon
                          onClick={() => {
                            if (search.length) {
                              handleSearch();
                            }
                          }}
                          icon='search'
                          className='search-icon'
                        />
                      </div>
                    </div>
                    {hasSearched && !loading && (
                      <div className='results-count'>
                        {results.length}
                        {' '}
                        Results
                      </div>
                    )}
                    {hasSearched && (
                      <div className={`results-container ${isCollapsed ? 'collapsed' : 'full-width'}`}>
                        {results.length
                          ? results.map((r, i) => (
                            <div
                              key={`${r.proNumber}_${i}`}
                              className={`result ${selectedRow === i ? 'selected' : ''}`}
                              onClick={() => {
                                if (selectedRow !== i) {
                                  setSelectedRow(i);
                                  handleSingleSearch(r.proNumber, matches.large);
                                }
                              }}
                            >
                              <div className='result-column pro'>
                                <div className={`result-label ${selectedRow === i ? 'selected' : ''}`}>PRO#</div>
                                <div className={`result-value ${selectedRow === i ? 'selected' : ''}`}>{r.proNumber}</div>
                              </div>
                              <div className='result-column equip'>
                                <div className={`result-label ${selectedRow === i ? 'selected' : ''}`}>EQUIP#</div>
                                <div className={`result-value ${selectedRow === i ? 'selected' : ''}`}>{r.containerNumber}</div>
                              </div>
                              <div className='result-column'>
                                <div className={`result-label ${selectedRow === i ? 'selected' : ''}`}>REF#</div>
                                <div className={`result-value ${selectedRow === i ? 'selected' : ''}`}>{r.primaryReferenceNumber}</div>
                              </div>
                            </div>
                          )) : null}
                      </div>
                    )}
                  </section>
                  <section className='modal-footer toolbar toolbar--xxs'>
                    <button
                      id='close-search-modal-button'
                      type='button'
                      className='toolbar--button--margin button button--large button--white text-align--center'
                      onClick={() => handleToggleSidebar()}
                    >
                      Cancel
                    </button>
                    <button
                      id='search-modal-button'
                      type='button'
                      className='toolbar--button--margin button button--large button--primary-color text-align--center margin--none--xxs'
                      onClick={() => handleSearch()}
                    >
                      Submit
                    </button>
                  </section>
                </Modal>
              )}
              {(matches.medium || matches.large) && (
                <div className={`${matches.medium ? 'medium-sidebar' : 'large-sidebar'} ${isCollapsed ? 'collapsed' : 'full-width'}`}>
                  <div
                    className={`toggle-button ${isCollapsed ? 'collapsed' : 'full-width'}`}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                  >
                    <Icon
                      icon='angle-right'
                      className={`icon--font-medium caret-icon ${isCollapsed ? '' : 'rotated'}`}
                      position='none'
                    />

                  </div>

                  <div
                    className={`toggle-button ${isCollapsed ? 'search' : 'hidden'}`}
                    onClick={() => handleClickSearch()}
                  >
                    <Icon
                      icon='search'
                      className={`icon--font-medium caret-icon`}
                      position='none'
                    />

                  </div>    
                  <div
                    className={`toggle-button ${isCollapsed ? 'print' : 'hidden'}`}
                    onClick={() => handleClickPrint()}
                  >
                    <Icon
                      icon='print'
                      className={`icon--font-medium caret-icon`}
                      position='none'
                    />

                  </div>  
                   
                  <div className={`medium-panel ${isCollapsed ? 'collapsed' : 'full-width'}`}>
                    {status == "search" &&
                            (<>
                              <div className='search-header'>Search</div>
                              <div className='search-inputs'>
                                <Select
                                  className='select search-type'
                                  options={searchTypeOptions.map((t) => ({ label: t.description, value: t.value }))}
                                  value={searchType}
                                  onChange={(selected) => {
                                    setSearchType(selected);
                                  }}
                                  isLoading={isLoading}
                                  isDisabled={isLoading}
                                />
                                <div className='search-input-wrapper'>
                                  <input
                                    className='search-input'
                                    placeholder='Search for Container, PRO, BKG, BOL or PO'
                                    id='search'
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onKeyUp={(e) => {
                                      if ((e.which === 13 || e.keyCode === 13) && search.length) {
                                        handleSearch();
                                      }
                                    }}
                                  />
                                  <Icon
                                    onClick={() => {
                                      if (search.length) {
                                        handleSearch();
                                      }
                                    }}
                                    icon='search'
                                    className='search-icon'
                                  />
                                </div>
                              </div>
                              <div className='divider' />
                              <div className='results-count'>
                                {(!hasSearched || loading) && null}
                                {loading && 'Loading...'}
                                {(hasSearched && !loading) && (results.length === 1 ? `${results.length} Result` : `${results.length} Results`)}
                              </div>
                            </>
                            )
                    }
                    {status == "print" &&
                            (<>
                              <div className='search-header'>Reports</div>
                              <div className='search-inputs'>
                                <Select
                                  className='select search-type'
                                  options={REPORT_TYPE_OPTIONS.map((t) => ({ label: t.description, value: t.value }))}
                                  value={reportType}
                                  onChange={(selected) => {
                                    setReportType(selected.value);
                                  }}
                                  isLoading={isLoading}
                                  isDisabled={isLoading}
                                />
                                    
                              </div>
                            </>
                            )}
                  </div>
                  {status == "search" && hasSearched && (
                    <div className={`results-container ${isCollapsed ? 'collapsed' : 'full-width'}`}>
                      {results.length
                        ? results.map((r, i) => (
                          <div
                            key={`${r.proNumber}_${i}`}
                            className={`result ${selectedRow === i ? 'selected' : ''}`}
                            onClick={() => {
                              if (selectedRow !== i) {
                                setSelectedRow(i);
                                handleSingleSearch(r.proNumber, matches.large);
                              }
                            }}
                          >
                            <div className='result-column pro'>
                              <div className={`result-label ${selectedRow === i ? 'selected' : ''}`}>PRO#</div>
                              <div className={`result-value ${selectedRow === i ? 'selected' : ''}`}>{r.proNumber}</div>
                            </div>
                            <div className='result-column equip'>
                              <div className={`result-label ${selectedRow === i ? 'selected' : ''}`}>EQUIP#</div>
                              <div className={`result-value ${selectedRow === i ? 'selected' : ''}`}>{r.containerNumber}</div>
                            </div>
                            <div className='result-column'>
                              <div className={`result-label ${selectedRow === i ? 'selected' : ''}`}>REF#</div>
                              <div className={`result-value ${selectedRow === i ? 'selected' : ''}`}>{r.primaryReferenceNumber}</div>
                            </div>
                          </div>
                        )) : null}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </Media>
        {status == "search" && (
          <div className='search-data'>
            <>
              {loading ? <LoadingSpinner /> : (
                hasSearched ? (
                  data ? (
                    <>
                      <TabStrip selected={selectedTab} onSelect={onSelectTab}>
                        <TabStripTab key='general-information' title='General Information'>
                          <div className='top-row'>
                            <OrderNumbers data={data} />
                            <OrderProgress data={data} />
                            <OrderManagement data={data} />
                          </div>
                          <div className='middle-row'>
                            <TrackingDates data={data} />
                            <OrderDetails data={data} />
                          </div>
                          {customerAccess && customerAccess[VIEW_DOCUMENTS] && (
                            <div className='bottom-row'>
                              <OrderFiles proNumber={data.proNumber} />
                            </div>
                          )}
                          {data.canComment && (
                            <div className='bottom-row'>
                              <CommentSection initialValue={data.comments} proNumber={data.proNumber} />
                            </div>
                          )}
                        </TabStripTab>
                        {data.hasWarehouseDetails && (
                          <TabStripTab key='warehouses' title='Warehouse Information'>
                            <div className='top-row'>
                              <WarehouseDetails order={data} />
                            </div>
                            <div className='middle-row'>
                              <InventoryUnitGrid
                                proNumber={data.proNumber}
                                moveType={data.moveType}
                              />
                            </div>
                          </TabStripTab>
                        )}
                      </TabStrip>
                    </>
                  ) : (
                    <div className='no-result-container'>
                      <div className='no-result'>
                        <div className='no-result-title'>No Results Found</div>
                        <div className='no-result-text'>Please try a new search query</div>
                      </div>
                    </div>
                  )
                ) : (
                  <div className='no-result-container'>
                    <div className='no-result'>
                      <div className='no-result-title'>Order Search</div>
                      <div className='no-result-text'>Please enter a search query to get started</div>
                    </div>
                  </div>
                )
              )}

            </>
          </div>
        )}
        {status == "print" && (
          <Report reportType={reportType} setReportTypeOptions={setReportTypeOptions} ReportTypeOptions={ReportTypeOptions} />
        )}

      </div>
    </div>
  );
};

export default withRouter(connect(
  (state) => ({
    customerAccess: state.user && state.user.customerAccess,
    customerLoading: state.user && state.user.isLoading,
  }),
  {},
)(Search));
