import React, { useState, useEffect, useMemo } from 'react';
import { isNil, sortBy } from 'lodash';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components
import Select from 'shared/inputs/Select';
import toastr from 'shared/helpers/toastr';
import Icon from 'shared/icons/IconComponent';
// Fetch
import { getCustomerAccounts } from 'src/fetch';
// Customer Access
import { SELECT_USER } from 'shared/../constants/CustomerAccess';

const defaultOption = { label: 'All Accounts', value: null, type: null };
const defaultCustomerOption = { label: 'Select Customer Account', value: null };

const DashboardHeader = ({
  showSwitcher = false,
  customerAccess,
  setCurrentCustomer,
  setLegacyAccount,
  setOrganization,
  setHasLoadedAccounts,
  setEmptyAccounts,
  loadChartData,
  onToggle,
  isOpen,
  initialCustomer,
}) => {
  // state
  const [selected, setSelected] = useState({ ...defaultOption });
  const [legacyAccounts, setLegacyAccounts] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [customerAndAccountStateUpdated, setCustomerAndAccountStateUpdated] = useState(false);

  // customers
  const customerAccounts = useSelector(({ customers }) => {
    const data = customers
      .filter(({ deleted }) => !deleted)
      .map(({ firstName, lastName, email }) => ({ label: `${firstName} ${lastName} (${email})`, value: email }));
    return [defaultCustomerOption, ...data];
  });

  const load = async () => {
    setIsLoading(true);
    setHasLoadedAccounts(false);
    try {
      const accountsResponse = await getCustomerAccounts();

      if (!accountsResponse.legacyAccounts.length && !accountsResponse.organizations.length) {
        setEmptyAccounts(true);
      }

      if (customerAccess && customerAccess[SELECT_USER]) {
        if (initialCustomer) {
          setSelectedCustomer(
            customerAccounts.find((c) => c.value === initialCustomer.value),
          );
        }
      }

      if (!initialCustomer) {
        setSelectedCustomer(defaultCustomerOption);
        setLegacyAccounts(accountsResponse.legacyAccounts);
        setOrganizations(accountsResponse.organizations);
      }

      if (setHasLoadedAccounts) {
        setHasLoadedAccounts(true);
      }

      setIsLoading(!isNil(initialCustomer));
    } catch (error) {
      toastr('error', 'Could not get accounts. Refresh to try again.');
      setIsLoading(false);
    }
  };

  // create the options when we have both legacyAccounts
  const options = useMemo(() => {
    if (legacyAccounts || organizations) {
      if (legacyAccounts.length && organizations.length) {
        const combined = [
          ...legacyAccounts.map((x) => ({ label: x.name, value: [x], type: 'legacyAccount' })),
          ...organizations.map((x) => ({ label: x.organizationName, value: [x], type: 'organization' })),
        ];
        setSelected({ ...defaultOption });
        return [defaultOption, ...sortBy(combined, ['label'])];
      }
      if (legacyAccounts.length > 1 && !organizations.length) {
        setSelected({ ...defaultOption });
        return [
          defaultOption,
          ...sortBy(
            legacyAccounts.map((x) => ({ label: x.name, value: [x], type: 'legacyAccount' })),
            ['label'],
          ),
        ];
      }
      if (!legacyAccounts.length && organizations.length > 1) {
        setSelected({ ...defaultOption });
        return [
          defaultOption,
          ...sortBy(
            organizations.map((x) => ({ label: x.organizationName, value: [x], type: 'organization' })),
            ['label'],
          ),
        ];
      }
      if (legacyAccounts.length === 1 && !organizations.length) {
        setSelected({ label: legacyAccounts[0].name, value: [legacyAccounts[0]], type: 'legacyAccount' });
      } else if (organizations.length === 1 && !legacyAccounts.length) {
        setSelected({ label: organizations[0].organizationName, value: [organizations[0]], type: 'organization' });
      } else {
        setSelected({ ...defaultOption });
      }
    }
    return [];
  }, [legacyAccounts, organizations]);

  // this handles the accounts dropdown on customer change
  const handleChangeCustomer = async (selectedCustomer) => {
    setIsLoading(true);
    if (showSwitcher) {
      setHasLoadedAccounts(false);
    }
    try {
      const response = await getCustomerAccounts(selectedCustomer.value);
      setLegacyAccounts(response.legacyAccounts);
      setOrganizations(response.organizations);
      if (response.legacyAccounts.length || response.organizations.length) {
        setEmptyAccounts(false);
      }
      setIsLoading(false);

      if (showSwitcher) {
        setHasLoadedAccounts(true);
      }
    } catch (accountsError) {
      console.log('accountsError', accountsError);
      setIsLoading(false);
      toastr('error', 'Could not assume customer account. Please refresh to try again.');
    }
  };

  // this looks at the current account value and sets legacy accounts
  // or organizations based on the current account value.
  const handleSetCustomerAccounts = (currentAccount) => {
    if (currentAccount.value) {
      if (currentAccount.type === 'legacyAccount') {
        setLegacyAccount(currentAccount.value);
        setOrganization(null);
      } else if (currentAccount.type === 'organization') {
        setOrganization(currentAccount.value);
        setLegacyAccount(null);
      }
    } else {
      setLegacyAccount(null);
      setOrganization(null);
    }
  };

  // This loads the data for the charts.
  // customer and account state must be explictly set as updated
  // so that the right values are in place.
  useEffect(() => {
    if (customerAndAccountStateUpdated === true) {
      loadChartData();
      setCustomerAndAccountStateUpdated(false);
    }
  }, [customerAndAccountStateUpdated]);

  useEffect(() => {
    if (showSwitcher) {
      load();
    }
  }, []);

  useEffect(() => {
    if (!isNil(selectedCustomer)) {
      setIsLoading(true);
      setSelected({ ...defaultOption });
      setOrganization(null);
      setLegacyAccount(null);
      setCurrentCustomer(selectedCustomer);
      handleChangeCustomer(selectedCustomer);
      setCustomerAndAccountStateUpdated(true);
    }
  }, [selectedCustomer]);

  return (
    <div className={`dashboard-header ${showSwitcher ? 'hasSwitcher' : ''}`}>
      <div className='search-button-container'>
        <button
          type='button'
          className='dashboard-search-toggle button--large search-button-link text-align--center'
          onClick={() => {
            onToggle();
          }}
        >
          <Icon icon={isOpen ? 'times' : 'Menu-Icon'} className='search-icon' />
        </button>
      </div>
      <div className='filters-container'>
        <div className='switchers-container'>
          {showSwitcher && customerAccess[SELECT_USER] && (
            <div className='customer-filter-container'>
              <Select
                className='customer-select'
                options={customerAccounts}
                value={selectedCustomer}
                onChange={(selected) => {
                  setIsLoading(true);
                  setSelectedCustomer(selected);
                }}
                isLoading={isLoading}
                isDisabled={isLoading}
              />
            </div>
          )}
          {showSwitcher && (
            <div className='filter-container'>
              <Select
                className='customer-account-select'
                options={options}
                value={selected}
                onChange={(selected) => {
                  setSelected(selected);
                  handleSetCustomerAccounts(selected);
                  setCustomerAndAccountStateUpdated(true);
                }}
                isLoading={isLoading}
                isDisabled={isLoading}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(DashboardHeader);
