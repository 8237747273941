import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
// Components
import LoadingModal from 'shared/loading/LoadingModal';
import Modal from 'shared/modals/Modal';
import MaintenanceTable from 'shared/tables/MaintenanceTable';
import toastr from 'shared/helpers/toastr';
import errorToastr from 'shared/helpers/errorToastr';
import ConfirmDialogue from 'shared/modals/ConfirmDialogue';
import Select from 'shared/inputs/Select';
import Icon from 'shared/icons/IconComponent';
import SearchBox from 'src/shared/components/search/ChippedSearchBox';
// Fetch
import { getDroppedContainersByType, updateDropReleases } from 'src/fetch';
// Customer Access
import { VIEW_MULTI_SEARCH } from '../../../shared/constants/CustomerAccess';
import { debug } from 'util';

const DropModal = ({
  isModalShowing,
  handleCloseModal,
  type,
  legacyAccount,
  organization,
  currentCustomer,
  customerAccess,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(0);
  const [text, setText] = useState('');

  const hasMultiSearch = customerAccess && customerAccess[VIEW_MULTI_SEARCH];

  const loadData = async () => {
    try {
      const response = await getDroppedContainersByType({
        LegacyAccounts: legacyAccount,
        Organizations: organization,
        Type: type,
        EmailAddress: currentCustomer.value,
      });
      setData(response);
      setClonedData(response);
      setLoading(false);
    } catch (apiError) {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    if (type) {
      loadData();
    }
  }, [type]);

  const toggleRow = (proNumber) => {
    const newSelected = [...selected];
    const currentIndex = newSelected?.indexOf(proNumber);

    if (currentIndex === -1) {
      newSelected.push(proNumber);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected(newSelected);
    setSelectAll(newSelected.length > 0 ? 2 : 0);
  };

  const toggleSelectAll = () => {
    let newSelected = [];

    if (selectAll === 0) {
      clonedData.forEach((x) => x.isDropReleasable && newSelected.push(x.proNumber));
    }

    setSelected(newSelected);
    setSelectAll(newSelected.length > 0 ? 2 : 0);
  };

  const releaseContainers = async () => {
    setLoading(true);
    try {
      await updateDropReleases(selected);
      toastr('success', 'Containers Released Successfully.');
      setLoading(false);
      handleCloseModal(true);
    } catch (releaseError) {
      setLoading(false);
      errorToastr({
        error: releaseError,
        messagePrefix: 'Container Release Failed!',
      });
    }
  };

  const columns = [
    {
      id: 'checkbox',
      accessor: '',
      style: { textAlign: 'center' },
      Cell: ({ original }) => {
        return original.isDropReleasable ? (
          <input
            type='checkbox'
            className='checkbox select-checkbox-table'
            checked={selected?.indexOf(original.proNumber) > -1}
            onChange={() => toggleRow(original.proNumber)}
          />
        ) : null;
      },
      Header: () => {
        return (
          <input
            type='checkbox'
            className='checkbox select-checkbox-table'
            checked={selectAll === 1}
            ref={(input) => {
              if (input) {
                input.indeterminate = selectAll === 2;
              }
            }}
            onChange={toggleSelectAll}
          />
        );
      },
      sortable: false,
      resizable: false,
      width: 35,
    },
    {
      Header: 'Pro #',
      accessor: 'proNumber',
      isCentered: true,
      width: 115,
      id: 'proNumber',
      Cell: ({ original }) =>
        hasMultiSearch ? (
          <Link className='link' to={`/search?type=0&query=${original.proNumber}`} target='_blank'>
            {original.proNumber}
          </Link>
        ) : (
          <Link className='link' to={`/search?pro=${original.proNumber}`} target='_blank'>
            {original.proNumber}
          </Link>
        ),
    },
    { Header: 'Equip #', accessor: 'equipmentNumber', isCentered: true, width: 155 },
    { Header: 'BOL/BKG/Ref', accessor: 'primaryReferenceNumber', isCentered: true, width: 155 },
    { Header: 'PO #', accessor: 'poNumber', isCentered: true, width: 155 },
    { Header: 'Move Type', accessor: 'moveType', isCentered: true, width: 155 },
    { Header: 'Pickup', accessor: 'pickup', isCentered: true },
    { Header: 'Delivery', accessor: 'delivery', isCentered: true },
  ];

  const getHeader = () => {
    switch (type) {
      case 'GWIPending':
        return 'Gulf Winds - Containers Pending Scheduling';
      case 'GWIComplete':
        return 'Gulf Winds - Containers Scheduled for Delivery';
      case 'GWITotal':
        return 'Gulf Winds - Total Containers';
      case 'CustPending':
        return 'Customer - Containers Pending Drop Release';
      case 'CustComplete':
        return 'Customer - Containers Released Pending Pickup';
      case 'CustTotal':
        return 'Customer - Total Containers';
      case 'GWIReturnPending':
        return 'Gulf Winds Dropped - Containers Pending Scheduling';
      case 'GWIReturnComplete':
        return 'Gulf Winds Dropped - Containers Scheduled for Terminate';
      case 'GWIReturnTotal':
        return 'Gulf Winds Dropped - Total Containers';
      case 'TotalPending':
        return 'All Pending Containers';
      case 'Total Complete':
        return 'All Containers Scheduled for Delivery or Pending Pickup';
      case 'Total':
        return 'All Containers';
      default:
        return '';
    }
  };

  const [confirmOpen, setConfirmOpen] = useState(null);

  const onConfirmClose = (didConfirm) => {
    if (didConfirm) {
      releaseContainers();
    }
    setConfirmOpen(false);
  };
  // search state
  const [clonedData, setClonedData] = useState([]);
  const [searchType, setSearchType] = useState('equipmentNumber');
  const [searchItems, setSearchItems] = useState([]);

  const onReset = () => {
    setClonedData(data);
    setSearchType(null);
    setSearchItems([]);
  };

  const searchTypeOptions = [
    { label: 'Equipment Number', value: 'equipmentNumber' },
    { label: 'BOL/BKG/Ref', value: 'primaryReferenceNumber' },
    { label: 'PO Number', value: 'poNumber' },
  ];

  const buildCSVHeaders = () => {
    return columns
      .filter((col) => col.accessor)
      .map((col) => {
        return { label: col.Header, key: col.accessor };
      });
  };

  const onChipAdd = (items) => {
    setText('');
    const trimmedItems = items.map((item) => item.trim());
    const toAdd = [];
    trimmedItems.forEach((item) => {
      if (!searchItems.includes(item) && item !== '') {
        toAdd.push(item);
      }
    });
    setSearchItems([...searchItems, ...toAdd]);
  };

  useEffect(() => {
    if (searchItems.length) {
      if (selectAll === 0) {
        toggleSelectAll();
      }
    } else {
      if (selectAll !== 0) {
        toggleSelectAll();
      }
    }
  }, [searchItems]);

  const handleSearch = () => {
    let results;
    // check if we have a search term
    if (searchItems.length) {
      const matches = [];
      // multiple search terms and single search type
      if (searchType) {
        searchItems.forEach((item) => {
          const found = data.filter((d) => d[searchType]?.indexOf(item) > -1);
          if (found.length) {
            matches.push(...found);
          }
        });
        results = matches;
      } else {
        // multiple search terms and no set search type
        searchItems.forEach((item) => {
          const found = data.filter(
            (d) =>
              d?.equipmentNumber?.indexOf(item) > -1 ||
              d?.primaryReferenceNumber?.indexOf(item) > -1 ||
              d?.poNumber?.indexOf(item) > -1,
          );
          if (found.length) {
            matches.push(...found);
          }
        });
        results = matches;
      }
    } else {
      // do nothing
      setClonedData(data);
      return;
    }
    // set cloned data to results
    setClonedData(results || []);
  };
  useEffect(() => {
    handleSearch();
  }, [searchType, searchItems]);

  return (
    <Modal
      isOpen={isModalShowing}
      handleClose={() => handleCloseModal()}
      containerStyle='large'
      className='dropped-containers-modal'
    >
      <div className='modal-header'>
        <h5 className='modal-title' id='drop-modal'>
          Dropped Containers
        </h5>
        <button className='button button--large button--close' onClick={() => handleCloseModal()} />
      </div>
      <LoadingModal isOpen={loading} />
      <section className='modal-body'>
        {!error && !loading && (
          <>
            <div style={{ fontSize: '1rem' }}>{getHeader()}</div>
            <MaintenanceTable
              wrapperClassName='dropped-containers-table-container'
              className='dropped-containers-table table--primary-color'
              data={clonedData}
              apiError={error}
              isLoading={loading}
              columns={columns}
              tableOpts={{
                selectedCount: selected.length,
                showPagination: data && data.length > 0,
                showPaginationBottom: true,
                showPageSizeOptions: true,
                pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
                defaultPageSize: 15,
                pageSize: data && data.length ? undefined : 0,
              }}
              headerChildren={
                <div
                  className='chipped-search-container '
                  style={{ marginTop: '1rem', display: 'flex', width: '100%' }}
                >
                  <div className='type-selector'>
                    <div className='filter-container'>
                      <label className='label'>Search By</label>
                      <Select
                        style={{ height: '44px' }}
                        closeMenuOnSelect={true}
                        className='select search-type-select'
                        value={searchType}
                        onChange={(selectedOption) => setSearchType(selectedOption.value)}
                        options={searchTypeOptions}
                        isDisabled={loading}
                      />
                    </div>
                  </div>
                  <div className='search-input-wrapper'>
                    <div className='filter-wrapper' style={{ width: '100%' }}>
                      <div className='search-container'>
                        <SearchBox
                          className='search-input'
                          placeholder='Enter search values - separated by a space or a comma'
                          id='drop-search'
                          onChipAdd={onChipAdd}
                          text={text}
                          setText={setText}
                          setItems={setSearchItems}
                          items={searchItems}
                        />
                        <Icon
                          onClick={() => {
                            handleSearch();
                          }}
                          icon='search'
                          className='search-icon'
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: '1.5rem' }}>
                    <button
                      className='button button--large button--primary-color text-align--center reset-search-button'
                      onClick={onReset}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              }
            />
          </>
        )}
        {error && !loading && <div>There was an error loading the data. Please try again.</div>}
      </section>
      <section className='modal-footer toolbar toolbar--xxs'>
        <CSVLink
          id='export-details'
          className='toolbar--button--margin button button--large button--primary-color text-align--center'
          headers={buildCSVHeaders()}
          data={clonedData}
          filename={`${type}.csv`}
        >
          Export Details
        </CSVLink>
        <button
          type='button'
          className='toolbar--button--margin button button--large button--primary-color text-align--center'
          onClick={() => setConfirmOpen(true)}
          disabled={!selected.length}
        >
          Release Selected
        </button>
        <button
          id='close-drop-modal-button'
          type='button'
          className='toolbar--button--margin button button--large button--white text-align--center margin--none--xxs'
          onClick={() => handleCloseModal()}
        >
          Cancel
        </button>
      </section>
      <ConfirmDialogue
        handleClose={onConfirmClose}
        headerText='Release Containers'
        text={`You are about to release ${selected.length} containers. Do you wish to continue?`}
        isOpen={confirmOpen}
      />
    </Modal>
  );
};

export default connect(
  (state) => ({
    customerAccess: state.user && state.user.customerAccess,
  }),
  {},
)(DropModal);
