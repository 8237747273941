import React from 'react';
import impersonationContext from './impersonationContext';
import Layout from 'shared/layout/Layout';
import LoadingSpinner from 'shared/loading/LoadingSpinner';
import useImpersonationProvider from './useImpersonationProvider';

const ImpersonationProvider = ({ children }) => {
  const value = useImpersonationProvider();
  const { deimpersonating } = value;
  return (
    <impersonationContext.Provider value={value}>
      {!deimpersonating && children}
      {deimpersonating && (
        <Layout>
          <LoadingSpinner text='Stop impersonating...' />
        </Layout>
      )}
    </impersonationContext.Provider>
  );
};

export default ImpersonationProvider;
