import { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import upperFirst from 'lodash/upperFirst';
// consts
import requestStatuses from './requestStatuses';

const defaultInitialSort = [{ field: 'id', dir: 'asc' }];

const defaultInitialPage = {
  skip: 0,
  take: 10,
};

const useGridData = (getData, searchParams, config = {}, columns, process = null, dataState = null) => {
  const mapSort = (sort) => sort?.map((s) => ({
    ...s,
    sortBy: upperFirst(columns?.find((c) => c.field === s.field)?.sortBy || s.field),
    avoidSort: columns?.find((c) => c.field === s.field)?.avoidSort,
  }));

  const { initialSort, initialPage, reloadOnSort } = config;
  const [status, setStatus] = useState(requestStatuses.INIT);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const [sort, setSort] = useState(mapSort(initialSort ?? defaultInitialSort));
  const [page, setPage] = useState(initialPage ?? defaultInitialPage);

  const onSortChange = ({ sort: newSort }) => {
    const mappedSort = mapSort(newSort);
    if (mappedSort?.length && mappedSort[0].avoidSort) {
      return;
    }

    setSort(mappedSort);
  };

  const onPageChange = ({ page }) => {
    setPage(page);
  };

  const getDataCallback = useCallback(
    debounce(async (params) => {
      setStatus(requestStatuses.PENDING);
      getData(params)
        .then(({ data, total }) => {
          const newData = process ? process(data, dataState).data : data;
          setItems(newData);
          setTotal(total || data.length);
          setStatus(requestStatuses.SUCCESS);
        })
        .catch((err) => {
          console.log('Error loading grid data', err);
          setError(err);
          setStatus(requestStatuses.FAILED);
        });
    }, 500),
    [],
  );

  const reload = () => {
    setError(null);
    const realInitialPage = initialPage ?? defaultInitialPage;
    const [{ sortBy, dir }] = mapSort(initialSort || defaultInitialSort);
    const params = {
      ...(searchParams ?? {}),
      ...(reloadOnSort
        ? {
          pageSize: realInitialPage.take,
          page: realInitialPage.skip / realInitialPage.take + 1,
          sort: sortBy,
          order: dir === 'asc' ? 1 : -1,
        }
        : {}),
    };
    getDataCallback(params);
  };

  const reloadPage = () => {
    const params = {
      ...(searchParams ?? {}),
      pageSize: page.take,
      page: page.skip / page.take + 1,
    };

    if (sort && sort.length) {
      const [{ sortBy, dir, avoidSort }] = sort;
      if (!avoidSort) {
        params.sort = sortBy;
        params.order = dir === 'asc' ? 1 : -1;
      }
    }

    getDataCallback(params);
  };

  // initial loading
  useEffect(() => {
    reload();
  }, [searchParams]);

  // reload
  useEffect(() => {
    if (status !== requestStatuses.SUCCESS || !reloadOnSort) {
      return;
    }

    reloadPage();
  }, [sort, page]);

  return {
    status,
    items,
    total,
    error,
    sort,
    onSortChange,
    page,
    onPageChange,
    setItems,
    reload,
    reloadPage,
  };
};

export default useGridData;
