import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as localForage from 'localforage';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
// components
import Table from 'shared/tables/Table';
import { Alert } from 'styled-alert-component';
import LoadingSpinner from 'shared/loading/LoadingSpinner';
// constants
import { DEFAULT_PAGE_SIZE, GWI_DEFAULT_TABLE_PAGE_SIZE } from 'shared/../constants/Globals';

const MaintenanceTable = ({
  data,
  defaultSort,
  columns,
  onRowSelect,
  isLoading,
  apiError,
  headerChildren,
  footerChildren,
  showPagination = true,
  overridePageSize,
  justifyContent,
  stickyFooter = false,
  showSelectStyle = false,
  preventDeselect = true,
  clearSelection = false,
  storedPage = 0,
  updateParentPage,
  storedSort = [],
  onSortedChange,
  noResultsText,
  wrapperClassName,
  className,
}) => {
  // need to store the defaultPageSize since we re-render the table on save from add/edit/delete
  const [defaultPageSize, setDefaultPageSize] = useState(overridePageSize || DEFAULT_PAGE_SIZE);

  // storing/retrieving/updating table size
  const [isLoadingPageSize, setIsLoadingPageSize] = useState(true);
  const getPageSize = async () => {
    try {
      const pageSize = await localForage.getItem(GWI_DEFAULT_TABLE_PAGE_SIZE);
      // if nothing in localforage default to pageSize in props, then fallback to static size
      setDefaultPageSize(overridePageSize || pageSize || DEFAULT_PAGE_SIZE);
      setIsLoadingPageSize(false);
    } catch (err) {
      // do nothing, just move on and flag the load as done
      setIsLoadingPageSize(false);
    }
  };
  const setPageSize = async pageSize => {
    try {
      await localForage.setItem(GWI_DEFAULT_TABLE_PAGE_SIZE, pageSize);
      setDefaultPageSize(pageSize);
    } catch (err) {
      // do nothing, just move on
    }
  };

  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (data.length / defaultPageSize > 1 && storedPage > 0) {
      setCurrentPage(storedPage);
    }
  }, [data]);

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
    if (updateParentPage) {
      updateParentPage(newPage);
    }
  };

  const [sort, setSort] = useState([]);

  useEffect(() => {
    getPageSize();
  }, []);

  useEffect(() => {
    // the sorted values are arrays, need to just JSON stringify them and compare
    // otherwise we will loop infinitely
    if (JSON.stringify(storedSort) !== JSON.stringify(sort)) {
      setSort(storedSort ? storedSort : defaultSort ? defaultSort : undefined);
    }
  }, [storedSort]);

  const handleSort = sort => {
    if (onSortedChange) {
      onSortedChange(sort);
    }
  };

  const wrClassName = cn('col col--xxs-12', {
    'has-fixed-toolbar': !!stickyFooter,
    [wrapperClassName]: !!wrapperClassName,
  });

  const tblClassName = cn('table--primary-color', {
    [className]: !!className,
  });

  return (
    <div className={wrClassName}>
      {headerChildren && <div className='toolbar toolbar--xxs'>{headerChildren}</div>}
      {(isLoading || isLoadingPageSize) && <LoadingSpinner />}
      {!isLoading && !isLoadingPageSize && !apiError && (
        <>
          <Table
            className={tblClassName}
            data={data}
            defaultSort={sort}
            columns={columns}
            justifyContent={justifyContent || 'flex-start'}
            textAlign='left'
            selectable={!!onRowSelect}
            onSelect={selected => onRowSelect(data[selected[0]])}
            clearSelection={clearSelection}
            noResultsText={noResultsText}
            tableOpts={{
              onSortedChange: sort => handleSort(sort),
              page: currentPage,
              onPageChange: page => handlePageChange(page),
              showPagination: showPagination && data.length !== 0,
              showPaginationBottom: true,
              pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
              onPageSizeChange: pageSize => setPageSize(pageSize),
              defaultPageSize: defaultPageSize,
              pageSize: data.length !== 0 ? defaultPageSize : 0,
              minRows: 1
            }}
            showSelectStyle={showSelectStyle}
            preventDeselect={preventDeselect}
          />
          <ReactTooltip className='gwi-tooltip' />
        </>
      )}
      {!isLoading && apiError && <Alert error>{apiError}</Alert>}
      {stickyFooter ? (
        <div className='toolbar--fixed toolbar toolbar--xxs toolbar--border toolbar--border-top toolbar--border--gray--lighter toolbar--shadow-bottom'>
          <div className='toolbar--column'>{footerChildren}</div>
        </div>
      ) : (
        <div className='toolbar toolbar--xxs'>
          <div className='toolbar--column'>{footerChildren}</div>
        </div>
      )}
    </div>
  );
};

MaintenanceTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  onRowSelect: PropTypes.func,
  justifyContent: PropTypes.string,
  pageSize: PropTypes.number,
  showSelectStyle: PropTypes.bool,
  preventDeselect: PropTypes.bool,
  clearSelection: PropTypes.bool
};

export default MaintenanceTable;
