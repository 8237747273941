/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import LoadingSpinner from 'shared/loading/LoadingSpinner';
import { getExportWarehouseInformation, getImportWarehouseInformation } from 'src/fetch/warehouseDetails';

const EXPORT_ORDER = 'EXPORT';
const OUTBOUND_ORDER = 'OUTBOUND';
const IMPORT_ORDER = 'IMPORT';

const WarehouseDetails = ({ order }) => {
  const [warehouseInfo, setWarehouseInfo] = useState({});
  const dispatch = useDispatch();
  const { moveType, proNumber } = order;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const warehouseInfoOnOrderPromise = () => ((moveType === EXPORT_ORDER || moveType === OUTBOUND_ORDER) ? getExportWarehouseInformation(proNumber)
      .then((warehouseInfoOnOrder) => setWarehouseInfo(warehouseInfoOnOrder))
      : getImportWarehouseInformation(proNumber)
        .then((warehouseInfoOnOrder) => setWarehouseInfo(warehouseInfoOnOrder)));

    Promise.all([
      warehouseInfoOnOrderPromise(),
    ]).then(() => setLoading(false));
  }, [dispatch]);

  const formatValues = (values) => {
    const retValues = {
      ...values,
      loadDate: new Date(values.loadDate),
    };
    return retValues;
  };

  const data = formatValues(warehouseInfo);

  const getStringValue = (value) => (value && value.length > 0 ? value : 'N/A');

  return loading ? <LoadingSpinner /> : (
    <>
      <div className='detail-section order-details warehouse-details'>
        <div className='title-container'>
          <div className='title'>Warehouse Details</div>
          <div className='title-bar' />
        </div>
        <div className='data-container'>
          <div className='data-section'>
            <div className='section-info'>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Qty.</label>
                </div>
                <div className='info-data'>{data.quantity ?? 'N/A'}</div>
              </div>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Seal No.</label>
                </div>
                <div className='info-data'>{getStringValue(data.sealNumber)}</div>
              </div>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Cargo Lbs / Kgs</label>
                </div>
                <div className='info-data'>{data.weight ?? 'N/A'}</div>
              </div>
            </div>
          </div>
          <div className='data-section'>
            <div className='section-info'>
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>
                    {moveType === EXPORT_ORDER || moveType === OUTBOUND_ORDER ? 'BL Hold' : 'MR Hold'}
                  </label>
                </div>
                <div className='info-data'>{getStringValue(data.holdReason)}</div>
              </div>
              {(moveType === EXPORT_ORDER || moveType === OUTBOUND_ORDER) && (
                <div className='info-row'>
                  <div className='info-label'>
                    <label className='label'>BL Comments</label>
                  </div>
                  <div className='info-data'>{getStringValue(data.comments)}</div>
                </div>
              )}
              {moveType === IMPORT_ORDER && (
                <div className='info-row'>
                  <div className='info-label'>
                    <label className='label'>Outbound Mode</label>
                  </div>
                  <div className='info-data'>{getStringValue(data.outboundMode)}</div>
                </div>
              )}
              <div className='info-row'>
                <div className='info-label'>
                  <label className='label'>Third Party System</label>
                </div>
                <div className='info-data'>{data.thirdpartysystem ?? 'N/A'}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WarehouseDetails;