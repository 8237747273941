import * as React from 'react';
import LoggedInAsDropdown from 'shared/dropdowns/LoggedInAsDropdownComponent';

class MobileNavMenu extends React.Component {
  render() {
    return (
      <nav className='menu menu--right margin--off mobile-menu'>
        <div className='button-group'>
          <LoggedInAsDropdown />
        </div>
      </nav>
    );
  }
}

export default MobileNavMenu;
