import { useState } from 'react';
import isNil from 'lodash/isNil';

const useImpersonationProvider = () => {
  const sessionStorageVal = localStorage.getItem('impersonate');
  const isImpersonated = !isNil(sessionStorageVal) && sessionStorageVal !== 'null';

  const [impersonate, setImpersonate] = useState(isImpersonated ? sessionStorageVal : null);
  const [deimpersonating, setDeimpersonating] = useState(false);

  const stopImpersonate = () => {
    setDeimpersonating(true);
    localStorage.removeItem('impersonate');
    setImpersonate(null);
    window.location.reload();
  };

  const startImpersonate = ({ value }) => {
    if (isNil(value) && isImpersonated) {
      stopImpersonate();
    }
    localStorage.setItem('impersonate', value);
    setImpersonate(value);
  };

  return {
    isImpersonated: !isNil(impersonate),
    impersonate,
    deimpersonating,
    startImpersonate,
    stopImpersonate,
  };
};

export default useImpersonationProvider;
