import React, { useState, useRef, useEffect } from 'react';
import Flatpickr from 'react-flatpickr';
import format from 'date-fns/format';
// Helpers
import { dateFormatter } from '../../constants/Formatting';

const SingleDatePicker = ({
  wrapperClass,
  required = false,
  disabled = false,
  preventDefaultFormat = false,
  showTime = false,
  minimumDate,
  label,
  value,
  onChange,
  errors,
  hideLabel = false
}) => {
  const [textDate, setTextDate] = useState(value);
  const [pickerShowing, setPickerShowing] = useState(false);
  const [error, setError] = useState(false);

  const pickerInput = useRef();

  const updatePickerDate = (selected, string) => {
    if (string && !preventDefaultFormat) {
      setTextDate(dateFormatter(string));
      onChange(dateFormatter(string));
    } else if (string && preventDefaultFormat) {
      setTextDate(format(string, 'MM/dd/yyyy HH:mm'));
      onChange(format(string, 'MM/dd/yyyy HH:mm'));
    }
  };

  useEffect(() => {
    if (value) {
      updatePickerDate(null, value);
    }

    if (value === null) {
      setTextDate('');
    }
  }, [value]);

  useEffect(() => {
    setError(errors);
  }, [errors]);

  const handleBlur = e => {
    const formatted = dateFormatter(e.target.value);
    if (e.target.value) {
      setError(false);
      if (preventDefaultFormat) {
        setTextDate(format(e.target.value, 'MM/dd/yyyy HH:mm'));
        onChange(format(e.target.value, 'MM/dd/yyyy HH:mm'));
      } else {
        setTextDate(formatted);
        onChange(formatted);
      }
    } else if (required && !e.target.value) {
      setTextDate('');
      onChange('');
      setError('This Field is Required');
    } else {
      setTextDate('');
      onChange('');
      setError(false);
    }
  };

  return (
    <div className={`gwi-datepicker-container ${wrapperClass ? wrapperClass : ''}`}>
      {!hideLabel && (
        <label className='label' style={{ marginBottom: '.25rem' }}>
          {label}
        </label>
      )}
      <div className={`date-input-wrapper ${error ? 'error' : ''}`}>
        <input
          className='date-text-input'
          style={{ paddingLeft: '.5rem' }}
          type='text'
          value={textDate ? textDate : ''}
          onChange={e => setTextDate(e.target.value)}
          onBlur={e => handleBlur(e)}
          placeholder={showTime ? 'mm/dd/yyyy hh:mm' : 'mm/dd/yyyy'}
          required={required}
          disabled={disabled}
          onFocus={({ target }) => target.select()}
        />
        <Flatpickr
          ref={pickerInput}
          data-enable-time={showTime}
          time_24hr='true'
          value={value}
          onChange={(selected, string) => updatePickerDate(selected, string)}
          className='picker'
          tabIndex='-1'
          options={{ dateFormat: 'm/d/Y H:i', minuteIncrement: 1, minDate: minimumDate ? minimumDate : null }}
          render={({ defaultValue, ...props }, ref) => {
            return (
              <div
                className='picker-input'
                // onClick={() => {
                //   if (pickerShowing && pickerInput.current && pickerInput.current.flatpickr) {
                //     setPickerShowing(false);
                //     pickerInput.current.flatpickr.close();
                //   } else {
                //     setPickerShowing(true);
                //     pickerInput.current.flatpickr.open();
                //   }
                // }}
              >
                <input {...props} value={props.value || ''} readOnly defaultValue={defaultValue} ref={ref} disabled={disabled} />
              </div>
            );
          }}
        />
      </div>
      {error && <div className='feedback'>{error}</div>}
    </div>
  );
};

export default SingleDatePicker;
