import React, { useState } from 'react';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import LoadingSpinner from 'shared/loading/LoadingSpinner';
import MilestoneModal from '../modals/MilestoneModal';

const MilestoneChart = ({ loading, data, legacyAccount = {}, organization = {}, error, showResponsive, currentCustomer }) => {
  const [showModal, setShowModal] = useState(false);
  const [milestoneType, setMilestoneType] = useState(null);
  const [dayCount, setDayCount] = useState(null);

  const today = new Date();

  const handleOpenModal = (type, day) => {
    setMilestoneType(type);
    setDayCount(day);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setMilestoneType(null);
    setDayCount(null);
    setShowModal(false);
  };

  return loading ? (
    <LoadingSpinner />
  ) : error ? (
    <div>There was an error loading data from the api.</div>
  ) : (
    <div className='table-chart'>
      <div className='table-chart-container'>
        <div className='title-row'>
          <div className='name-placeholder' />
          <div className='table-heading'>Due Date</div>
          <div className='total-placeholder' />
        </div>
        <div className='header-row'>
          <div className='cell name header-cell'>Milestone</div>
          {!showResponsive && (
            <>
              <div className='cell header-cell'>{format(addDays(today, 4), 'M/d')}+</div>
              <div className='cell header-cell'>{format(addDays(today, 3), 'M/d')}</div>
              <div className='cell header-cell'>{format(addDays(today, 2), 'M/d')}</div>
            </>
          )}
          <div className='cell header-cell'>{format(addDays(today, 1), 'M/d')}</div>
          <div className='cell header-cell'>Today</div>
          <div className='cell header-cell'>Overdue</div>
          <div className='cell header-cell'>Total</div>
        </div>
        {data &&
          data.milestones &&
          data.milestones.length &&
          data.milestones.map((row, index) => (
            <div key={index} className='milestone-row'>
              <div className='cell name'>
                <div className='name-inner'>
                  {/* For Last Free Day we need to shorten the anme to LFD on mobile */}
                  {showResponsive && row.name && row.name.toLowerCase ? (row.name.toLowerCase() === 'last free day' ? 'LFD' : row.name) : row.name}
                </div>
              </div>
              {!showResponsive && (
                <>
                  <div
                    className='cell'
                    onClick={() => (row.dateCount4 > 0 ? handleOpenModal(row.milestoneType, 4) : null)}
                    style={row.dateCount4 > 0 ? { cursor: 'pointer' } : {}}
                  >
                    <div>{row.dateCount4}</div>
                  </div>
                  <div
                    className='cell'
                    onClick={() => (row.dateCount3 > 0 ? handleOpenModal(row.milestoneType, 3) : null)}
                    style={row.dateCount3 > 0 ? { cursor: 'pointer' } : {}}
                  >
                    <div>{row.dateCount3}</div>
                  </div>
                  <div
                    className='cell'
                    onClick={() => (row.dateCount2 > 0 ? handleOpenModal(row.milestoneType, 2) : null)}
                    style={row.dateCount2 > 0 ? { cursor: 'pointer' } : {}}
                  >
                    <div>{row.dateCount2}</div>
                  </div>
                </>
              )}
              <div
                className={`cell ${row.dateCount1 === 0 ? '' : 'yellow'}`}
                onClick={() => (row.dateCount1 > 0 ? handleOpenModal(row.milestoneType, 1) : null)}
                style={row.dateCount1 > 0 ? { cursor: 'pointer' } : {}}
              >
                <div>{row.dateCount1}</div>
              </div>
              <div
                className={`cell ${row.dateCountToday === 0 ? '' : 'orange'}`}
                onClick={() => (row.dateCountToday > 0 ? handleOpenModal(row.milestoneType, 0) : null)}
                style={row.dateCountToday > 0 ? { cursor: 'pointer' } : {}}
              >
                <div>{row.dateCountToday}</div>
              </div>
              <div
                className={`cell ${row.dateCountOverdue === 0 ? '' : 'red'}`}
                onClick={() => (row.dateCountOverdue > 0 ? handleOpenModal(row.milestoneType, -1) : null)}
                style={row.dateCountOverdue > 0 ? { cursor: 'pointer' } : {}}
              >
                <div>{row.dateCountOverdue}</div>
              </div>
              <div className='cell pending'>
                <div>{row.totalPending}</div>
              </div>
            </div>
          ))}
      </div>
      {showModal && (
        <MilestoneModal
          isModalShowing={showModal}
          handleCloseModal={handleCloseModal}
          milestoneType={milestoneType}
          dayCount={dayCount}
          legacyAccount={legacyAccount}
          organization={organization}
          currentCustomer={currentCustomer}
        />
      )}
    </div>
  );
};

export default MilestoneChart;
