import { lazy } from 'react';
import { VIEW_CUSTOMER_APP } from 'shared/../constants/CustomerAccess';
import { onLazyImportError } from 'shared/../helpers/common';

const CreatePasswordPage = lazy(() => import(/* webpackChunkName: "create-password" */ './pages/CreatePasswordPage')
  .catch(onLazyImportError));
const ForgotPasswordPage = lazy(() => import(/* webpackChunkName: "forgot-password" */ './pages/ForgotPasswordPage')
  .catch(onLazyImportError));
const LoginPage = lazy(() => import(/* webpackChunkName: "login" */ './pages/LoginPage').catch(onLazyImportError));
const ResetPasswordPage = lazy(() => import(/* webpackChunkName: "reset-password" */ './pages/ResetPasswordPage')
  .catch(onLazyImportError));

export const loggedInAuthRoutes = [
  {
    path: '/createPassword',
    exact: true,
    component: CreatePasswordPage,
    requiredAccess: [VIEW_CUSTOMER_APP],
  },
  {
    path: '/resetPassword',
    exact: true,
    component: ResetPasswordPage,
    requiredAccess: [VIEW_CUSTOMER_APP],
  },
  {
    path: '/forgotPassword',
    exact: true,
    component: ForgotPasswordPage,
    requiredAccess: [VIEW_CUSTOMER_APP],
  },
];

export const loggedOutAuthRoutes = [
  {
    path: '/createPassword',
    exact: true,
    component: CreatePasswordPage,
  },
  {
    path: '/resetPassword',
    exact: true,
    component: ResetPasswordPage,
  },
  {
    path: '/forgotPassword',
    exact: true,
    component: ForgotPasswordPage,
  },
  {
    path: '/',
    component: LoginPage,
  },
];
