import { handleActions } from 'redux-actions';

const initialState = [];

export default handleActions(
  {
    SET_CUSTOMERS: (state, { payload }) => [...payload],
  },
  initialState,
);
