import authFetch from './fetch';

export const getUser = async () => {
  try {
    const { data } = await authFetch.get('/api/me');
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const getUserHeapProperties = async () => {
  try {
    const { data } = await authFetch.get('/api/me/heap');
    return data;
  } catch (err) {
    console.error(err);
  }
};
