/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import { lowerFirst, isNil } from 'lodash';
import { dateFormatter } from 'shared/../constants/Formatting';
import { Button } from '@progress/kendo-react-buttons';

export const OrderProgress = ({ data }) => {
  const [expanded, setExpanded] = useState(false);

  const hasDetails = !isNil(data.orderLegDetails);

  if (expanded && hasDetails) {
    const { orderLegDetails } = data;
    return (
      <div className='detail-section order-progress'>
        <div className='title-container'>
          <div className='title'>Order Progress</div>
          <div className='title-bar' />
        </div>
        <div className='data-container'>
          <div className='status-legend'>
            {orderLegDetails.map((stop, ind) => (
              <div key={JSON.stringify({ ...stop, ROLE: 'legend' })} className='stop-section'>
                <div
                  className={(
                    `status-circle${stop.date && stop.date !== '' ? ` ${stop.type.split(' ').map(lowerFirst).join('-')}` : ''}`
                  )}
                />
                {ind !== orderLegDetails.length - 1 && (
                  <div className='status-divider' />
                )}
                <div />
              </div>
            ))}
          </div>
          <div className='status-stops'>
            {orderLegDetails.map((stop) => (
              <div key={JSON.stringify({ ...stop, ROLE: 'details' })} className='stop-data'>
                <label className='label'>{stop.type}</label>
                <div className='location-info name'>{stop.locationName}</div>
                <div className='location-info'>{stop.address}</div>
                <div className='location-info'>{stop.addressZip}</div>
                {stop.date && stop.date !== '' ? (
                  <div className='location-info date'>{`Completed ${stop.date}`}</div>
                ) : stop.appointmentDate ? (
                  <div className='location-info date'>{`Scheduled ${dateFormatter(stop.appointmentDate)}`}</div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
        <div className='footer-container'>
          <Button
            id='toggle-legs-button'
            onClick={() => setExpanded(false)}
            icon='minus'
          >
            Show less
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className='detail-section order-progress'>
      <div className='title-container'>
        <div className='title'>Order Progress</div>
        <div className='title-bar' />
      </div>
      <div className='data-container'>
        <div className='status-legend'>
          <div className='stop-section'>
            <div className={`status-circle ${data.pickupDate ? 'pickup' : ''}`} />
            <div className='status-divider' />
          </div>
          <div className='stop-section'>
            <div className={`status-circle ${data.deliveryDate ? 'delivery' : ''}`} />
            {data.terminate && <div className='status-divider' />}
          </div>
          {data.terminate && (
            <div className='stop-section'>
              <div className={`status-circle ${data.terminateDate ? 'terminate' : ''}`} />
            </div>
          )}
        </div>
        <div className='status-stops'>
          {data.pickup && (
            <div className='stop-data'>
              <label className='label'>Pickup</label>
              <div className='location-info name'>{data.pickup}</div>
              <div className='location-info'>{data.pickupAddress.charAt(0) !== '-' && data.pickupAddress}</div>
              <div className='location-info'>{data.pickupCityStateZip.charAt(0) !== '-' && data.pickupCityStateZip}</div>
              {data.pickupDate ? (
                <div className='location-info date'>Completed {data.pickupDate}</div>
              ) : data.pickupAppointmentDate ? (
                <div className='location-info date'>Scheduled {dateFormatter(data.pickupAppointmentDate)}</div>
              ) : null}
            </div>
          )}
          {data.delivery && (
            <div className='stop-data'>
              <label className='label'>Delivery</label>
              <div className='location-info name'>{data.delivery}</div>
              <div className='location-info'>{data.deliveryAddress.charAt(0) !== '-' && data.deliveryAddress}</div>
              <div className='location-info'>{data.deliveryCityStateZip.charAt(0) !== '-' && data.deliveryCityStateZip}</div>
              {data.deliveryDate ? (
                <div className='location-info date'>Completed {data.deliveryDate}</div>
              ) : data.deliveryAppointmentDate ? (
                <div className='location-info date'>Scheduled {dateFormatter(data.deliveryAppointmentDate)}</div>
              ) : null}
            </div>
          )}
          {data.terminate && (
            <div className='stop-data'>
              <label className='label'>Terminate</label>
              <div className='location-info name'>{data.terminate}</div>
              <div className='location-info'>{data.terminateAddress.charAt(0) !== '-' && data.terminateAddress}</div>
              <div className='location-info'>{data.terminateCityStateZip.charAt(0) !== '-' && data.terminateCityStateZip}</div>
              {data.terminateDate ? (
                <div className='location-info date'>Completed {data.terminateDate}</div>
              ) : data.terminateAppointmentDate ? (
                <div className='location-info date'>Scheduled {dateFormatter(data.terminateAppointmentDate)}</div>
              ) : null}
            </div>
          )}
        </div>
      </div>
      {hasDetails && (
        <div className='footer-container'>
          <Button
            id='toggle-legs-button'
            onClick={() => setExpanded(true)}
            icon='plus'
          >
            Show all stops
          </Button>
        </div>
      )}
    </div>
  );
};
