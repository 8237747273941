import authFetch from './fetch';
import { makeRequest } from './utils';

export const getDroppedContainers = async (payload) => makeRequest(
  authFetch.post('api/DroppedContainers', payload)
);

export const getDroppedContainersByType = async (payload) => makeRequest(
  authFetch.post('api/DroppedContainers/ByType', payload)
);

export const updateDropReleases = async (proNumbers) => makeRequest(
  authFetch.post('api/DroppedContainers/UpdateDropReleases', { proNumbers: proNumbers })
);
