import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const newScript = (src) =>
  // create a promise for the newScript
  new Promise((resolve, reject) => {
    // create an html script element
    const script = document.createElement('script');
    // set the source of the script element
    script.src = src;
    // set a listener when the script element finishes loading the script
    script.addEventListener('load', () => {
      // resolve if the script element loads
      resolve();
    });
    // set a listener when the script element faces any errors while loading
    script.addEventListener('error', (e) => {
      // reject if the script element has an error while loading the script
      reject(e);
    });
    // append the script element to the body
    document.body.appendChild(script);
  });

const loadTrackingScripts = async (email, identityProperties = {}) => {
  try {
    const { appcuesId, heapId, ...userProperties } = identityProperties;

    // eslint-disable-next-line
  window.heap = window.heap || [], heap.load = function (e, t) { window.heap.appid = e, window.heap.config = t = t || {}; const r = document.createElement('script'); r.type = 'text/javascript', r.async = !0, r.src = `https://cdn.heapanalytics.com/js/heap-${ e }.js`; const a = document.getElementsByTagName('script')[0]; a.parentNode.insertBefore(r, a); for (let n = function (e) { return function () { heap.push([e].concat(Array.prototype.slice.call(arguments, 0))); }; }, p = ['addEventProperties', 'addUserProperties', 'clearEventProperties', 'identify', 'resetIdentity', 'removeEventProperty', 'setEventProperties', 'track', 'unsetEventProperty'], o = 0; o < p.length; o++) { heap[p[o]] = n(p[o]); } };
    heap.load(heapId);

    window.AppcuesSettings = {
      enableURLDetection: true,
    };
    // load the Appcues script
    if (!window.Appcues && appcuesId) {
      await newScript(`https://fast.appcues.com/${appcuesId}.js`);
    }

    if (email) {
      heap.identify(email);
      await window.Appcues.identify(email, userProperties);
    }
    heap.addUserProperties(userProperties);
  } catch (err) {
    console.error('Error whilst authenticating heap or appcues scripts');
  }
};

const CookieDialog = () => {
  const [showCookieDialog, setShowCookieDialog] = useState(false);

  const history = useHistory();

  useEffect(() => {
    // listen to history changes
    const unlisten = history.listen(() => {
      if (window.localStorage.getItem('cookieConsent') === 'accepted' && window.Appcues) {
        window.Appcues.page();
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  // extract user from state
  const user = useSelector(({ user }) => user);

  useEffect(() => {
    // check if the user has already accepted or declined the cookie dialog
    const cookie = window.localStorage.getItem('cookieConsent');
    if (!cookie) {
      setShowCookieDialog(true);
      return;
    }

    if (cookie === 'declined' || !user?.email) {
      return;
    }

    // load the tracking scripts
    loadTrackingScripts(user.email, user.heapProperties);
  }, [user]);

  const onAccept = () => {
    loadTrackingScripts(user?.email, user?.heapProperties);
    setShowCookieDialog(false);
    window.localStorage.setItem('cookieConsent', 'accepted');
  };

  const onDecline = () => {
    // set cookie to remember the user's choice
    setShowCookieDialog(false);
    window.localStorage.setItem('cookieConsent', 'declined');
  };

  // still execute this but dont show anything
  if (!showCookieDialog) { return null; }

  return (

    <div
      className='cookie-dialog'
      id='cookie-dialog'
      style={{
        position: 'sticky', bottom: 0, width: '100vw', zIndex: 1000,
      }}
    >
      <div id='bottom-position' />
      <footer className='footer margin--off' id='cookie-footer'>
        <div className='inner footer--inner'>
          <div className='row margin--off w-screen'>
            <div
              className='col text-align--left cookie-dialog-text'
            >
              We use cookies for analytics and to enhance your experience in future site builds. By clicking "Accept," you consent to our use of cookies.
            </div>
            <div className='col text-align--right  width--10 justify-content--center actions'>
              <button className='button button--secondary button--large decline-button' onClick={onDecline}>
                Decline
              </button>
              <button className='button button--primary button--large accept-button' onClick={onAccept}>
                Accept
              </button>
            </div>
          </div>
        </div>
      </footer>
    </div>

  );
};

export default CookieDialog;