import React from 'react';
import MobileNavMenu from './MobileNavMenuComponent';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import CookieDialog from './CookieDialog';
import { NavLink } from 'react-router-dom';
import LoggedInAsDropdown from 'shared/dropdowns/LoggedInAsDropdownComponent';
import { withRouter } from 'react-router';

const Layout = ({ children }) => {
  return (
    <div className='wrapper margin--off main' id='wrapper'>
      <div className='wrapper react-wrapper margin--off'>
        <Header
          leftChildren={
            <div className='logo'>
              <NavLink exact to={'/'} className='logo--link'>
                <img src={require('shared/../imgs/logoWhite.png')} style={{ height: '50px' }}alt='Customer | Gulf Winds' className='logo--image' />
              </NavLink>
            </div>
          }
          rightChildren={[
            <div key='LoggedInAsDropdown' className='display--inline-block margin--off'>
              <LoggedInAsDropdown />
            </div>
          ]}
        />
        <Body>{children}</Body>
        <CookieDialog />
        <Footer />
      </div>
      <div id='menus' className='menus'>
        <MobileNavMenu />
      </div>
      <div className='modals' id='modals' />
    </div>
  );
};

export default withRouter(Layout);
