import React, { forwardRef } from 'react';

const SearchInput = forwardRef(({ value, onChange, onAdd, ...props }, ref) => {
  const handleChange = ({ target: { value } }) => {
    // if value contains comma or space, add it to chips
    let items = [];
    if (value.includes(' ')) items = value.split(' ');
    if (value.includes(',')) items = value.split(',');

    if (items.length) {
      onAdd(items);
    } else {
      onChange(value);
    }
  };

  const forceAdd = (text) => {
    handleChange({ target: { value: text + ',' } });
  };
  const handleAdd = () => forceAdd(value);

  const handleKeyDown = (e) => {
    const key = e.key.toLowerCase();
    const keysToSearch = ['enter', 'tab'];
    if (keysToSearch.includes(key)) {
      e.preventDefault();
      forceAdd(value);
    }
  };

  return (
    <input
      ref={ref}
      wrapperClass='wrapper-fill-column'
      type='text'
      label='Search'
      placeholder='Search...'
      name='search'
      value={value}
      onChange={handleChange}
      quickAddClick={handleAdd}
      onKeyDown={handleKeyDown}
      {...props}
    />
  );
});

export default SearchInput;
