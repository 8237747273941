import React from 'react';
import { Skeleton } from '@progress/kendo-react-indicators';

const GridLoader = () => (
  <div>
    <div>
      <Skeleton shape="text" />
    </div>
    <div className="flex">
      <div style={{ width: '10%', marginRight: '0.5em' }}><Skeleton shape="rectangle" style={{ height: '5em' }} /></div>
      <div style={{ width: '10%', marginRight: '0.5em' }}><Skeleton shape="rectangle" style={{ height: '5em' }} /></div>
      <div style={{ width: '10%', marginRight: '0.5em' }}><Skeleton shape="rectangle" style={{ height: '5em' }} /></div>
      <div style={{ width: '10%', marginRight: '0.5em' }}><Skeleton shape="rectangle" style={{ height: '5em' }} /></div>
      <div style={{ width: '10%', marginRight: '0.5em' }}><Skeleton shape="rectangle" style={{ height: '5em' }} /></div>
      <div style={{ width: '10%', marginRight: '0.5em' }}><Skeleton shape="rectangle" style={{ height: '5em' }} /></div>
      <div style={{ width: '10%', marginRight: '0.5em' }}><Skeleton shape="rectangle" style={{ height: '5em' }} /></div>
      <div style={{ width: '10%', marginRight: '0.5em' }}><Skeleton shape="rectangle" style={{ height: '5em' }} /></div>
      <div style={{ width: '10%', marginRight: '0.5em' }}><Skeleton shape="rectangle" style={{ height: '5em' }} /></div>
      <div style={{ width: '10%' }}><Skeleton shape="rectangle" style={{ height: '5em' }} /></div>
    </div>
    <div className="flex" style={{ marginTop: '0.5em' }}>
      <div style={{ width: '10%', marginRight: '0.5em' }}><Skeleton shape="rectangle" style={{ height: '50vh' }} /></div>
      <div style={{ width: '10%', marginRight: '0.5em' }}><Skeleton shape="rectangle" style={{ height: '50vh' }} /></div>
      <div style={{ width: '10%', marginRight: '0.5em' }}><Skeleton shape="rectangle" style={{ height: '50vh' }} /></div>
      <div style={{ width: '10%', marginRight: '0.5em' }}><Skeleton shape="rectangle" style={{ height: '50vh' }} /></div>
      <div style={{ width: '10%', marginRight: '0.5em' }}><Skeleton shape="rectangle" style={{ height: '50vh' }} /></div>
      <div style={{ width: '10%', marginRight: '0.5em' }}><Skeleton shape="rectangle" style={{ height: '50vh' }} /></div>
      <div style={{ width: '10%', marginRight: '0.5em' }}><Skeleton shape="rectangle" style={{ height: '50vh' }} /></div>
      <div style={{ width: '10%', marginRight: '0.5em' }}><Skeleton shape="rectangle" style={{ height: '50vh' }} /></div>
      <div style={{ width: '10%', marginRight: '0.5em' }}><Skeleton shape="rectangle" style={{ height: '50vh' }} /></div>
      <div style={{ width: '10%' }}><Skeleton shape="rectangle" style={{ height: '50vh' }} /></div>
    </div>
  </div>
);

export default GridLoader;
