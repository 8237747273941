import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import cn from 'classnames';

ReactModal.setAppElement('#root');

const Modal = ({ isOpen, backDropStyle, containerStyle, children, handleClose, className }) => {
  const modalClassName = cn('modal', { [className]: !!className });
  const overlayClassName = cn('backdrop', { [backDropStyle]: !!backDropStyle });
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className={modalClassName}
      overlayClassName={overlayClassName}
    >
      <div className='modal-dialog'>
        <div className={`modal-content ${containerStyle || ''}`}>{children}</div>
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  // Force the user to override with an empty function if we don't want this.
  // Specifically used for esc to close modal
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  containerStyle: PropTypes.string,
  backDropStyle: PropTypes.string
};

export default Modal;
